import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import DownloadIcon from '@mui/icons-material/DownloadRounded';

type DownloadedImagesSelectProps = {
    parentLoading: boolean;
}

export enum HIDE_DOWNLOADED_IMAGES {
    SHOW_ALL = "0",
    TO_ANNOTATE = "1",
    TO_AUGMENT = "2",
    HIDE_ALL = "3"
};

function DownloadedImagesSelect(props: DownloadedImagesSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const hideDownloadedImages = searchParams.get("downloaded");

    const handleDownloadedImagesSelected = (hide: HIDE_DOWNLOADED_IMAGES) => {
        searchParams.set("downloaded", hide);
        setSearchParams(searchParams);
    };

    const options: Option[] = Object.values(HIDE_DOWNLOADED_IMAGES).map(value => ({
        label: t(`manage_batches.hide_downloaded.${value}`, { ns: Namespace.FORMS }),
        value: value,
    }));

    return (
        <DropdownMenu
            id="images-downloaded-select-menu"
            title={t(`manage_batches.hide_downloaded.title`, { ns: Namespace.FORMS })}
            loading={loading}
            values={options}
            defaultValue={hideDownloadedImages || HIDE_DOWNLOADED_IMAGES.SHOW_ALL}
            startIcon={<DownloadIcon />}
            onChange={(value) => handleDownloadedImagesSelected(value as HIDE_DOWNLOADED_IMAGES)}
        />
    );
}

export default DownloadedImagesSelect;