import { Box, Stack } from "@mui/material";
import CollectionsController from "controllers/collections";
import SectionLoader from "components/_include/SectionLoader";
import Collection from "models/Collection";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { selectCollectionsIds } from "store/reducers/collections/list";
import CollectionListItem, { COLLECTION_ITEM_HEIGHT } from "./CollectionItem/CollectionListItem";
import CollectionsListControls from "./Controls/CollectionsListControls";
import LoadMoreCollections from "./LoadMoreCollections";

type CollectionsListProps = {

}

export default function CollectionsList({ }: CollectionsListProps) {
    const partnerID = usePartnerID();

    const collectionsIDs = useAppSelector(selectCollectionsIds);
    const loading = useAppSelector(state => state.collections.list.loading);

    const dispatch = useAppDispatch();

    const [list, setList] = useState<HTMLDivElement>();
    const listRef = useCallback((el: HTMLDivElement) => {
        setList(el);
    }, []);

    const selectCollection = useCallback((collection: Collection, itemOffset: number, itemWidth: number) => {
        dispatch(CollectionsController.select(collection));

        // scroll the item into view
        if (!list) return;
        const additionalPadding = 40; // add a bit of padding at the end
        if (itemOffset < list.scrollLeft) { // need to scroll left
            list.scrollLeft = itemOffset - additionalPadding;
        }
        else if (itemOffset + itemWidth > list.scrollLeft + list.offsetWidth) { // need to scroll right
            list.scrollLeft = itemOffset - list.offsetWidth + itemWidth + additionalPadding; // add a bit of padding at the end
        }
    }, [list, dispatch]);

    const [loadMore, _setLoadMore] = useState(1);
    const loadMoreRef = useRef(loadMore);
    const setLoadMore = (value: number) => {
        loadMoreRef.current = value;
        _setLoadMore(value);
    };

    const setUpScrollListener = (list: HTMLDivElement) => {
        return list.addEventListener("scroll", (e) => {
            if (list.scrollWidth - list.scrollLeft === list.offsetWidth) { // scrolled to the end of the list
                setLoadMore(loadMoreRef.current + 1);
            }
        });
    }

    useEffect(() => {
        if (partnerID && list) {
            return setUpScrollListener(list);
        }
    }, [partnerID, list]);

    return (
        <Box
            position="relative"
        >
            <Box
                ref={listRef}
                flex={1}
                display="flex"
                position="relative"
                px={4}
                pb={3}
                minHeight={theme => theme.spacing(COLLECTION_ITEM_HEIGHT + 3)}
                sx={{
                    overflowX: "scroll",
                }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    <Stack direction="row" spacing={2}>
                        {collectionsIDs.map(collectionID => (
                            <CollectionListItem
                                key={collectionID}
                                collectionID={collectionID.toString()}
                                onClick={selectCollection}
                            />
                        ))}
                        <LoadMoreCollections key="loading-more" loadPage={loadMore} />
                    </Stack>
                )}
            </Box>

            {list && (
                <CollectionsListControls list={list} />
            )}
        </Box>
    )
}