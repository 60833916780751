import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { BoundingBox, getBoundingBoxSurface } from "models/BoundingBox";
import { TrashType } from "constants/trash";

const resultsBoundingBoxesAdapter = createEntityAdapter<BoundingBox>({
    selectId: bbox => bbox.ID,
    sortComparer: (bbox1, bbox2) => getBoundingBoxSurface(bbox2.box, 1, 1) - getBoundingBoxSurface(bbox1.box, 1, 1), // display smaller boxes on top
});

export enum BatchMediaOverlayType {
    // NONE = "none",
    BBOXES = "bboxes",
    AI_MASKS = "ai_masks",
}

type BoundingBoxesContext = {
    /** The trash type currently being drawn */
    selectedTrashType: TrashType | null;

    /** The trash type that was last being drawn */
    lastSelectedTrashType: TrashType | null;

    /** True if the user is currently dragging the cursor to draw a new bounding box, false otherwise */
    drawing: boolean;

    /** The zoom level on the image */
    imageZoom: number;

    /** The natural width of the batch's image currently displayed */
    imageWidth: number;

    /** The natural height of the batch's image currently displayed */
    imageHeight: number;

    /** The selected type of overlay, bounding boxes or AI polygon masks */
    overlayType: BatchMediaOverlayType;

    /** The ID of the selected bounding box for keyboard shortcuts */
    selectedBoxID: string | null;

    /** The ID of the bounding box that the cursor is currently over */
    hoveredBoxID: string | null;

    /** List of trash types that the user doesn't want to see */
    hiddenTrashTypes: TrashType[];

    /** True to expand the "Other types of waste" drawer, false otherwise */
    lessCommonWastesDrawerOpen: boolean;
}

const initialState: BoundingBoxesContext = { 
    selectedTrashType: null,
    lastSelectedTrashType: null,
    drawing: false,
    imageZoom: 1,
    imageWidth: 0,
    imageHeight: 0,
    overlayType: BatchMediaOverlayType.BBOXES,
    selectedBoxID: null,
    hoveredBoxID: null,
    hiddenTrashTypes: [TrashType.RECYCLE_WASTE], // hide recyclable waste by default
    lessCommonWastesDrawerOpen: false,
};


export const mediaOverlaySlice = createSlice({
    name: 'media_overlay',
    initialState: resultsBoundingBoxesAdapter.getInitialState(initialState),
    reducers: {
        /** Select the waste class to draw bounding boxes */
        selectTrashType: (state, { payload: trashType }: PayloadAction<TrashType | null>) => {
            state.selectedTrashType = trashType;
            if (trashType) {
                state.lastSelectedTrashType = trashType;
                if (state.hiddenTrashTypes.includes(trashType)) { // show trash type to draw if it was hidden
                    state.hiddenTrashTypes = state.hiddenTrashTypes.filter(t => t !== trashType);
                }
            }
            state.overlayType = BatchMediaOverlayType.BBOXES; // go back to bboxes type
        },
        /** Select the last waste class to draw bounding boxes again. */
        selectLastTrashType: (state) => {
            const trashType = state.lastSelectedTrashType;
            state.selectedTrashType = trashType;
            state.overlayType = BatchMediaOverlayType.BBOXES; // go back to bboxes type
            if (trashType && state.hiddenTrashTypes.includes(trashType)) { // show trash type to draw if it was hidden
                state.hiddenTrashTypes = state.hiddenTrashTypes.filter(t => t !== trashType);
            }
        },       
        /** Indicates that the user is currently dragging the cursor on the canvas to draw a new bounding box */
        setDrawing: (state, { payload: drawing }: PayloadAction<boolean>) => {
            state.drawing = drawing;
        },
        /** Toggle between the types of overlay (none, bboxes or AI masks) */
        changeOverlayType: (state, { payload: newType }: PayloadAction<BatchMediaOverlayType>) => {
            state.overlayType = newType;
            if (newType !== BatchMediaOverlayType.BBOXES) state.selectedTrashType = null; // stop editing bboxes if selected type is not bboxes
        },
        /** Add a new bounding box */
        addOne: resultsBoundingBoxesAdapter.addOne,
        /** Update an existing bounding box */
        updateOne: resultsBoundingBoxesAdapter.updateOne,
        /** Delete an existing bounding box */
        removeOne: (state, { payload: boxID }: PayloadAction<string>) => {
            resultsBoundingBoxesAdapter.removeOne(state, boxID);
            state.selectedBoxID = null;
        },
        /** Set the full list of the bounding boxes */
        setAll: (state, { payload: entities }: PayloadAction<BoundingBox[]>) => {
            const bboxes = entities.map(bbox => ({
                ...bbox,
                hidden: state.hiddenTrashTypes.includes(bbox.class),
            }));
            resultsBoundingBoxesAdapter.setAll(state, bboxes);
        },
        /** Set the scaling level for the image and overlay canvases */
        setZoom: (state, { payload: newValue }: PayloadAction<number>) => {
            state.imageZoom = newValue;
        },
        /** Set the natural width and height of the loaded image */
        setImageDimensions: (state, { payload: { width, height } }: PayloadAction<{ width: number, height: number }>) => {
            state.imageWidth = width;
            state.imageHeight = height;
        },
        /** Highlight a bounding box the user has clicked on */
        selectBox: (state, { payload: boxID }: PayloadAction<string | null>) => {
            state.selectedBoxID = boxID;
        },
        /** Highlight a bounding box the user is hovering in the drawers menu */
        hoverBox: (state, { payload: boxID }: PayloadAction<string | null>) => {
            state.hoveredBoxID = boxID;
        },
        /** Hide or show all of the bounding boxes for a given class of waste */
        toggleTrashType: (state, { payload: trashType }: PayloadAction<TrashType>) => {
            const boxesIDsToUpdate = state.ids.filter(bboxID => state.entities[bboxID]?.class === trashType);
            if (state.hiddenTrashTypes.includes(trashType)) { // show trash type
                state.hiddenTrashTypes = state.hiddenTrashTypes.filter(t => t !== trashType);
                resultsBoundingBoxesAdapter.updateMany(state, boxesIDsToUpdate.map(bboxID => ({
                    id: bboxID,
                    changes: { hidden: false },
                })));
            }
            else { // hide trash type
                state.hiddenTrashTypes.push(trashType);
                resultsBoundingBoxesAdapter.updateMany(state, boxesIDsToUpdate.map(bboxID => ({
                    id: bboxID,
                    changes: { hidden: true },
                })));
            }
        },
        /**
         * Open or close the drawer containing the list of the less common waste classes
         */
        toggleLessCommonWastesDrawer: (state, { payload: open }: PayloadAction<boolean>) => {
            state.lessCommonWastesDrawerOpen = open;
        },
    },
});

export const MediaOverlayActions = mediaOverlaySlice.actions;

export const {
    selectAll: selectAllResultsBboxes,
    selectById: selectResultBboxById,
    selectIds: selectResultBboxesIds,
} = resultsBoundingBoxesAdapter.getSelectors((state: RootState) => state.batches.mediaOverlay);

const MediaOverlayReducer = mediaOverlaySlice.reducer;

export default MediaOverlayReducer;