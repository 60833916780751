import { Grid } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectMissionsIds } from 'store/reducers/missions/list';
import MissionCard from './MissionCard/MissionCard';
import NewMissionButton from './New Mission/NewMissionButton';
import SectionLoader from 'components/_include/SectionLoader';


function MissionsGrid() {
    const missionsIDs = useAppSelector(selectMissionsIds);

    const loading = useAppSelector(state => state.mission.list.loading);

    return (
        <Grid container spacing="3">
            {loading ? (
                <SectionLoader />
            ) : (
                <>
                    {missionsIDs.map(missionID => (
                        <Grid key={missionID} item xs={12} sm={6} md={3} xl={3}>
                            <MissionCard missionID={missionID.toString()} />
                        </Grid>
                    ))}
                    <Grid key="add-partner" item xs={12} sm={6} md={3} xl={3} height={theme => theme.spacing(47)}>
                        <NewMissionButton />
                    </Grid>
                </>
            )}
        </Grid >
    );
}

export default MissionsGrid;
