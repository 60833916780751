import { Box, Button, Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { Weekday } from "models/CollectionSchedule";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/AddRounded';
import CollectionTimesRangeSelect from "components/Onboarding/OnboardingSetupCard/Schedule/ScheduleCalendar/CollectionTimeRangeSelect";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SelectedCollectionScheduleActions } from "store/reducers/collections_schedules/selected";

type ItemProps = {
    weekday: Weekday;
}

export default function WeekdayTimesItem({ weekday, }: ItemProps) {

    const { t } = useTranslation([Namespace.DATES]);

    const label = t("weekday", {
        ns: Namespace.DATES,
        date: moment().day(Number(weekday) % 7).toDate(),
    });

    const scheduled = useAppSelector(state => {
        const editingCalendar = state.collectionsSchedules.selected.editingCalendar;
        return editingCalendar ? weekday in editingCalendar : false;
    });

    const dispatch = useAppDispatch();

    /** Add the full day by default when selecting weekday for collection */
    const addWeekday = () => dispatch(SelectedCollectionScheduleActions.setWeekday({ weekday, times: { start: "00:00", end: "23:59" }}));

    return (
        <Box 
            minWidth={148} 
            minHeight={200}
            px={2}
            pt={2}
            display="flex"
            flexDirection="column"
            gap={2}
            >
            <Typography variant="button" gutterBottom>
                {label}
            </Typography>
            {scheduled ? ( // collection is scheduled on this day
                <CollectionTimesRangeSelect weekday={weekday} />
            ) : ( // collection is not scheduled on this day
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={addWeekday}
                    sx={{
                        minWidth: '100%', // Ensure the button has a size even if there's no text
                        height: '40px',
                        borderStyle: 'solid',
                    }}
                >
                    <AddIcon />
                </Button>
            )}
        </Box>
    )
}