import Collection from "models/Collection";
import { LoadableContext } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CollectionContext = LoadableContext & {
    data: Collection | null;
};

const initialState: CollectionContext = {
    loading: false,
    error: null,
    data: null,
};

export const selectedCollectionSlice = createSlice({
    name: 'selected_collection',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setData: (state, { payload: collection }: PayloadAction<Collection | null>) => {
            state.loading = false;
            state.data = collection;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedCollectionActions = selectedCollectionSlice.actions;

const SelectedCollectionReducer = selectedCollectionSlice.reducer;

export default SelectedCollectionReducer;