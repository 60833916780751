import { TrashType, WASTES_COLORS } from "constants/trash";
import { cleanCanvas, drawLabel, drawRectangle } from "helpers/draw";
import { MERGING_WASTE_MAPPING } from "helpers/trash";
import { Namespace } from "locales/translations";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

type AIResultsProps = {
    imageRef: HTMLImageElement;
}

function DemoAIResults({ imageRef }: AIResultsProps) {
    const { t } = useTranslation([Namespace.WASTES]);

    const boundingBoxes = useAppSelector(state => state.batches.selected.data?.bboxes);

    const [searchParams] = useSearchParams();
    /** If set to true, wait until results are verified to display them. */
    const waitForVerified = Boolean(Number(searchParams.get("verified") ?? 1));

    const verified = useAppSelector(state => state.batches.selected.data?.verified);
    
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    
    const handleCanvasRef = useCallback((node: HTMLCanvasElement | null) => {
        setCanvas(node);
    }, []);

    /** Draw the bounding boxes when they change. */
    useEffect(() => {
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                cleanCanvas(ctx, imageRef.width, imageRef.height);

                if ((!waitForVerified || verified) && boundingBoxes) {
                    for (let { box, class: trashType, } of boundingBoxes) {
                        const classToDraw = MERGING_WASTE_MAPPING[trashType] ?? trashType; // display "parent" class if merged

                        if (classToDraw === TrashType.RECYCLE_WASTE) continue; // don't draw recyclable waste

                        const wasteColor = WASTES_COLORS[trashType];
                        drawRectangle(ctx, box[0], box[1], wasteColor, 4);
                        drawLabel(
                            ctx,
                            [box[0][0], box[0][1] - 10],
                            t(trashType + (trashType === TrashType.OTHER ? "_small" : ""), { ns: Namespace.WASTES, count: 1 }),
                            22, "Roboto Mono",
                            wasteColor,
                            "#222222",
                        );
                    }
                }
            }
        }
    }, [t, boundingBoxes, canvas, imageRef.naturalWidth, imageRef.naturalHeight, imageRef.width, imageRef.height]);

    return (
        <canvas
            ref={handleCanvasRef}
            width={imageRef.naturalWidth}
            height={imageRef.naturalHeight}
            style={{
                position: "absolute",
                objectFit: "contain",
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }} />
    );
}

export default DemoAIResults;