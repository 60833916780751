import { API_TIMESTAMP_FORMAT } from 'constants/dates';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment, { Moment } from 'moment';

export function getCurrentTimestamp() {
    return Number(moment().format('x'));
}

export const timestampToString = (timestamp: Timestamp) => moment(timestamp.toDate()).format(API_TIMESTAMP_FORMAT);
export const stringToTimestamp = (timeString: string) => Timestamp.fromDate(moment(timeString, API_TIMESTAMP_FORMAT).toDate());

interface DisplayDateOptions {
    day?: boolean;
    month?: boolean;
    year?: boolean;
    time?: boolean;
}

export type DatesInterval = {
    start: moment.Moment | null;
    end: moment.Moment | null;
};

export enum DatesRangeType {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this_week",
    LAST_WEEK = "last_week",
    LAST_TWO_WEEKS = "last_two_weeks",
    THIS_MONTH = "this_month",
    LAST_MONTH = "last_month",
    LAST_THREE_MONTHS = "last_three_months",
    THIS_YEAR = "this_year",
    // SINCE_INSTALLATION = "since_installation",
    // SINGLE_DAY = "single_day",
    CUSTOM = "custom",
}

/**
 * Determines whether selected dates are on the same day
 */
export const isSingleDay = (type: DatesRangeType, start: Moment, end: Moment) => {
    if (type === DatesRangeType.CUSTOM) return start.isSame(end, "day");
    return [DatesRangeType.TODAY, DatesRangeType.YESTERDAY,].includes(type);   
};

/**
 * Get the start and end dates for a given type of dates range as moment objects.
 * Returns undefined values if the range type is "custom"
 */
export function getDatesFromDateSelectType(type: DatesRangeType | undefined) {
    switch (type) {
        case DatesRangeType.TODAY:
            return {
                start: moment().startOf("day"),
                end: moment().endOf("day"),
            };

        case DatesRangeType.YESTERDAY:
            return {
                start: moment().subtract(1, "day").startOf("day"),
                end: moment().subtract(1, "day").endOf("day"),
            };

        case DatesRangeType.THIS_WEEK:
            return {
                start: moment().startOf("week"),
                end: moment().endOf("week"),
            };

        case DatesRangeType.LAST_WEEK:
            return {
                start: moment().subtract(1, "week").startOf("week"),
                end: moment().subtract(1, "week").endOf("week"),
        };

        case DatesRangeType.LAST_TWO_WEEKS:
            return {
                start: moment().subtract(1, "week").startOf("week"),
                end: moment().endOf("week"),
        };

        case DatesRangeType.THIS_MONTH:
            return {
                start: moment().startOf("month"),
                end: moment().endOf("month"),
        };

        case DatesRangeType.LAST_MONTH:
            return {
                start: moment().subtract(1, "month").startOf("month"),
                end: moment().subtract(1, "month").endOf("month"),
        };

        case DatesRangeType.LAST_THREE_MONTHS:
            return {
                start: moment().subtract(3, "month").endOf("week"),
                end: moment().endOf("week"),
            };

        case DatesRangeType.THIS_YEAR:
            return {
                start: moment().startOf("year"),
                end: moment().endOf("year"),
            };

        default:
            return {
                start: undefined,
                end: undefined,
            };
    }
}

/**
 * Modifies a Moment object set in the user's timezone to simulates they are in the default timezone
 * @param date The Moment to adjust
 * @param reverse Set to true to convert default timezone into user's, false or empty for user's timezone into default
 */
export const adjustToDefaultTimezone = (date: Moment, reverse?: boolean) => {
    const minutesOffset = date.tz(moment.tz.guess()).utcOffset() - date.utcOffset();
    return date.add(minutesOffset * (reverse ? -1 : 1), "minutes");
}

/**
 * Convert a date read from Excel into a Javascript Date object.
 */
export const excelDateToJSDate = (excelDate: number) => new Date(Math.round((excelDate - 25569) * 86400 * 1000));