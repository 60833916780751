
import { Box, Divider, Typography, List, IconButton, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SelectBatchItemButton from './SelectBatchItemButton';
import { useCallback } from 'react';
import { BatchesActions } from 'store/reducers/batches/list';
import TrashColorSquare from 'components/_include/TrashColorSquare';
import { TrashType } from 'constants/trash';
import CloseIcon from '@mui/icons-material/Close';
import { BatchesByPlace, PlacedBatchesActions } from 'store/reducers/batches/places';
import { selectSavedAddressById } from 'store/reducers/saved_addresses/list';
import { formatAddress } from 'helpers/geo';

type PlaceBatchesListProps = {
    batchesByPlace: BatchesByPlace;
}

function PlaceBatchesList({ batchesByPlace }: PlaceBatchesListProps) {

    const { t } = useTranslation([Namespace.GLOSSARY, Namespace.SNACKS, Namespace.WASTES]);

    const address = useAppSelector(state => {
        const place = selectSavedAddressById(state, batchesByPlace.addressKey);
        return place ? formatAddress(place.address) : "";
    });

    const dispatch = useAppDispatch();

    /**
     * Handle click on a batch Item
     */
    const handleBatchClick = useCallback((batchID: string) => {
        // save in local cache to display link as "visited"
        localStorage.setItem(`visited-batch-${batchID}`, "1");

        // set search param to navigate to view
        dispatch(BatchesActions.selectBatch(batchID));
    }, [dispatch]);

    const handleBatchHover = useCallback((batchID: string) => {
        // set search param to navigate to view
        dispatch(BatchesActions.hoverBatch(batchID));
    }, [dispatch]);

    const handleBatchLeave = useCallback((batchID: string) => {
        // set search param to navigate to view
        dispatch(BatchesActions.leaveBatch(batchID));
    }, [dispatch]);

    const handleCloseClick = () => {
        dispatch(PlacedBatchesActions.selectPlace(null));
    }

    const { batches, errorsCount, errors } = batchesByPlace;

    function getColor(quality: number) {
        if (quality > 0.90) return "success";
        if (quality > 0.7) return "warning";
        return "error";
    }

    const headerColor = errorsCount > 0 ? getColor(batches.length / (errorsCount * 2)) : "success";

    return (
        <Box>
            <Box
                py={2}
                px={3}
                color="#ffffff"
                bgcolor={theme => theme.palette[headerColor].dark}
            >
                <Box
                    mb={2}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1}>
                        <Typography variant="body2">
                            {address}
                        </Typography>
                        <Typography variant="h6">
                            {t("sorting_error", { ns: Namespace.GLOSSARY, count: 2 })}
                        </Typography>
                    </Box>
                    <IconButton
                        color="white"
                        onClick={handleCloseClick}
                        sx={{ flexShrink: 1 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    {Object.keys(errors).length > 0 ? (
                        Object.entries(errors).map(([trashType, count]) => (
                            count > 0 ? (
                                <Typography
                                    key={trashType}
                                    variant="body2"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <TrashColorSquare
                                        key={`${trashType}`}
                                        trashType={trashType as TrashType}
                                    />
                                    <span style={{ marginLeft: ".5rem" }}>
                                        {`${t(trashType, { ns: Namespace.WASTES })}: ${count}`}
                                    </span>
                                </Typography>
                            ) : null
                        ))
                    ) : ( // no sorting error
                        <Typography variant="body2">
                            {t("no_sorting_error", { ns: Namespace.GLOSSARY })}
                        </Typography>
                    )}
                </Box>
            </Box>

            <Divider />

            <List sx={{ py: 0 }}>
                {batches.map((batch) => (
                    <SelectBatchItemButton
                        key={batch.ID}
                        batch={batch}
                        onClick={handleBatchClick}
                        onMouseEnter={handleBatchHover}
                        onMouseLeave={handleBatchLeave}
                    />
                ))}
            </List>
        </Box>
    );
}

export default PlaceBatchesList;