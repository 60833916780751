import { Box, Typography, TypographyProps } from '@mui/material';
import NumberInput from 'components/_include/Inputs/NumberInput';
import { Timestamp } from 'firebase/firestore';
import { Namespace } from 'locales/translations';
import { upperFirst } from 'lodash';
import CollectionSchedule from 'models/CollectionSchedule';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'react-rainbow-components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SelectedCollectionScheduleActions } from 'store/reducers/collections_schedules/selected';

type CollectionFrequencyInputProps = {

}

const DEFAULT_FREQUENCY: NonNullable<CollectionSchedule["frequency"]> = {
    interval: 1,
    unit: "weeks",
    start: Timestamp.now().toMillis(),
}

const TYPOGRAPHY_PROPS: TypographyProps = {
    component: "span",
    color: "secondary",
    variant: "subtitle1",
}

export default function CollectionFrequencyInput({ }: CollectionFrequencyInputProps) {

    const { t } = useTranslation([Namespace.COLLECTIONS, Namespace.COMMONS, Namespace.DATES]);

    const frequency = useAppSelector(state => state.collectionsSchedules.selected.editingFrequency ?? DEFAULT_FREQUENCY);

    const dispatch = useAppDispatch();

    const handleIntervalChange = (value: number) => {
        dispatch(SelectedCollectionScheduleActions.setFrequency({
            ...frequency,
            interval: value,
        }));
    }

    const handleStartDateChange = (date: Date) => {
        dispatch(SelectedCollectionScheduleActions.setFrequency({
            ...frequency,
            start: Timestamp.fromDate(date).toMillis(),
        }));
    }

    return (
        <Box
            display="flex"
            pt={2}
            pb={2}
            alignItems="center"
        >
            <Typography {...TYPOGRAPHY_PROPS}>{upperFirst(t("every", { ns: Namespace.COMMONS }))}</Typography>
            <Box mx={1.5}>
                <NumberInput
                    id="schedule-frequency"
                    value={frequency.interval}
                    onChange={handleIntervalChange}
                    min={1}
                />
            </Box>
            <Typography {...TYPOGRAPHY_PROPS}>{t("week", { ns: Namespace.DATES, count: frequency.interval })}</Typography>

            {frequency.interval > 1 && ( // need to select start date if not every week
                <>
                    <Typography {...TYPOGRAPHY_PROPS}>, {t("starting_on", { ns: Namespace.COMMONS })}</Typography>

                    <Box mx={1.5} maxWidth={116}>
                        <DatePicker
                            id="first-collection-schedule-date"
                            placeholder={t("first_collection", { ns: Namespace.COLLECTIONS })}
                            value={Timestamp.fromMillis(frequency.start).toDate()}
                            icon={<></>}
                            className='datepicker-single-outlined'
                            onChange={handleStartDateChange}
                            locale="fr"
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}