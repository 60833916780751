import { Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesActions } from "store/reducers/batches/list";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import { useEffect } from "react";
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";

export default function GoToPreviousBatch() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);

    const key = useAppSelector(state => state.preferences.validation[Shortcut.GO_TO_PREVIOUS_BATCH]);

    const goToPreviousBatch = () => {
        dispatch(BatchesActions.selectPreviousBatch());
    }

    /**
     * Bind the "Left arrow" key to go to previous batch
     */
    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === key) {
            if ((e.target as HTMLElement | null)?.tagName !== "INPUT") { // user not focused on an input
                goToPreviousBatch();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);

        return () => window.removeEventListener('keydown', handleKeydown);
    }, [key]);


    return (
        <Grid
            item
            xs={2}
            display="flex"
            justifyContent="center"
        >
            <IconButton
                onClick={goToPreviousBatch}
                color="secondary"
                disabled={loading}
            >
                <ChevronLeftIcon />
            </IconButton>
        </Grid>
    )
}