import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TimeSelect from "./TimeSelect";
import { Box, Grid } from "@mui/material";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

type TimesRangeSelectProps = {
    parentLoading: boolean;
}

const getTime = (m: Moment) => m === null ? "" : m.format("HH:mm");

function TimesRangeSelect(props: TimesRangeSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;

    const { t } = useTranslation([Namespace.COMMONS,]);

    const [searchParams, setSearchParams] = useSearchParams();

    const startTime = searchParams.get("startTime");
    const endTime = searchParams.get("endTime");

    const [rangeValue, setRangeValue] = useState<[string, string]>([startTime ?? "", endTime ?? ""]);

    // handle automatic changes in the search params
    useEffect(() => {
        if (startTime !== rangeValue[0] || endTime !== rangeValue[1]) {
            setRangeValue([startTime ?? "00:00", endTime ?? "23:59"]);
        }
    }, [startTime, endTime]);

    const handleChange = (value: string, position: "start" | "end") => {
        setRangeValue(position === "start" ? [value, rangeValue[1]] : [rangeValue[0], value]);

        // update search params in URL
        if (position === "start") {
            searchParams.set("startTime", value);
        }
        else {
            searchParams.set("endTime", value);
        }
        setSearchParams(searchParams);
    }

    return (
        <Box 
            display="flex"
            alignItems="center"
            gap={1} 
            maxWidth={233} // to align with dates range picker width while fitting 2 TimePicker
            >
            <TimeSelect
                label={t("start_time", { ns: Namespace.COMMONS })}
                value={rangeValue[0]}
                onChange={(v) => handleChange(v, "start")}
                loading={loading}
            />

            <ArrowForwardRoundedIcon />

            <TimeSelect
                label={t("end_time", { ns: Namespace.COMMONS })}
                value={rangeValue[1]}
                onChange={(v) => handleChange(v, "end")}
                loading={loading}
            />
        </Box>
    );
}

export default TimesRangeSelect;