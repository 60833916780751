import { DbCollection } from "constants/db";
import { deleteDoc, getFirestore, updateDoc, writeBatch } from "firebase/firestore";
import { createDocument, getDocumentReference, listDocs } from "helpers/db";
import CollectionSchedule, { CollectionScheduleDbData, fromDbData, fromDbDoc, numberToWeekday } from "models/CollectionSchedule";
import { CollectionsSchedulesListActions } from "store/reducers/collections_schedules/list";
import { SelectedCollectionScheduleActions } from "store/reducers/collections_schedules/selected";
import { showError } from "store/reducers/snacks";
import { AppDispatch } from "store/store";

const create = (partnerID: string, data: CollectionScheduleDbData) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsSchedulesListActions.startLoadingList());
    try {
        const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.COLLECTIONS_SCHEDULES];
        const collectionScheduleData = await createDocument(collectionPath, data) as CollectionScheduleDbData & { ID: string };
        const collectionSchedule = fromDbData(collectionScheduleData.ID, partnerID, collectionScheduleData);
        dispatch(CollectionsSchedulesListActions.addItem(collectionSchedule));
        return collectionSchedule;
    }
    catch (e) {
        const error = e as Error;
        console.error(error);
        dispatch(showError(error.message));
        dispatch(CollectionsSchedulesListActions.setError(error.message));
        return null;
    }
}

const list = (partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsSchedulesListActions.startLoadingList());
    try {
        const docs = await listDocs([DbCollection.PARTNERS, partnerID, DbCollection.COLLECTIONS_SCHEDULES]);
        let schedules: CollectionSchedule[] = docs.map(doc => fromDbDoc(doc));
        dispatch(CollectionsSchedulesListActions.setList(schedules));
        return schedules;
    }
    catch (e) {
        const error = e as Error;
        console.error("failed to load schedules", error);
        dispatch(showError(error.message));
        dispatch(CollectionsSchedulesListActions.setError(error.message));
        return [];
    }
}

const update = (schedule: CollectionSchedule, data: Partial<CollectionScheduleDbData>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollectionScheduleActions.startLoading());
    
    const parentPath = `${DbCollection.PARTNERS}/${schedule.partnerID}`;
    const docRef = getDocumentReference(schedule.ID, DbCollection.COLLECTIONS_SCHEDULES, parentPath);
    
    try {
        await updateDoc(docRef, data);

        dispatch(CollectionsSchedulesListActions.updateItem({
            id: schedule.ID,
            data: {
                ...data,
                weekdays: data.weekdays ? data.weekdays.map(weekdayNumber => numberToWeekday(weekdayNumber)) : schedule.weekdays,
                frequency: data.frequency ? {
                    ...data.frequency,
                    start: data.frequency.start?.toMillis(),
                } : undefined,
            }
        }));
        dispatch(SelectedCollectionScheduleActions.stopLoading());

        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error(error);
        dispatch(showError(error.message));
        dispatch(SelectedCollectionScheduleActions.setError(error.message));
        return false;
    }
}

const deleteSchedule = (partnerID: string, scheduleID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollectionScheduleActions.startLoading());
    
    const parentPath = `${DbCollection.PARTNERS}/${partnerID}`;
    const docRef = getDocumentReference(scheduleID, DbCollection.COLLECTIONS_SCHEDULES, parentPath);
    
    try {
        await deleteDoc(docRef);
        dispatch(CollectionsSchedulesListActions.removeItem(scheduleID));
        dispatch(SelectedCollectionScheduleActions.stopLoading());
        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error(error);
        dispatch(showError(error.message));
        dispatch(SelectedCollectionScheduleActions.setError(error.message));
        return false;
    }
}

export const deleteRouteSchedules = (partnerID: string, routeID: string) => async (dispatch: AppDispatch) => {
    const batchWrite = writeBatch(getFirestore());

    const docs = await listDocs([DbCollection.PARTNERS, partnerID, DbCollection.COLLECTIONS_SCHEDULES], [{
        fieldPath: "route.ID",
        opStr: "==",
        value: routeID,
    }]);

    for (let doc of docs) {
        batchWrite.delete(doc.ref);
    }

    await batchWrite.commit();

    dispatch(CollectionsSchedulesListActions.removeMany(docs.map(doc => doc.id)));
}

const CollectionsSchedulesMethods = {
    create,
    list,
    update,
    delete: deleteSchedule,
};

export default CollectionsSchedulesMethods;