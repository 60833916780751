import { ButtonBase, Typography, useTheme } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ReportFormatButtonProps = {
    imgSource: string;
    label: string;
    loading?: boolean;
    onClick: () => void;
}

function ReportFormatButton({ imgSource, label, loading, onClick }: ReportFormatButtonProps) {
    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    const theme = useTheme();

    return (
        <ButtonBase
            onClick={onClick}
            disabled={loading}
            sx={{
                flexDirection: "column",
                p: 4,
                borderRadius: theme => theme.shape.borderRadius,
            }}
            >
            <img 
                src={imgSource} 
                style={{
                    maxWidth: "100%",
                    maxHeight: theme.spacing(12),
                    marginBottom: theme.spacing(3),
                }}
                />
            <Typography
                variant="subtitle2"
                >
                {label}
            </Typography>
            {loading && (
                <SectionLoader />
            )}
        </ButtonBase>
    );
}

export default ReportFormatButton;