import { Box, Container } from "@mui/material";
import PageTitle from "components/_include/PageTitle";
import CollectionDetailWrapper from "./CollectionDetail/CollectionDetailWrapper";
import CollectionsList from "./CollectionsList/CollectionsList";
import CollectionsListBackground from "./CollectionsList/CollectionsListBackground";
import CollectionsFilters from "./CollectionsList/CollectionsFilters/CollectionsFilters";

export default function ManageCollectionsPage() {

    return (
        <Container
            sx={{
                // bgcolor: "#EFEFEF",
            }}
        >
            <Box
                px={4}
                bgcolor="#FFFFFF"
                sx={{
                    borderTopLeftRadius: theme => theme.shape.borderRadius * 3,
                    borderTopRightRadius: theme => theme.shape.borderRadius * 3,
                }}
            >
                <PageTitle />
            </Box>

            <Box 
                position="relative" 
                mb={2}
                zIndex={9} // allow using mouse to drag horizontal scroll bar
                >
                <CollectionsListBackground />
                <CollectionsFilters />
                <CollectionsList />
            </Box>

            <CollectionDetailWrapper />
        </Container>
    );
}