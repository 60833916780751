import { createSvgIcon } from "@mui/material";

const VisitedIcon = createSvgIcon(
    <g>
        <path d="M14 7L17 10L22 4"  fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.5806 10.8755C22.1501 11.6693 22.6232 12.5442 23 13.5C22.1 15.7833 20.65 17.604 18.65 18.962C16.65 20.3207 14.4333 21 12 21C9.56667 21 7.35 20.3207 5.35 18.962C3.35 17.604 1.9 15.7833 1 13.5C1.9 11.2167 3.35 9.39567 5.35 8.03701C7.09434 6.8526 9.0035 6.1847 11.0775 6.03329C11.0265 6.34799 11 6.6709 11 6.99999C11 7.71655 11.1256 8.40377 11.356 9.04083C10.3809 9.1679 9.53293 9.59163 8.812 10.312C7.93733 11.1873 7.5 12.25 7.5 13.5C7.5 14.75 7.93733 15.8127 8.812 16.688C9.68733 17.5627 10.75 18 12 18C13.25 18 14.3127 17.5627 15.188 16.688C16.0627 15.8127 16.5 14.75 16.5 13.5C16.5 13.3219 16.4911 13.1477 16.4734 12.9772C16.6469 12.9923 16.8226 13 17 13C18.8363 13 20.48 12.175 21.5806 10.8755ZM12.3755 10.8231C12.9489 11.5159 13.6751 12.0778 14.5023 12.457C14.6341 12.7765 14.7 13.1241 14.7 13.5C14.7 14.25 14.4377 14.8873 13.913 15.412C13.3877 15.9373 12.75 16.2 12 16.2C11.25 16.2 10.6127 15.9373 10.088 15.412C9.56267 14.8873 9.3 14.25 9.3 13.5C9.3 12.75 9.56267 12.1123 10.088 11.587C10.6127 11.0623 11.25 10.8 12 10.8C12.1285 10.8 12.2536 10.8077 12.3755 10.8231Z" />
    </g>,
    "Visited"
);

export default VisitedIcon;