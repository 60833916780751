import GoogleMapReact, {  } from "google-map-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getBoundsZoomLevel, getMapOptions } from "helpers/geo";
import { BatchesActions } from "store/reducers/batches/list";
import BatchMarker from "./BatchMarker";

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type BatchesMapProps = {
    width: number | undefined;
    height: number | undefined;
}

export default function BatchesMap(props: BatchesMapProps) {

    const { width, height } = props;

    const defaultCenter = useAppSelector(state => state.batches.collectionMap.defaultCenter);
    const defaultBounds = useAppSelector(state => state.batches.collectionMap.defaultBounds);
    const defaultZoom = useAppSelector(state => state.batches.collectionMap.defaultZoom);

    const dispatch = useAppDispatch();

    // handle map
    const [zoom, setZoom] = useState(defaultZoom);
    useEffect(() => {
        setZoom(defaultZoom);
    }, [defaultZoom]);

    const points = useAppSelector(state => state.batches.collectionMap.points);
    const selectedBatchID = useAppSelector(state => state.batches.list.selectedBatch?.ID);

    const mapRef = useRef<GoogleMapReact>(null);

    useEffect(() => {
        if (defaultBounds && width && height) {
            const mapDims = {
                width: width,
                height: height,
            };
            let newZoom = getBoundsZoomLevel(defaultBounds, mapDims);
            
            setZoom(newZoom);
        }
    }, [defaultBounds]);

    const handleBatchMarkerClick = useCallback((batchID: string) => {
        dispatch(BatchesActions.selectBatch(batchID));
    }, [dispatch]);

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={defaultCenter}
            zoom={zoom}
            options={getMapOptions}
            onChange={({ zoom, bounds }) => {
                setZoom(zoom);
            }}
        >
            {points.map((point) => {
                const {
                    batchID,
                    addressKey,
                    position,
                    dangerLevel,
                } = point;

                return (
                    <BatchMarker
                        key={batchID}
                        lat={position.lat}
                        lng={position.lng}
                        batchID={batchID}
                        addressKey={addressKey}
                        dangerLevel={dangerLevel}
                        selected={batchID === selectedBatchID}
                        onClick={handleBatchMarkerClick}
                    />
                );
            })}
        </GoogleMapReact>
    );
}