import GoogleMapReact, { Coords } from 'google-map-react';
import { getMapOptions } from 'helpers/geo';
import { useRef } from 'react';

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type DefaultBoundsMapProps = {
    zoom: number;
    center: Coords;
    onChange: (v: GoogleMapReact.ChangeEventValue) => void;
}

function DefaultBoundsMap({ zoom, center, onChange }: DefaultBoundsMapProps) {
    const mapRef = useRef<GoogleMapReact>(null);

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={center}
            zoom={zoom}
            options={getMapOptions}
            onChange={onChange}
        />
    );
}

export default DefaultBoundsMap;