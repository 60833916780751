import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrashType } from "constants/trash";
import Truck from "models/Truck";
import { LoadableContext } from "store/store";

type SortingException = [TrashType | null, TrashType | null, string];

type ExceptionsForm = Array<SortingException>;

type SelectedTruckContext = LoadableContext & {
    data: Truck | null;
    exceptionsForm: ExceptionsForm;
};

const initialState: SelectedTruckContext = {
    loading: false,
    error: null,
    data: null,
    exceptionsForm: [],
};


export const selectedTruckSlice = createSlice({
    name: 'selected_truck',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        selectTruck: (state, { payload: truck }: PayloadAction<Truck | null>) => {
            state.loading = false;
            state.data = truck;
            state.exceptionsForm = Object.entries(state.data?.exceptions ?? {}).map(([fromType, { to, description }]) => [fromType as TrashType, to, description]);
        },
        update: (state, { payload: data }: PayloadAction<Partial<Truck>>) => {
            state.loading = false;
            if (state.data) {
                state.data = {
                    ...state.data,
                    ...data,
                    displayID: data.identificationNumber ?? state.data.identificationNumber ?? data.licensePlate ?? state.data.licensePlate ?? "",
                };
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        /** Update the sorting exceptions values in the edit truck form */
        updateExceptions: (state, { payload: { index, exception } }: PayloadAction<{ index: number, exception: SortingException }>) => {
            state.exceptionsForm[index] = exception;
        },
        /** Remove a sorting exception previously defined */
        deleteException: (state, { payload: index }: PayloadAction<number>) => {
            state.exceptionsForm.splice(index, 1);
        },
    },
});

export const SelectedTruckActions = selectedTruckSlice.actions;

const SelectedTruckReducer = selectedTruckSlice.reducer;

export default SelectedTruckReducer;