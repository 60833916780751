import SavedAddress from "models/SavedAddress";
import { LoadableContext } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SavedAddressContext = LoadableContext & {
    data: SavedAddress | null;
}

const initialState: SavedAddressContext = {
    loading: false,
    error: null,
    data: null,
};

export const selectedSavedAddressSlice = createSlice({
    name: 'selected_saved_address',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setData: (state, { payload: place }: PayloadAction<SavedAddress | null>) => {
            state.loading = false;
            state.data = place;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedSavedAddressActions = selectedSavedAddressSlice.actions;

const SelectedSavedAddressReducer = selectedSavedAddressSlice.reducer;

export default SelectedSavedAddressReducer;