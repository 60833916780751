import { Box, IconButton, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPartnerById } from 'store/reducers/partners/list';
import EditIcon from '@mui/icons-material/EditRounded';

type PartnerNameTextProps = {
    partnerID: string;
    onStartEdit: () => void;
}

function PartnerNameText({ partnerID, onStartEdit, }: PartnerNameTextProps) {
    const partnerName = useAppSelector(state => selectPartnerById(state, partnerID)?.name);
    // const dispatch = useAppDispatch();

    return (
        <Box
            display="flex"
            alignItems="center"
            >
            <Typography
                variant="h4"
                sx={{
                    flexGrow: 1,
                }}
            >
                {partnerName}
            </Typography>

            <IconButton
                onClick={onStartEdit}
                >
                <EditIcon />
            </IconButton>
        </Box>
    );
}

export default PartnerNameText;