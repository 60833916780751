import SavedAddress from "models/SavedAddress";
import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LoadableContext = {
    loading: false,
    error: null,
};

const addressesAdapter = createEntityAdapter<SavedAddress>({
    selectId: place => place.ID,
});

export const savedAddressesSlice = createSlice({
    name: 'saved_addresses',
    initialState: addressesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: places }: PayloadAction<SavedAddress[]>) => {
            state.loading = false;
            addressesAdapter.setAll(state, places);
        },
        addItem: (state, { payload: data }: PayloadAction<SavedAddress>) => {
            state.loading = false;
            addressesAdapter.addOne(state, data);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<SavedAddress>}>) => {
            state.loading = false;
            addressesAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        removeItem: (state, { payload: placeID }: PayloadAction<string>) => {
            state.loading = false;
            addressesAdapter.removeOne(state, placeID);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SavedAddressesActions = savedAddressesSlice.actions;

export const {
    selectAll: selectAllSavedAddresses,
    selectById: selectSavedAddressById,
    selectIds: selectSavedAddressesIds
} = addressesAdapter.getSelectors((state: RootState) => state.savedAddresses.list);

const SavedAddressesReducer = savedAddressesSlice.reducer;

export default SavedAddressesReducer;