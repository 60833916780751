import { IconButton, ListItemButton, ListItemText } from "@mui/material";
import { LESS_COMMON_ERRORS, TRASH_TYPES_ICONS, TrashType, VALIDATION_WASTES_COLORS } from "constants/trash"
import { hexToRgba } from "helpers/draw";
import { isSortingError } from "helpers/trash";
import { Namespace } from "locales/translations";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ExpandLess from '@mui/icons-material/ExpandLessRounded';
import ExpandMore from '@mui/icons-material/ExpandMoreRounded';
import { WastesInputsActions } from "store/reducers/batches/wastes_inputs";
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";

type DrawerHeaderProps = {
    wasteType: TrashType;
    canEditResults?: boolean;
}

/**
 * A single drawer for a class of waste.
 * It has an expandable header for the sum of all of the instances
 * and a nested item for each bounding box of this type of waste.
 */
export default function WasteTypeDrawerHeader({ wasteType, canEditResults }: DrawerHeaderProps) {
    const { t } = useTranslation([Namespace.WASTES]);

    const label = `${TRASH_TYPES_ICONS[wasteType]} ${t(wasteType, { ns: Namespace.WASTES, context: "small" })}`;

    const count = useAppSelector(state => state.batches.wastesInputs[wasteType].count);

    const open = useAppSelector(state => state.batches.wastesInputs[wasteType].open);

    const isHidden = useAppSelector(state => state.batches.mediaOverlay.hiddenTrashTypes.includes(wasteType));

    const selectedForDrawing = useAppSelector(state => state.batches.mediaOverlay.selectedTrashType === wasteType);

    const lessCommonClassesDrawerOpen = useAppSelector(state => state.batches.mediaOverlay.lessCommonWastesDrawerOpen);

    const dispatch = useAppDispatch();

    /** Open drawer for this class, opening less common classes drawer if needed. */
    const handleOpen = () => {
        if (LESS_COMMON_ERRORS.includes(wasteType) && !lessCommonClassesDrawerOpen) { 
            dispatch(MediaOverlayActions.toggleLessCommonWastesDrawer(true));
        }

        dispatch(WastesInputsActions.toggleDrawer({
            trashType: wasteType,
            open: !open,
        }));
    }

    /**
     * Hide or show the bounding boxes for the trash type
     */
    const handleToggleTrashTypeClick = (e: any) => {
        e.stopPropagation();
        dispatch(MediaOverlayActions.toggleTrashType(wasteType));
    }

    const startDrawing = () => dispatch(MediaOverlayActions.selectTrashType(selectedForDrawing ? null : wasteType));

    /**
     * If already drawing, cancel drawing.
     * If not already drawing, start drawing this type of waste
     */
    const handleAddClick = (e: any) => {
        // don't close the drawer if currently open
        if (open || selectedForDrawing) e.stopPropagation();

        startDrawing();
    }

    const drawClassKey = useAppSelector(state => state.preferences.validation[wasteType]);

    /**
     * Handle keyboard shortcuts
     */
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === drawClassKey) { // select the previously selected class to draw again
            startDrawing();
            handleOpen();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => { window.removeEventListener("keydown", handleKeyDown); };
    }, [drawClassKey, lessCommonClassesDrawerOpen, open,]);

    /** Background color is the classe's associated color with 20% opacity */
    const bgcolor = useMemo(
        () => hexToRgba(VALIDATION_WASTES_COLORS[wasteType], 0.2),
        [wasteType]
    );

    return (
        <ListItemButton
            sx={{
                px: 0.5,
                gap: 0.5,
                bgcolor: bgcolor,
                ...(open && {
                    boxShadow: theme => theme.shadows[2],
                }),
                "&:hover": {
                    bgcolor: bgcolor
                }
            }}
            onClick={handleOpen}
        >
            {canEditResults && (
                <IconButton
                    color="secondary"
                    onClick={handleAddClick}
                    size="large"
                >
                    <AddRoundedIcon
                        fontSize="medium"
                        sx={{
                            transition: "rotate 0.3s",
                            rotate: selectedForDrawing ? "45deg" : 0
                        }}
                    />
                </IconButton>
            )}

            <ListItemText
                primary={`${label}: ${count}`}
                primaryTypographyProps={{
                    variant: "button",
                    color: isSortingError(wasteType) && count > 0 ? "error" : "secondary"
                }}
            />

            {canEditResults && (
                <IconButton
                    color="secondary"
                    onClick={handleToggleTrashTypeClick}
                    sx={{
                        opacity: 0.36,
                        "&:hover": {
                            opacity: 1,
                        },
                        ...(isHidden && { opacity: 1 }),
                    }}
                >
                    <VisibilityOffIcon fontSize="small" />
                </IconButton>
            )}

            <IconButton>
                {open ? (
                    <ExpandLess color="secondary" fontSize="small" />
                ) : (
                    <ExpandMore color="secondary" fontSize="small" />
                )}
            </IconButton>
        </ListItemButton>
    )
}