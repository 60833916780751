import { Box } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { PositionedNamedErrorsByAddress } from 'constants/types';
import { Namespace } from 'locales/translations';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import ChartTitle from '../ChartTitle';
import WorstAddressesMap from './WorstAddressesMap';

type WorstAddressesMapWrapperProps = {
    height: number;
    points: PositionedNamedErrorsByAddress[];
}

function WorstAddressesMapWrapper({ height, points }: WorstAddressesMapWrapperProps) {

    const mapWrapperRef = useRef<HTMLDivElement>(null);

    const loading = useAppSelector(state => state.batches.list.loading);

    const { t } = useTranslation([Namespace.CHARTS]);

    return (
        <Box
            position="relative"
            >
            <ChartTitle>
                {t("sensibilisation_itinerary.title", { ns: Namespace.CHARTS })}
            </ChartTitle>

            <Box
                position="relative"
                width="100%"
                height={height}
                >
                <Box
                    ref={mapWrapperRef}
                    height={theme => `calc(${height}px + ${theme.spacing(3)})`}
                    borderRadius={4}
                    boxShadow={4}
                    overflow="hidden"
                    position="absolute"
                    left={theme => theme.spacing(-2)}
                    right={theme => theme.spacing(-2)}
                    bottom={theme => theme.spacing(-3)}
                >
                    {loading && (
                        <SectionLoader />
                    )}
                    <WorstAddressesMap
                        width={mapWrapperRef.current?.offsetWidth ?? 0}
                        height={height}
                        points={points}
                        />
                </Box>
            </Box>
        </Box>
    );
}

export default WorstAddressesMapWrapper;