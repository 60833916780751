import moment from "moment";
import { useEffect, useState } from "react";
import { Namespace } from "locales/translations";
import { Box, Button, Menu, MenuItem, useTheme, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { DatePicker } from 'react-rainbow-components';
import { useAppSelector } from "store/hooks";
import { selectAllCollectionsSchedules } from "store/reducers/collections_schedules/list";
import { Weekday } from "models/CollectionSchedule";

type SingleDateSelectProps = {
    parentLoading: boolean;
    needDefault?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

function SingleDateSelect({ parentLoading, minDate, maxDate, needDefault }: SingleDateSelectProps) {

    const theme = useTheme();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS,]);
    
    const loading = parentLoading;
    const selectedTruck = useAppSelector(state => state.trucks.selected.data);

    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get("date");
    const defaultDate = needDefault ? moment(dateParam || undefined) : dateParam ? moment(dateParam) : undefined; // default to today if no date set
    const [date, setDate] = useState(defaultDate);

    useEffect(() => {
        if (date) {
            searchParams.set("date", date.format("YYYY-MM-DD"));
            setSearchParams(searchParams);
        }
    }, [date]);

    useEffect(() => {
        setDate(defaultDate ? moment(dateParam) : undefined);
    }, [dateParam]);

    const handleCustomDateSelected = (value: Date) => {
        setDate(moment(value));
    }

    const collectionsSchedules = useAppSelector(state => selectAllCollectionsSchedules(state));

    useEffect(() => {
        // mark sortable days on calendar
        let truckSchedules = [...collectionsSchedules];
        if (selectedTruck) truckSchedules = truckSchedules.filter(schedule => schedule.truck.ID === selectedTruck.ID);

        const STYLE_BLOCK_ID = "sortableCollectionDaysCalendarStyling";
        let styleBlock = document.getElementById(STYLE_BLOCK_ID);
        if (!styleBlock) {
            document.head.insertAdjacentHTML("beforeend", `<style id="${STYLE_BLOCK_ID}"></style>`);
            styleBlock = document.getElementById(STYLE_BLOCK_ID);
        }
        let sortableCollectionsWeekdays: Weekday[] = [];
        for (let schedule of truckSchedules) {
            for (let weekday of Object.keys(schedule.calendar)) {
                if (!sortableCollectionsWeekdays.includes(weekday as Weekday)) {
                    sortableCollectionsWeekdays.push(weekday as Weekday);
                }
            }
        }
        let sortableCollectionsDaysStyling = "";
        for (let weekday of sortableCollectionsWeekdays) {
            sortableCollectionsDaysStyling += `tbody tr td:nth-child(${(Number(weekday) + 1) % 7}) button {
                border: 1px solid ${theme.palette.success.main};
            }
            `;
            styleBlock!.innerHTML = sortableCollectionsDaysStyling;
        }
    }, [selectedTruck, collectionsSchedules]);

    return (
        <Box
            position="relative"
            sx={{
                pointerEvents: "all",
            }}
        >
            <Box>
                <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={loading}
                    placeholder={needDefault ? t("select_dates.single", { ns: Namespace.ACTIONS }) : t("select.all_dates", { ns: Namespace.FORMS })}
                    formatStyle="large"
                    variant="single"
                    locale="fr"
                    value={date?.toDate()}
                    onChange={(value: Date) => handleCustomDateSelected(value)}
                    className="datepicker-single"
                    icon={<></>}
                />
            </Box>
        </Box >
    );
}

export default SingleDateSelect;