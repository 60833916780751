import ActionButton from "components/_include/Buttons/ActionButton";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ImagesToAnnotateActions, selectNumberOfBatchesToDownload } from "store/reducers/batches/to_download";
import { selectAllBatches } from "store/reducers/batches/list";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';

export default function ToggleImagesToDownloadButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const batches = useAppSelector(selectAllBatches);
    const numberOfImagesChecked = useAppSelector(selectNumberOfBatchesToDownload);

    const shouldSelectAll = batches.length > numberOfImagesChecked; // click on button selects all images if not all selected

    const dispatch = useAppDispatch();

    const toggleImagesChecked = () => {
        if (shouldSelectAll) { // select all images for download
            dispatch(ImagesToAnnotateActions.setAll(batches.map(batch => ({ ID: batch.ID, partnerID: batch.partnerID, imageURL: batch.imageURL }))));
        }
        else { // clear list of images to download
            dispatch(ImagesToAnnotateActions.clearList());
        }
    }

    return (
        <ActionButton
            disabled={batches.length === 0}
            color='secondary'
            startIcon={shouldSelectAll ? numberOfImagesChecked > 0 ? <IndeterminateCheckBoxRoundedIcon /> : <CheckBoxRoundedIcon /> : <CheckBoxOutlineBlankRoundedIcon />}
            onClick={() => toggleImagesChecked()}
            fullWidth
            style={{
                whiteSpace: "nowrap"
            }}
        >
            {t(shouldSelectAll ? "select_all" : "remove_all", { ns: Namespace.ACTIONS })}
        </ActionButton>
    )
}
