import { useAppDispatch, useAppSelector } from 'store/hooks';
import DropdownMenu, { Option } from './DropdownMenu/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { LOCALES_LABELS, LOCAL_STORAGE_LOCALE_KEY, Locale, Namespace } from 'locales/translations';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import i18next from 'i18next';
import moment from 'moment';

type LanguageSelectProps = {

}

const LANGUAGES_OPTIONS: Option[] = Object.values(Locale).map(locale => ({ label: LOCALES_LABELS[locale], value: locale }));

function LanguageSelect({ }: LanguageSelectProps) {
    const { t } = useTranslation([Namespace.COMMONS]);

    const onLanguageChange = (locale: string) => {
        localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
        i18next.changeLanguage(locale);
        moment.locale(locale);
    }

    return (
        <DropdownMenu
            id="language-select-menu"
            title={t("language", { ns: Namespace.COMMONS })}
            values={LANGUAGES_OPTIONS}
            defaultValue={i18next.language}
            startIcon={<TranslateRoundedIcon />}
            onChange={onLanguageChange}
        />
    );
}

export default LanguageSelect;