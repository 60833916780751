export type Contact = {
    name: string;
    role: string;
    email: string;
    phone?: string;
}

export const CONTACTS: { [company: string]: Contact[]} = {
    "Ficha": [
        {
            name: "Vincent Hipault",
            role: "ceo",
            email: "vincent@ficha.fr",
        },
        {
            name: "Hubert Ménard",
            role: "general director",
            email: "hubert@ficha.fr",
        },
        {
            name: "Guillaume Dionisi",
            role: "developer",
            email: "guillaume.dionisi@ficha.fr",
        },
    ],
};

export const SEPUR_ID = "LDvnVbiN0fp5rCCtoY4D";