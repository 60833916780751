import { List } from '@mui/material';
import { LESS_COMMON_ERRORS } from 'constants/trash';
import WasteTypeDrawer from '../WasteTypeDrawer/WasteTypeDrawer';

type ListProps = {
    canEditResults?: boolean;
}

export default function LessCommonWastesList({ canEditResults }: ListProps) {

    return (
        <List>
            {LESS_COMMON_ERRORS.map((wasteType) => (
                <WasteTypeDrawer
                    key={wasteType}
                    wasteType={wasteType}
                    canEditResults={canEditResults}
                />
            ))}

        </List>
    );
}