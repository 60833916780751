import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BatchMediaOverlayType, MediaOverlayActions } from 'store/reducers/batches/media_overlay';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import SquareTwoToneIcon from '@mui/icons-material/SquareTwoTone';
import PentagonTwoToneIcon from '@mui/icons-material/PentagonTwoTone';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

type OverlayChoiceToggleProps = {

}

const getButtonIcon = (type: BatchMediaOverlayType) => {
    switch (type) {
        // case BatchMediaOverlayType.NONE: return <VisibilityOffRoundedIcon />;
        case BatchMediaOverlayType.BBOXES: return <SquareTwoToneIcon />;
        case BatchMediaOverlayType.AI_MASKS: return <PentagonTwoToneIcon />;
    }
}

/**
 * Toggle buttons to select what the user wants to see on top of the batch's media:
 * * Editable bounding boxes
 * * Masks calculated by AI
 * * Nothing
 */
export default function OverlayChoiceToggle({ }: OverlayChoiceToggleProps) {

    const { t } = useTranslation([Namespace.GLOSSARY]);

    const selectedType = useAppSelector(state => state.batches.mediaOverlay.overlayType);

    const dispatch = useAppDispatch();

    const handleChange = (value: BatchMediaOverlayType) => {
        dispatch(MediaOverlayActions.changeOverlayType(value));
    }

    return (
        <Box
            position="absolute"
            top={theme => theme.spacing(4)}
            display="flex"
            width="100%"
            justifyContent="center"
            sx={{ pointerEvents: "none" }}
            >
            <ToggleButtonGroup
                color="primary"
                value={selectedType}
                exclusive
                onChange={(e, v) => handleChange(v)}
                sx={{ pointerEvents: "all" }}
            >
                {Object.values(BatchMediaOverlayType).map(type => (
                    <ToggleButton
                        key={type}
                        value={type}
                        color="info"
                        sx={{
                            color: "#fff",
                            gap: 1,
                            textTransform: "none",
                            borderColor: "rgba(255, 255, 255, 0.12)",
                        }}
                    >
                        {getButtonIcon(type as BatchMediaOverlayType)}
                        {t(`batch_media_overlay_type.${type}`, { ns: Namespace.GLOSSARY })}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}