import BatchesController from 'controllers/batches';
import { cleanCanvas, drawPolygons } from 'helpers/draw';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BatchMediaOverlayType } from 'store/reducers/batches/media_overlay';

type BatchAIMasksOverlayProps = {
    width: number;
    height: number;
    left?: number;
    top?: number;
}

export default function BatchAIMasksOverlay(canvasDims: BatchAIMasksOverlayProps) {
    const hidden = useAppSelector(state => state.batches.mediaOverlay.overlayType !== BatchMediaOverlayType.AI_MASKS);

    const imageZoom = useAppSelector(state => state.batches.list.selectedBatch!.imageZoom);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvas = canvasRef.current;

    const dispatch = useAppDispatch();

    const imageWidth = useAppSelector(state => state.batches.mediaOverlay.imageWidth);
    const imageHeight = useAppSelector(state => state.batches.mediaOverlay.imageHeight);

    const partnerID = useAppSelector(state => state.batches.list.selectedBatch!.partnerID);
    const batchID = useAppSelector(state => state.batches.list.selectedBatch!.ID);

    const hiddenTrashTypes = useAppSelector(state => state.batches.mediaOverlay.hiddenTrashTypes);

    /**
     * Load AI results whenever the "AI_MASKS" overlay type is selected
     * if they are not loaded already
     */
    const aiResults = useAppSelector(state => state.batches.list.selectedBatch?.aiResults);

    useEffect(() => {
        if (!hidden && !aiResults) {
            dispatch(BatchesController.getAIResults(partnerID, batchID));
        }
    }, [hidden, aiResults]);
    
    useEffect(() => {
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                cleanCanvas(ctx, canvas.width, canvas.height); // remove previous drawings

                if (aiResults) {
                    const resultsToDraw = aiResults.filter(aiResult => !hiddenTrashTypes.includes(aiResult.class));

                    drawPolygons(ctx, resultsToDraw);
                }
            }
        }
    }, [aiResults, canvas, hiddenTrashTypes]);

    return (
        <canvas
            ref={canvasRef}
            hidden={hidden}
            width={imageWidth}
            height={imageHeight}
            style={{
                position: "absolute",
                translate: `${imageZoom.transform.x} ${imageZoom.transform.y}`,
                scale: imageZoom.zoom,
                ...canvasDims,
            }} />
    );
}