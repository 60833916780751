export type NumberInput = {
    value: number;
    error: string | null;
}

export type TextInput = {
    value: string;
    error: string | null;
}

export type StringsArrayInput = {
    value: string[];
    error: string | null;
}

export type NumbersArrayInput = {
    value: number[];
    error: string | null;
}

export type BooleanInput = {
    value: boolean;
    error: string | null;
}

export type FileInput = {
    file: File | null;
    error: string | null;
}

/**
 * Print all the fields of a form data into the console
 * @param {FormData} formData 
 */
export function logFormData(formData: FormData) {
    const keys = formData.keys();
    const values = formData.values();

    for (const key of keys) {
        console.debug(`${key}: ${values.next().value}`);
    }
}

/**
 * Styling of menus' option groups element
 */
export const OPTION_GROUP_STYLE = {
    disabled: true,
    sx: {
        bgcolor: "rgba(0,0,0,0.12)",
        borderTop: "solid 1px rgba(0,0,0,0.2)",
        "&.Mui-disabled": {
            opacity: 0.6,
        },
    }
};