import { PartnerData } from "models/Partner";
import _ from "lodash";
import { Query } from "firebase/firestore";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { useAppSelector } from "store/hooks";

type SelectedPartnerContext = LoadableContext & {
    data: PartnerData | undefined;
};

const initialState: SelectedPartnerContext = {
    loading: false,
    error: null,
    data: undefined,
};


export const selectedPartnerSlice = createSlice({
    name: 'selected_partner',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setSelected: (state, { payload: partner }: PayloadAction<PartnerData | undefined>) => {
            state.loading = false;
            state.data = partner;
        },
        update: (state, { payload: data }: PayloadAction<Partial<PartnerData>>) => {
            state.loading = false;
            if (state.data) {
                state.data = {
                    ...state.data,
                    ...data,
                };
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedPartnerActions = selectedPartnerSlice.actions;

const SelectedPartnerReducer = selectedPartnerSlice.reducer;

export default SelectedPartnerReducer;