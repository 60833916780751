import { Box } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import BatchesByPlaceDrawer from './BatchesByPlaceDrawer/BatchesByPlaceDrawer';
import BatchesMap from './BatchesMap';

type BatchesMapWrapperProps = {

}

export const MAP_HEIGHT = "80vh"; // 80% of viewport's height

function BatchesMapWrapper(props: BatchesMapWrapperProps) {
    const { } = props;

    const mapWrapperRef = useRef<HTMLDivElement>(null);

    const loading = useAppSelector(state => state.batches.list.loading);

    return (
        <Box
            ref={mapWrapperRef}
            id="batches-map"
            width="100%"
            height={MAP_HEIGHT}
            mb={4}
            borderRadius={4}
            border={1}
            borderColor="rgba(0,0,0,0.12)"
            overflow="hidden"
            position="relative"
        >
            {loading && (
                <SectionLoader />
            )}

            <BatchesMap
                width={mapWrapperRef.current?.offsetWidth}
                height={400}
            />

            <BatchesByPlaceDrawer />
        </Box>
    );
}

export default BatchesMapWrapper;