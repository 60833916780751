import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MissionsAddressWithBatchCount } from "constants/types";
import { MissionsData } from "models/Missions";
import { UserData } from "models/User";
import { LoadableContext } from "store/store";

type SelectedMissionContext = LoadableContext & {
    selectedMission: MissionsData | null;
};

const initialState: SelectedMissionContext = {
    loading: false,
    error: null,
    selectedMission: null,
};

export const selectedMissionSlice = createSlice({
    name: 'selectedMission',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setSelected: (state, { payload: mission }: PayloadAction<MissionsData | null>) => {
            state.loading = false;
            state.selectedMission = mission;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        clearSelected: (state) => {
            state.selectedMission = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        updateAmbassadors: (state, { payload: ambassadors }: PayloadAction<UserData[]>) => {
            if (state.selectedMission) {
                state.selectedMission.assigned = ambassadors;
            }
        },
        updateAddresses: (state, { payload: address }: PayloadAction<MissionsAddressWithBatchCount>) => {
            if (state.selectedMission) {
                const isAssigned = state.selectedMission.addresses!.some(a => a.addressKey === address.addressKey);
                if (isAssigned) {
                    state.selectedMission.addresses = state.selectedMission.addresses!.filter(a => a.addressKey !== address.addressKey);
                } else {
                    state.selectedMission.addresses!.push(address);
                }
            }
        },
    },
});

export const SelectedMissionActions = selectedMissionSlice.actions;

const SelectedMissionReducer = selectedMissionSlice.reducer;

export default SelectedMissionReducer;
