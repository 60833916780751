import { Modal } from '@mui/material';
import { BoundingBox } from 'models/BoundingBox';
import { Moment } from 'moment';
import FullScreenBatchImage from 'components/_include/FullScreenBatchImage';
import { useAppSelector } from 'store/hooks';

type FullScreenBatchPictureModalProps = {
    pictureDate: Moment;
    pictureURL: string;
    bboxes: BoundingBox[];
    open: boolean;
    onClose: () => void;
}

export default function FullScreenBatchPictureModal({ pictureDate, pictureURL, bboxes, open, onClose }: FullScreenBatchPictureModalProps) {
    const batchID = useAppSelector(state => state.batches.list.selectedBatch?.ID ?? "");
    const batchLoading = useAppSelector(state => state.batches.list.selectedBatch?.loading ?? false);

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{

            }}
            slotProps={{
                backdrop: {
                    sx: {
                        bgcolor: "rgba(0,0,0,.9)"
                    }
                }
            }}
        >
            <FullScreenBatchImage 
                batchID={batchID }
                loading={batchLoading}
                pictureDate={pictureDate} 
                pictureURL={pictureURL}
                bboxes={bboxes} 
                onClose={onClose}
                />
        </Modal>
    );
}