import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore";
import Route from "./Route";
import Truck from "./Truck";
import { pick } from "lodash";
import { Timestamp } from "firebase/firestore";

export interface Frequency {
    unit: "months" | "weeks",
    interval: number;
    start: Timestamp;
}

export enum Weekday {
    MONDAY = "1",
    TUESDAY = "2",
    WEDNESDAY = "3",
    THURSDAY = "4",
    FRIDAY = "5",
    SATURDAY = "6",
    SUNDAY = "0",
}

export type StartAndEndTimes = { start: string, end: string };

export type Calendar = Partial<{ [day in Weekday]: StartAndEndTimes }>;

type CollectionScheduleTruckData = Pick<Truck, "ID" | "identificationNumber">;
type CollectionScheduleRouteData = Pick<Route, "ID" | "label" | "type">;

export type CollectionScheduleDbData = {
    /** Name of the schedule */
    label: string;

    /** Truck scheduled to go on collection */
    truck: CollectionScheduleTruckData;

    /** 
     * @deprecated 
     * List of weekdays on which the truck is going on collection. 
     * @example [1,3,0] // Monday, Wednesday and Sunday
     */
    weekdays: number[];

    /** 
     * Start and end times for each weekday on which the truck is going on collection. 
     * @example 
     * [{ 
     *      "1": { start: "04:00", end: "13:30" }], // Monday between 4am and 1:30pm
     *      "3": { start: "17:00", end: "20:00" }]  // Wednesday between 5pm and 8pm
     * }]
     */
    calendar: Calendar;

    /**
     * How often the collection happens. Leave empty for collection to repeat every week.
     * @example { unit: "weeks", interval: 2, start: Timestamp<2023-11-27> } // every second week, starting on the week from Nov 27th 2023
     */
    // frequency?: Frequency;

    /**
     * The named itinerary that the truck will follow, 
     * often linked to the name(s) of the town(s) that the truck passes by
     */
    route: CollectionScheduleRouteData;

    /**
     * How often the collection happens. Leave empty for collection to repeat every week.
     * @example 
     * // every second week, starting on the week from Nov 27th 2023
     * { unit: "weeks", interval: 2, start: Timestamp<2023-11-27> }
     */    
    frequency?: Frequency;
}

export type CollectionSchedule = Omit<CollectionScheduleDbData, "weekdays" | "frequency"> & {
    /** Id of the collection schedule's document in the Firestore database. */
    ID: string;

    /** Id of the partner this schedule is attached to (owner of the truck) */
    partnerID: string;

    /** @deprecated List of weekdays on which the truck is going on collection. */
    weekdays: Weekday[];

    frequency?: Omit<Frequency, "start"> & {
        start: number;
    };
}

export const numberToWeekday = (d: number) => d.toString() as Weekday;

export function fromDbData(ID: string, partnerID: string, data: CollectionScheduleDbData): CollectionSchedule {
    return {
        ID: ID,
        partnerID: partnerID,
        weekdays: data.weekdays.map(weekdayNumber => numberToWeekday(weekdayNumber)),
        calendar: data.calendar ? data.calendar : data.weekdays.reduce((cal, day) => { 
            // LEGACY: build calendar with times covering the entire day if not set
            cal[numberToWeekday(day)] = { start: "00:00", end: "23:59" };
            return cal;
        }, {} as Calendar),
        frequency: data.frequency ? {
            ...data.frequency,
            start: data.frequency?.start.toMillis(),
        } : undefined,
        ...pick(data, ["label", "truck", "route"]),
    };
}

export function fromDbDoc(doc: QueryDocumentSnapshot<DocumentData>): CollectionSchedule {
    const data = doc.data() as CollectionScheduleDbData;
    const partnerRef = doc.ref.parent.parent!;
    return fromDbData(doc.id, partnerRef.id, data);
}

export default CollectionSchedule;