import { List } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectRoutesIds } from 'store/reducers/routes/list';
import OnboardingSetupCard from '../OnboardingSetupCard';
import DeleteRouteDialog from './DeleteRouteDialog';
import EditRouteDialog from './EditRouteDialog';
import RouteItem from './RouteItem';
import OnboardingSetupDisabled from '../OnboardingSetupDisabled';

type RoutesSetupCardProps = {
}

type SelectedRouteState = {
    routeID: string | null;
    action: "edit" | "delete" | null;
}

function RoutesSetupCard(props: RoutesSetupCardProps) {
    const { } = props;

    const routesIDs = useAppSelector(state => selectRoutesIds(state));

    const disabled = useAppSelector(state => state.trucks.list.ids.length === 0);
    const Wrapper = !disabled ? OnboardingSetupCard : OnboardingSetupDisabled;

    const [selectedRouteState, setSelectedRouteState] = useState<SelectedRouteState>({ routeID: null, action: null });

    const onEditClicked = useCallback((routeID: string) => {
        setSelectedRouteState({ 
            routeID,
            action: "edit",
        });
    }, [setSelectedRouteState]);

    const onDeleteClicked = useCallback((routeID: string) => {
        setSelectedRouteState({ 
            routeID,
            action: "delete",
        });
    }, [setSelectedRouteState]);

    return (
        <Wrapper
            type="routes"
            completed={routesIDs.length > 0}
        >
            <List>
                {routesIDs.map((routeID) => (
                    <RouteItem
                        key={routeID}
                        routeID={routeID}
                        onEditClicked={onEditClicked}
                        onDeleteClicked={onDeleteClicked}
                    />
                ))}
            </List>

            <EditRouteDialog 
                routeID={selectedRouteState.action === "edit" ? selectedRouteState.routeID : null} 
                onClose={() => setSelectedRouteState({ routeID: null, action: null, })}
                />
            <DeleteRouteDialog 
                routeID={selectedRouteState.action === "delete" ? selectedRouteState.routeID : null} 
                onClose={() => setSelectedRouteState({ routeID: null, action: null, })}
                />
        </Wrapper>
    );
}

export default RoutesSetupCard;