import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import CollaboratorsController from 'controllers/collaborators';
import { Namespace } from 'locales/translations';
import { UserRole } from 'models/User';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCanWrite, usePartnerID } from 'store/hooks';
import { selectAllCollaborators } from 'store/reducers/collaborators/list';
import TrashIcon from '@mui/icons-material/DeleteForeverRounded';
import RemoveCollaboratorDialog from './RemoveCollaboratorDialog';
import { SelectedCollaboratorActions } from 'store/reducers/collaborators/selected';

const ROW_PADDING_X = 2;

/** DataGrid component with custom styling applied */
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaders': {
        paddingLeft: theme.spacing(ROW_PADDING_X),
        paddingRight: theme.spacing(ROW_PADDING_X),
    },
    '& .MuiDataGrid-row': {
        paddingLeft: theme.spacing(ROW_PADDING_X),
        paddingRight: theme.spacing(ROW_PADDING_X),
    },
}));

/** Data table to display the list of collaborators. */
export default function CollaboratorsTable() {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.CONTACTS, Namespace.DATES]);

    const partnerID = usePartnerID();
    const canDelete = useCanWrite(partnerID);

    const collaborators = useAppSelector(selectAllCollaborators);

    const dispatch = useAppDispatch();

    /** Toggle "Remove collaborator" dialog. */
    const selectCollaboratorToDelete = useCallback((collaboratorID: GridRowId) => {
        dispatch(SelectedCollaboratorActions.setSelected(collaborators.find(c => c.ID === collaboratorID) ?? null));
    }, [dispatch, collaborators]);

    /** List of columns and their respective props for the collaborators table. */
    const columns: GridColDef[] = useMemo(() => ([
        {
            field: 'fullName',
            headerName: t('full name', { ns: Namespace.CONTACTS }),
            minWidth: 160,
            disableColumnMenu: true,
        },
        {
            field: 'email',
            headerName: t('email address', { ns: Namespace.CONTACTS }),
            minWidth: 320,
            disableColumnMenu: true,
        },
        {
            field: 'role',
            headerName: t('role', { ns: Namespace.CONTACTS }),
            minWidth: 160,
            disableColumnMenu: true,
            type: 'singleSelect',
            valueOptions: [UserRole.PARTNER_ADMIN, UserRole.PARTNER_MEMBER, UserRole.AMBASSADOR],
            getOptionLabel: (value) => t(value, { ns: Namespace.CONTACTS }),
            // editable: true,
        },
        {
            field: 'addedAt',
            headerName: t('added at', { ns: Namespace.CONTACTS }),
            minWidth: 160,
            disableColumnMenu: true,
            type: 'dateTime',
            valueFormatter: ({ value }) => t("day_month_year", { ns: Namespace.DATES, date: value }),
        },
        {
            field: 'actions',
            headerName: t('actions', { ns: Namespace.COMMONS }),
            minWidth: 200,
            disableColumnMenu: true,
            type: 'actions',
            getActions: ({ id }) => {
                let actions = [];
                if (canDelete) { // add delete button
                    actions.push(
                        <GridActionsCellItem // delete button
                            key="delete"
                            color='secondary'
                            icon={<TrashIcon />}
                            label={t("delete", { ns: Namespace.ACTIONS })}
                            onClick={() => selectCollaboratorToDelete(id)}
                        />
                    );
                }
                return actions;
            }
        },
    ]), [t, canDelete, selectCollaboratorToDelete]);

/** List of the rows corresponding to each collaborator */
const rows = useMemo(() => {
    return collaborators.map(collaborator => ({
        id: collaborator.ID,
        fullName: `${collaborator.firstName} ${collaborator.lastName}`,
        email: collaborator.email,
        role: t(collaborator.role, { ns: Namespace.CONTACTS }),
        addedAt: moment(collaborator.addedAt).toDate(),
    }));
}, [t, collaborators]);

useEffect(() => {
    if (partnerID) dispatch(CollaboratorsController.list(partnerID));
}, [partnerID]);

return (
    <>
        <StyledDataGrid
            autoHeight
            rows={rows}
            columns={columns}
            // style={}
            // initialState={{
            //     pagination: {
            //         paginationModel: {
            //             pageSize: 10,
            //         },
            //     },
            // }}
            pageSizeOptions={[100]}
            disableRowSelectionOnClick
        />

        <RemoveCollaboratorDialog />
    </>
)
}