import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import DialogIcon from "components/_include/DialogIcon";
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ValidationShortcutsActions } from "store/reducers/preferences/validation_shortcuts";
import ValidationShortcutsList from "./ValidationShortcutsList";

export default function EditValidationShortcutsDialog() {

    const open = useAppSelector(state => state.preferences.validation.dialogOpen);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.PREFERENCES,]);

    const closeDialog = () => dispatch(ValidationShortcutsActions.toggleDialog(false));

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            >
            <DialogIcon Icon={SettingsIcon} />
            <DialogTitle align="center">
                {t("shortcuts_preferences", { ns: Namespace.PREFERENCES })}
            </DialogTitle>
            <DialogContent>
                <ValidationShortcutsList />
            </DialogContent>
        </Dialog>
    );
}