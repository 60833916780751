import { Box, ButtonBase, Collapse, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import { selectTruckById } from 'store/reducers/trucks/list';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

export default function TruckExceptionsModal() {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const [expanded, setExpanded] = useState(false);

    const truckExceptions = useAppSelector(state => selectTruckById(state, state.batches.list.selectedBatch?.truckID ?? "")?.exceptions ?? {});

    const exceptionsList = useMemo(() => Object.values(truckExceptions).map(e => e.description), [truckExceptions]);

    if (!exceptionsList.length) return null;

    return (
        <Paper
            sx={{
                position: "absolute",
                left: theme => theme.spacing(2),
                top: theme => theme.spacing(2),
            }}
        >
            <Box position="relative">
                <Box
                    sx={{
                        p: 2,
                        pr: 1,
                    }}
                >
                    <Collapse
                        orientation='horizontal'
                        in={expanded}
                        collapsedSize={140} // indicator + gap + chevron = 48px width
                    >
                        <Box
                            display="inline-flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={1}
                        >
                            {expanded ? ( // modal fully open
                                <List dense>
                                    {exceptionsList.map(exception => (
                                        <ListItem key={exception}>
                                            <ListItemText
                                                sx={{
                                                    m: 0,
                                                    whiteSpace: "nowrap"
                                                }}
                                                primaryTypographyProps={{
                                                    variant: "subtitle1"
                                                }}
                                            >
                                                • {exception}
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : ( // show simple label indicator saying whether there are errors
                                <Typography variant='subtitle1' py={1.5}>
                                    {`⚠️ ${t("exceptions", { ns: Namespace.GLOSSARY })}`}
                                </Typography>
                            )}

                            {expanded ? ( // clickable icon button
                                <IconButton
                                    onClick={() => setExpanded(false)}
                                    sx={{ color: '#000' }}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                            ) : ( // simple icon (full card is clickable)
                                <ChevronRightIcon />
                            )}
                        </Box>
                    </Collapse>
                </Box>

                {!expanded && ( // make collapsed paper clickable to expand
                    <ButtonBase
                        sx={{
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            left: 0,
                            width: "100%"
                        }}
                        onClick={() => setExpanded(true)}
                    />
                )}
            </Box>
        </Paper>
    );
}