import { CenterAndBounds, ErrorsByAddress } from "constants/types";
import { Bounds, Coords, MapOptions, Maps } from "google-map-react";
import { PositionedBatch } from "models/Batch";
import { DangerLevel } from "constants/stats";
import SavedAddress from "models/SavedAddress";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

/**
 * Additional properties used by GeoJSON softwares to style the markers 
 */
export type GeoJSONStyling = {
    "marker-color": string;
    "marker-size": "small" | "medium" | "large";
}

/**
 * Structure for a single batch displayed as a point on a map (e.g. single collection details)
 */
export type BatchPoint = {
    batchID: string;
    addressKey: string;
    dangerLevel: DangerLevel;
    position: Coords;
}

/**
 * Properties for a map point representing a single address,
 * as a "cluster" of the batches at this address over time
 */
export type AddressPointProperties = ErrorsByAddress & Pick<PositionedBatch, "address" | "hereID"> & {
    /** List of the IDs of the batches at this address */
    batchesIDs: string[];

    /** Number of batches at this address */
    batchesCount: number;

    /** Number of batches with at least 1 error at this address */
    batchesWithErrorsCount: number;

    /** Indicates how many errors were made at this address */
    dangerLevel: DangerLevel;
};

/**
 * GeoJSON feature representing a single point
 */
export type AddressStatsPointFeature = {
    type: "Feature";
    properties: AddressPointProperties,
    geometry: {
        type: "Point";
        coordinates: number[];
    };
}

type Viewport = {
    northeast: Coords;
    southwest: Coords;
};

export type MapView = {
    north: number;
    east: number;
    south: number;
    west: number;
}

export type MapGeometry = {
    location: Coords;
    viewport: Viewport;
}

export type SimpleAddress = {
    name: string;
    vicinity: string;
}

export const UNNAMED_ROAD = "Unnamed Road";

export const DEFAULT_ZOOM_LEVEL = 15;
export const MAX_ZOOM = 20;

export const GRENOBLE_COORDINATES = { lat: 45.19, lng: 5.72 };

export const DEFAULT_CENTER_AND_BOUNDS: CenterAndBounds = {
    bounds: {
        nw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 },
        sw: { lat: 0, lng: 0 },
        se: { lat: 0, lng: 0 },
    },
    center: GRENOBLE_COORDINATES,
};

export function getBoundsZoomLevel(bounds: Bounds, mapDim: { width: number, height: number }) {
    const WORLD_DIM = { height: 360, width: 360 };

    function latRad(lat: number) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.ne;
    const sw = bounds.sw;

    const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    const lngDiff = ne.lng - sw.lng;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, MAX_ZOOM);
}

export function getBoundsFromZoomAndCenter(center: Coords, zoom: number, mapDim: { width: number, height: number }): Bounds {
    const worldDim = { height: 360, width: 360 };
    const fraction = 1 / Math.pow(2, zoom);

    const latRadCenter = center.lat * Math.PI / 180;
    const lngRadCenter = center.lng * Math.PI / 180;

    const latRadNorth = Math.min(Math.PI / 2, latRadCenter + (mapDim.height / 2) * fraction * Math.PI / (180 * worldDim.height));
    const latRadSouth = Math.max(-Math.PI / 2, latRadCenter - (mapDim.height / 2) * fraction * Math.PI / (180 * worldDim.height));
    const lngRadWest = lngRadCenter - (mapDim.width / 2) * fraction * Math.PI / (180 * worldDim.width);
    const lngRadEast = lngRadCenter + (mapDim.width / 2) * fraction * Math.PI / (180 * worldDim.width);

    const ne = { lat: 180 * Math.atan(Math.exp(latRadNorth)) / Math.PI, lng: 180 * lngRadEast / Math.PI };
    const sw = { lat: 180 * Math.atan(Math.exp(latRadSouth)) / Math.PI, lng: 180 * lngRadWest / Math.PI };

    return viewportToBounds({ northeast: ne, southwest: sw });
}

export function getMapOptions(maps: Maps): MapOptions {
    return {
        maxZoom: MAX_ZOOM,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
            ],
        },
        clickableIcons: false,
        fullscreenControl: false,
    }
}

export const viewportToBounds = (viewport: Viewport) => {
    const north = viewport.northeast.lat;
    const south = viewport.southwest.lat;
    const east = viewport.northeast.lng;
    const west = viewport.southwest.lng;

    return {
        nw: { lat: north, lng: west, },
        ne: { lat: north, lng: east, },
        se: { lat: south, lng: east, },
        sw: { lat: south, lng: west, },
    };
}

export const mapViewToBounds = (viewport: MapView) => {
    const { north, east, south, west } = viewport;

    return {
        nw: { lat: north, lng: west, },
        ne: { lat: north, lng: east, },
        se: { lat: south, lng: east, },
        sw: { lat: south, lng: west, },
    };
}

export function getCenterAndBounds(minLat: number, maxLat: number, minLng: number, maxLng: number): CenterAndBounds {
    if (maxLat >= minLat && maxLng >= minLng) { // at least one point
        const center: Coords = {
            lat: (maxLat + minLat) / 2,
            lng: (maxLng + minLng) / 2,
        };
        const bounds: Bounds = {
            nw: { lat: maxLat, lng: minLng },
            ne: { lat: maxLat, lng: maxLng },
            se: { lat: minLat, lng: maxLng },
            sw: { lat: minLat, lng: minLng },
        }

        return { center, bounds };
    }
    else { // no point, return default
        return DEFAULT_CENTER_AND_BOUNDS;
    }
}

export function getPointsBoundsAndCenter(points: (Coords & unknown)[]): CenterAndBounds {
    let minLat = 999;
    let maxLat = -999;
    let minLng = 999;
    let maxLng = -999;

    for (let point of points) {
        if (point.lat < minLat) minLat = point.lat;
        if (point.lat > maxLat) maxLat = point.lat;
        if (point.lng < minLng) minLng = point.lng;
        if (point.lng > maxLng) maxLng = point.lng;
    }

    return getCenterAndBounds(minLat, maxLat, minLng, maxLng);
}

export const mvcArrayToCoords = (mvcArray: google.maps.MVCArray) => {
    return mvcArray.getArray().map(p => ({ lat: p.lat(), lng: p.lng() }))
}

/**
 * Check if two coordinates (object with lat and lng fields) are the same at a given level of precision passed as parameter
 */
export function areSameCoordinates(coord1: Coords, coord2: Coords, precision: number): boolean {
    const latPrecision = Math.pow(10, precision);
    const lngPrecision = Math.pow(10, precision);
    const roundedLat1 = Math.round(coord1.lat * latPrecision) / latPrecision;
    const roundedLng1 = Math.round(coord1.lng * lngPrecision) / lngPrecision;
    const roundedLat2 = Math.round(coord2.lat * latPrecision) / latPrecision;
    const roundedLng2 = Math.round(coord2.lng * lngPrecision) / lngPrecision;
    return roundedLat1 === roundedLat2 && roundedLng1 === roundedLng2;
}

/**
 * Extract and format the address components for display
 */
export function formatAddress(address: Pick<SavedAddress["address"], "houseNumber" | "street" | "postalCode" | "city">): string {
    if (!address) return "";

    const { houseNumber, street, city, postalCode } = address;
    
    let houseNumberPrefix = houseNumber ? `${houseNumber} ` : ""; // house number and space before street name
    
    return `${houseNumberPrefix}${street}, ${postalCode} ${city}`;
}