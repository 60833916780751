import { Box, Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { PARTNER_STATUSES_COLORS, PartnerStatus } from "models/Partner";
import { useTranslation } from "react-i18next";
import PartnerStatusColorIndicator from "./PartnerStatusColorIndicator";

type PartnerStatusAndColorProps = {
    status: PartnerStatus;
}

export default function PartnerStatusAndColor({ status }: PartnerStatusAndColorProps) {
    const { t } = useTranslation([Namespace.PARTNERS]);

    return (
        <>
            <PartnerStatusColorIndicator status={status} />
            <Typography flexGrow={1}>{t(`status.${status}`, { ns: Namespace.PARTNERS })}</Typography>
        </>
    )
}
