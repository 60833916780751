import { ErrorPoint } from './MostFrequentErrorsBarChart'
import { useTranslation } from 'react-i18next'
import { Namespace } from 'locales/translations'
import { useAppDispatch } from 'store/hooks';
import { TrashType } from 'constants/trash';
import _ from 'lodash';
import BatchesController from 'controllers/batches';

type ErrorBarItemProps = {
    interactive?: boolean;
    width: number;
    height: number;
    y: number;
    data: ErrorPoint;
    color: string;
    mx: number;
}

function ErrorBarItem({ interactive, width, height, y, data, color, mx }: ErrorBarItemProps) {
    const { t } = useTranslation([Namespace.GLOSSARY, Namespace.WASTES]);

    const dispatch = useAppDispatch();

    const handleClick = (trashType: TrashType) => {
        // filter batches with selected sorting error on map
        dispatch(BatchesController.applyMapBatchesFilters(
            [
                { fieldPath: "error", opStr: "==", value: trashType },
            ],
            "collection"
        ));
    }

    let textColor = data.textColor;

    return (
        <g
            width="100%"
            transform={y ? `translate(0, ${y})` : "none"}
            cursor={interactive && data.count > 0 ? "pointer" : "default"}
            onClick={() => { 
                if (interactive && data.count > 0) handleClick(data.trashType as TrashType);
            }}
        >
            <rect
                width={width}
                height={height}
                rx={height / 2}
                fill={color}
            />
            {/* <animated.path d={animatedPath} fill={color} stroke={color} strokeWidth={2} /> */}
            {textColor === "#ffffff" && (
                <text
                    x={17}
                    y={height / 2 + 1}
                    textAnchor="left"
                    fill="rgba(0,0,0,0.3)"
                    dominantBaseline="central"
                    fontSize="13"
                >
                    {`${t(data.trashType, { ns: Namespace.WASTES })} : ${t(data.count > 0 ? "errors_count" : "no_error", { ns: Namespace.GLOSSARY, count: data.count })}`}
                </text>
            )}
            <text
                x={16}
                y={height / 2}
                textAnchor="left"
                fill={textColor}
                dominantBaseline="central"
                fontSize="13"
            >
                {`${t(data.trashType, { ns: Namespace.WASTES })} : ${t(data.count > 0 ? "errors_count" : "no_error", { ns: Namespace.GLOSSARY, count: data.count })}`}
            </text>
            {!data.count && ( // add "check" icon if no error
                <svg x="100%"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                overflow="visible"
                >
                    <g
                        width="24"
                        height="24"
                        fill="none"
                        transform={`translate(${-24 - mx * 2}, 1)`}
                    >
                        <path
                            d="M7.2002 12.6L10.8002 16.2L16.8002 9"
                            stroke={textColor}
                            strokeWidth="2.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
                            stroke={textColor}
                            strokeLinecap="round"
                        />
                    </g>
                </svg>
            )}
        </g>
    );
}

export default ErrorBarItem;
// export default memo(ErrorBarItem);