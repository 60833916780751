import { MenuItem } from "@mui/material";

type ItemProps = {
    value: string;
    label: string;
    onClick: (value: string) => void; 
}

export default function BoundingBoxesContextMenuItem({ value, label, onClick }: ItemProps) {
    return (
        <MenuItem
            value={value}
            onClick={() => onClick(value)}
        >
            {label}
        </MenuItem>
    )
}