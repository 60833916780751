import queryString from 'query-string';
import _ from 'lodash';
import { Dictionary } from 'constants/types';

export function getURLParam(key: string) {
    const queryParams = queryString.parse(window.location.search, {});
    return queryParams[key]?.toString();
}

export function getURLParams(keys: string[]): Dictionary {
    const queryParams = queryString.parse(window.location.search, {});
    return _.pick(queryParams, keys);
}

export function checkFileExists(url: string) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
}

/**
 * Simulate a click on a button with a "download" attribute,
 * in order to download a Blob of data as a file.
 */
export function downloadDataAsFile(data: Blob, filename: string) {
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(data);

    a.download = filename;
    a.click();

    // clean up
    window.URL.revokeObjectURL(a.href);
    a.remove();
}