import { Theme, ButtonProps, ButtonBase, SimplePaletteColorOptions, } from '@mui/material';
import { SxProps } from '@mui/system';
import { LabelButtonProps, OnClickAction } from './ActionButton';

type IconActionButtonProps = {
    id?: string;
    children?: any;
    color: ButtonProps["color"];
    disabled?: boolean;
    loading?: boolean;
    buttonStyle: SxProps<Theme>;
    size?: "normal" | "compact";
} & (OnClickAction | LabelButtonProps);

function IconActionButton({ id, children, color, disabled, loading, buttonStyle, size, ...other }: IconActionButtonProps) {

    const buttonDisabled = disabled || loading;

    return (
        <ButtonBase
            id={id}
            disabled={buttonDisabled}
            disableRipple
            {...other}
            sx={{
                pt: 1.9,
                pb: 1.4,
                px: 1.7,
                borderRadius: 1,
                ...buttonStyle,
            }}
        >
            {children}
        </ButtonBase>
    );
}

export default IconActionButton;