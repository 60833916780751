import { Box, IconButton, ListItem } from "@mui/material"
import DropdownMenu from "components/_include/DropdownMenu/DropdownMenu";
import { LESS_COMMON_ERRORS, MOST_COMMON_TRASH_TYPES, TRASH_TYPES_ICONS, TrashType, VALIDATION_WASTES_COLORS } from "constants/trash";
import { Namespace } from "locales/translations";
import { BoundingBox, changeBoundingBoxTrashType, deleteBoundingBox } from "models/BoundingBox";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";
import { WastesInputsActions } from "store/reducers/batches/wastes_inputs";
import TrashIcon from "@mui/icons-material/DeleteForeverRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import { useEffect, useRef } from "react";
import { hexToRgba } from "helpers/draw";

type ItemProps = {
    bbox: BoundingBox;
    canEditResults?: boolean;
}

/**
 * List item containing the details about a specific bounding box.
 * It offers options to change the class, to hide the box and to delete it.
 */
export default function BoundingBoxDetailsItem({ bbox, canEditResults }: ItemProps) {
    const { t } = useTranslation([Namespace.WASTES]);

    const itemRef = useRef<HTMLLIElement>(null);

    const dispatch = useAppDispatch();

    const isSelected = useAppSelector(state => state.batches.mediaOverlay.selectedBoxID === bbox.ID);

    const imageWidth = useAppSelector(state => state.batches.mediaOverlay.imageWidth);
    const imageHeight = useAppSelector(state => state.batches.mediaOverlay.imageHeight);

    const wasteTypesOptions = [...MOST_COMMON_TRASH_TYPES, ...LESS_COMMON_ERRORS]
        .map(trashType => ({
            label: `${TRASH_TYPES_ICONS[trashType]} ${t(trashType, { ns: Namespace.WASTES, count: 1, context: "small" })}`,
            value: trashType,
        }));

    /** Handle changing the trash type with the dropdown menu */
    const handleChangeWasteType = (newType: TrashType) => {
        dispatch(changeBoundingBoxTrashType(bbox, newType, imageWidth, imageHeight));
    }

    /** Handle mouse enter and leave events to highlight bounding box */
    const toggleMouseHover = (highlight: boolean) => {
        dispatch(MediaOverlayActions.hoverBox(highlight ? bbox.ID : null));
    }

    /** Handle "Hide" button clicked to hide/show the bounding box */
    const toggleHideBox = (hide: boolean) => {
        // hide box from drawing layer
        dispatch(MediaOverlayActions.updateOne({
            id: bbox.ID,
            changes: { hidden: hide }
        }));
    }

    /** Handle "Delete" button clicked to remove the bounding box */
    const removeBox = () => {
        dispatch(deleteBoundingBox(bbox, imageWidth, imageHeight));
    }

    /** Open the drawer if the corresponding bounding box is selected */
    useEffect(() => {
        if (isSelected) {
            if (LESS_COMMON_ERRORS.includes(bbox.class)) {
                // open the less common waste drawer if needed
                dispatch(MediaOverlayActions.toggleLessCommonWastesDrawer(true));
            }

            dispatch(WastesInputsActions.toggleDrawer({
                trashType: bbox.class,
                open: true,
            }));

            // scroll item into view
            itemRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    }, [isSelected]);
    
    return (
        <ListItem
            ref={itemRef}
            sx={{ 
                p: 1,
                justifyContent: "space-between",
                ...(isSelected && {
                    bgcolor: hexToRgba(VALIDATION_WASTES_COLORS[bbox.class], 0.3)
                })
            }}
            onMouseEnter={() => toggleMouseHover(true)}
            onMouseLeave={() => toggleMouseHover(false)}
            >
            <DropdownMenu 
                variant="outlined"
                id={`class-select-menu-${bbox.ID}`}
                title=""
                disabled={!canEditResults}
                values={wasteTypesOptions}
                defaultValue={bbox.class}
                onChange={(value) => handleChangeWasteType(value as TrashType)}
                />

            {canEditResults && (
                <Box>
                    <IconButton 
                        color="secondary"
                        onClick={() => toggleHideBox(!bbox.hidden)}
                        sx={{
                            opacity: 0.36,
                            "&:hover": {
                                opacity: 1,
                            },
                            ...(bbox.hidden && { opacity: 1 }),
                        }}
                        >
                        <VisibilityOffIcon fontSize="small" />
                    </IconButton>

                    <IconButton 
                        onClick={removeBox}
                        >
                        <TrashIcon fontSize="small" />
                    </IconButton>
                </Box>
            )}
        </ListItem>
    )
}