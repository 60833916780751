import { Box } from "@mui/material";
import { DRAWER_WIDTH } from "components/Navigation/Drawer";
import AreasControls from "./AreasControls/AreasControls";
import AreasList from "./AreasList/AreasList";
import AreasMap from "./AreasMap/AreasMap";

export default function ManageAreasPage() {
    return (
        <Box
            width="100%"
            height="100vh"
            position="relative"
        >
            <Box
                height='100%'
                width={`calc(100% + ${DRAWER_WIDTH}px)`}
                left={-DRAWER_WIDTH}
                position='relative'
            >
                <AreasMap />
            </Box>

            <Box
                position="absolute"
                pl={2}
                left={0}
                pt={2}
                top={0}
                pr={2}
                right={0}
                maxHeight="100%"
                display="flex"
                flexDirection="column"
                sx={{ pointerEvents: "none" }}
                >
                <AreasControls />

                <AreasList />
            </Box>
        </Box>
    );
}