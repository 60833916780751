import { Box, IconButton, TextField, Typography } from '@mui/material';
import TrashTypeSelect from 'components/_include/Filters/TrashTypeSelect/TrashTypeSelect';
import { TrashType } from 'constants/trash';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/DeleteForeverRounded';

type TruckSortingExceptionsItemProps = {
    index: number;
    fromType: TrashType | null;
    toType: TrashType | null;
    description: string;
    availableValues: { from: TrashType[], to: TrashType[] };
    onChange: (index: number, from: TrashType | null, to: TrashType | null, description: string) => void;
    onDelete: (index: number) => void;
}

const MAX_DESCRIPTION_LENGTH = 80; // max number of characters for the exception's description

export default function TruckSortingExceptionsItem({ index, fromType, toType, description, availableValues, onChange, onDelete }: TruckSortingExceptionsItemProps) {

    const { t } = useTranslation([Namespace.FORMS]);

    const handleFromChange = (value: TrashType) => {
        onChange(index, value, toType, description);
    }

    const handleToChange = (value: TrashType) => {
        onChange(index, fromType, value, description);
    }

    const handleDescriptionChange = (value: string) => {
        onChange(index, fromType, toType, value);
    }

    const descriptionLengthSuffix = description.length ? ` (${description.length}/${MAX_DESCRIPTION_LENGTH})` : "";

    return (
        <Box
            display="flex"
            alignItems="center"
            py={2}
            mb={1}
            gap={3}
            borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                gap={3}
            >
                <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                >
                    <TrashTypeSelect
                        id={`exception-class-detected-menu-${index}`}
                        title={t("onboarding.trucks.exceptions.class_detected", { ns: Namespace.FORMS })}
                        parentLoading={false}
                        options={fromType ? [...availableValues.from, fromType] : availableValues.from}
                        defaultValue={fromType ?? ''}
                        preventEmpty
                        onChange={handleFromChange}
                    />
                    <Typography variant="subtitle2">
                        {t("onboarding.trucks.exceptions.saved_as", { ns: Namespace.FORMS })}
                    </Typography>
                    <TrashTypeSelect
                        id={`exception-class-saved-menu-${index}`}
                        title={t("onboarding.trucks.exceptions.class_saved", { ns: Namespace.FORMS })}
                        parentLoading={false}
                        options={toType ? [...availableValues.to, toType] : availableValues.to}
                        defaultValue={toType ?? ''}
                        preventEmpty
                        onChange={handleToChange}
                    />
                </Box>

                <TextField
                    margin="normal"
                    label={`${t("onboarding.trucks.exceptions.description", { ns: Namespace.FORMS })}${descriptionLengthSuffix}`}
                    value={description}
                    onChange={(e) => handleDescriptionChange(e.currentTarget.value)}
                    inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                />
            </Box>
            <IconButton onClick={() => onDelete(index)}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
}