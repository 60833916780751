import { useState, FormEvent } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import UserController from '../../controllers/user';
import ActionButton from '../_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { UserActions } from 'store/reducers/user';
import { TextInput } from 'helpers/forms';
import { isValidEmail } from 'helpers/strings';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type LostSigninTokenPageProps = {

}

function LostSigninTokenPage(props: LostSigninTokenPageProps) {

    const [email, setEmail] = useState<TextInput>({ value: "", error: null });

    const userLoading = useAppSelector(state => state.user.loading);
    const userError = useAppSelector(state => state.user.error);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS, Namespace.SNACKS, Namespace.TITLES,]);

    const handleSubmitPressed = (event?: FormEvent) => {
        event?.preventDefault();

        let error: string | null = null;

        if (!isValidEmail(email.value)) {
            error = t("sign_in.invalid_email", { ns: Namespace.FORMS });
        }

        if (error) {
            setEmail({
                ...email,
                error: error,
            });
        }
        else {
            // submit form
            const emailContent = `A user has lost their signin token to the truck dashboard:\n \
                - Email: ${email.value} \
                `;
            dispatch(UserController.sendEmailToFicha(emailContent));
        }
    };

    useEffect(() => {
        if (userError) {
            setEmail({
                ...email,
                error: userError,
            });
        }
    }, [userError]);

    const handleEmailChange = (val: string) => {
        if (userError) {
            dispatch(UserActions.resetError());
        }

        setEmail({
            ...email,
            value: val,
            error: null,
        });
    }

    const formIsValid = email.value.length > 0;

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
        >
            <Typography variant="h1" sx={{
                mb: 5
            }}>
                {t("dashboard", { ns: Namespace.TITLES })}
            </Typography>
            <form
                autoComplete="on"
                method="post"
                action="#"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    flexGrow: 1,
                    maxHeight: 400,
                }}
            >
                <Box>
                    <Typography>
                        {t("sign_in.lost_token_instruction", { ns: Namespace.FORMS })}
                    </Typography>
                    <TextField
                        label={t("your_email_address", { ns: Namespace.FORMS })}
                        value={email.value}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            onChange: (event) => { handleEmailChange(event.target.value) }
                        }}
                        error={email.error !== null}
                        helperText={email.error}
                    />

                    <Box
                        textAlign="center"
                    >
                        <ActionButton
                            color="primary"
                            disabled={!formIsValid}
                            loading={userLoading}
                            onClick={(event) => handleSubmitPressed(event)}
                            type="submit"
                        >
                            {t("send", { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </Box>

                    <Box mt={4}>
                        <Link
                            to="/login"
                        >
                            <Button 
                                color="info" 
                                startIcon={<ChevronLeftIcon />}
                                >
                                {t("go_back", { ns: Namespace.ACTIONS })}
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </form>
        </Box>
    )
}

export default LostSigninTokenPage;