import { Menu, MenuItem } from '@mui/material';
import { PartnerStatus } from 'models/Partner';
import PartnerStatusAndColor from './PartnerStatusAndColor';

type PartnerStatusMenuProps = {
    open: boolean;
    value: PartnerStatus;
    onChange: (value: PartnerStatus) => void;
    anchor: HTMLElement | null;
    onClose: () => void;
}

function PartnerStatusMenu({ open, anchor, value, onChange, onClose }: PartnerStatusMenuProps) {

    const onStatusSelected = (status: PartnerStatus) => {
        onChange(status);
        onClose();
    }

    return (
        <Menu
            anchorEl={anchor}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {Object.values(PartnerStatus).map(status => (
                <MenuItem
                    key={status}
                    onClick={() => onStatusSelected(status)}
                    selected={status === value}
                >
                    <PartnerStatusAndColor
                        status={status}
                    />
                </MenuItem>
            ))}
        </Menu>
    );
}

export default PartnerStatusMenu;