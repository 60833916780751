import { useMemo } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option, OptionGroup } from "components/_include/DropdownMenu/DropdownMenu";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAppSelector } from "store/hooks";
import { selectAllPartners } from "store/reducers/partners/list";
import { PartnerStatus } from "models/Partner";
import PartnerStatusColorIndicator from "components/PartnersPage/PartnerCard/PartnerStatus/PartnerStatusColorIndicator";


type PartnerSelectProps = {
    parentLoading: boolean;
    canSelectAll?: boolean;
}

function PartnerSelect({ canSelectAll, parentLoading }: PartnerSelectProps) {

    const loading = useAppSelector(state => state.partners.list.loading) || parentLoading;
    const partners = useAppSelector(state => selectAllPartners(state));
    const selectedPartner = useAppSelector(state => state.partners.selected.data);

    const { t } = useTranslation([Namespace.COMMONS, Namespace.FORMS, Namespace.GLOSSARY, Namespace.PARTNERS,]);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamPartnerID = searchParams.get("partnerID");

    const partnersOptions = useMemo(() => {
        let partnersOptions: (Option | OptionGroup)[] = [];

        if (canSelectAll) { // add option to select all partners (no particular partner selected)
            partnersOptions.push({
                label: t("select.all_partners", { ns: Namespace.FORMS }),
                value: "",
            });
        }
        else if (!selectedPartner) { // add loading option while partners are loading
            partnersOptions.push({
                label: t("loading", { ns: Namespace.COMMONS }),
                value: "",
            });
        }

        // add each partner as an option
        let archivedGroupSet = false;
        partners.forEach(partner => {
            // add a separator between partners and archived ones
            if (partner.status === PartnerStatus.ARCHIVED && !archivedGroupSet) {
                archivedGroupSet = true;
                partnersOptions.push({ label: t("status.archived", { ns: Namespace.PARTNERS, count: 2 }) })
            }

            // add the partner with a color indicating the status
            partnersOptions.push({
                label: partner.name,
                value: partner.ID,
                startAdornment: (<PartnerStatusColorIndicator status={partner.status} />)
            });
        });

        return partnersOptions;
    }, [canSelectAll, selectedPartner, partners, t]);

    const handlePartnerSelected = (id: string) => {
        searchParams.set("partnerID", id);
        searchParams.delete("truckID");
        setSearchParams(searchParams);
    };

    let defaultValue :string, title: string;
    
    if (canSelectAll) { // default can be All partners
        if (searchParamPartnerID) { // a partner is selected
            defaultValue = searchParamPartnerID;
            title = selectedPartner?.name || "";
        }
        else { // no particular partner is selected
            defaultValue = "";
            title = t("select.all_partners", { ns: Namespace.FORMS }).toString();
        }
    }
    else { // default is first partner in the list
        const firstPartner: Option = partnersOptions.find(p => "value" in p) as Option;
        defaultValue = searchParamPartnerID || firstPartner.value;
        title = selectedPartner?.name || firstPartner.label;
    }

    return (
        <DropdownMenu
            id="partner-select-menu"
            title={title}
            loading={loading}
            values={partnersOptions}
            defaultValue={defaultValue}
            startIcon={<LocalShippingIcon />}
            onChange={(partnerID) => handlePartnerSelected(partnerID)}
        />
    );
}

export default PartnerSelect;