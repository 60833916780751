import { Dialog, DialogTitle } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import DownloadReportDialogContent from './DownloadReportDialogContent';

type DownloadReportDialogProps = {
    open: boolean;
    onClose: () => void;
}

function DownloadReportDialog({ open, onClose }: DownloadReportDialogProps) {

    const { t } = useTranslation([Namespace.DIALOGS]);

    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {t("download_report.title", {
                    ns: Namespace.DIALOGS,
                    startDate,
                    endDate,
                    context: "stats"
                })}
            </DialogTitle>

            <DownloadReportDialogContent parentPage="stats" />
        </Dialog>
    );
}

export default DownloadReportDialog;