import { Box, List, Typography } from '@mui/material';
import CollaboratorsController from 'controllers/collaborators';
import { Namespace } from 'locales/translations';
import Collaborator from 'models/Collaborator';
import { UserData, UserRole } from 'models/User';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import AssignAmbassadorListItem from './AmbassadorListItem';
import { selectAllCollaborators } from 'store/reducers/collaborators/list';

const convertToUserData = (collaborator: Collaborator, partnerID: string): UserData => ({
    name: `${collaborator.firstName} ${collaborator.lastName}`,
    role: collaborator.role,
    partnerID: partnerID,
    email: collaborator.email,
});

const AmbassadorsList: React.FC = () => {
    const { t } = useTranslation([Namespace.MISSIONS]);
    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (partnerID) {
            dispatch(CollaboratorsController.list(partnerID));
        }
    }, [partnerID, dispatch]);

    const collaborators = useAppSelector(selectAllCollaborators);

    // Filter collaborators with role ambassador and convert to UserData type
    const ambassadors: UserData[] = useMemo(() => {
        if (!partnerID) return [];
        return collaborators
            .filter((collaborator): collaborator is Collaborator => collaborator?.role === UserRole.AMBASSADOR)
            .map(collaborator => convertToUserData(collaborator, partnerID));
    }, [collaborators, partnerID]);

    return (
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start">
            <Typography variant="h6" gutterBottom>
                {t('assigned ambassadors')}
            </Typography>
            <List>
                {ambassadors.map(ambassador => (
                    <AssignAmbassadorListItem key={ambassador.email} ambassador={ambassador} />
                ))}
            </List>
        </Box>
    );
};

export default AmbassadorsList;
