import { Box, BoxProps } from "@mui/material"
import { AppIcon } from "constants/pages"

type DialogIconProps = BoxProps & {
    Icon: AppIcon;
}

export default function DialogIcon(props: DialogIconProps) {
    const { Icon } = props;
    return (
        <Box 
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={2}
            mb={0}
            >
            <Icon sx={{ fontSize: theme => theme.spacing(8) }} />
        </Box>
    )
}