import { Box, Card, CardContent, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectMissionById } from 'store/reducers/missions/list';

type MissionCardProps = {
    missionID: string;
};

function MissionCard({ missionID }: MissionCardProps) {
    const { t } = useTranslation([Namespace.MISSIONS]);
    
    const { search } = useLocation();

    const imageUrl = 'https://via.placeholder.com/150'; // Use a network image or placeholder

    const mission = useAppSelector(state => selectMissionById(state, missionID));

    if (!mission) return null;

    const { name, addresses, createdAt, deadline, assigned } = mission;
    const assignedTo = assigned?.map(user => user.name).join(', ') || 'N/A';

    return (
        <Link to={{
            pathname: `${missionID}/edit`,
            search: search
        }}>
            <Card style={{ cursor: 'pointer' }}>
                <CardContent>
                    <Box
                        component="img"
                        sx={{
                            height: 140,
                            width: '100%',
                            mb: 2,
                            objectFit: 'cover'
                        }}
                        alt=""
                        src={imageUrl}
                    />
                    <Typography variant="h4" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('created at')} {moment(createdAt).format('LL')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('addresses_to_visit', { ns: Namespace.MISSIONS, count: addresses?.length ?? 0 })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('deadline')}: {moment(deadline).format('LL')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('assigned to')}: {assignedTo}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
}

export default MissionCard;
