import urls from 'constants/urls';
import { Namespace } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, usePartnerID } from 'store/hooks';
import ReportFormatButton from './ReportFormatButton';
import { stringifyUrl } from 'query-string';
import moment from 'moment';
import { API_TIMESTAMP_FORMAT } from 'constants/dates';
import { getAuth } from 'firebase/auth';
import { getResponseFilename } from 'controllers/actions';
import { downloadDataAsFile } from 'helpers/urls';
import { DownloadReportDialogContentProps } from './DownloadReportDialogContent';

function DownloadExcelButton({ parentPage }: DownloadReportDialogContentProps) {
    const { t } = useTranslation([Namespace.DIALOGS]);

    const partnerID = usePartnerID();

    const collection = useAppSelector(state => state.collections.selected.data);
    const truck = useAppSelector(state => state.trucks.selected.data);

    const [searchParams] = useSearchParams();
    const startDate = moment(searchParams.get("startDate")).startOf("day");
    const endDate = moment(searchParams.get("endDate")).endOf("day");
    const areaID = searchParams.get("areaID");

    const [buttonLoading, setButtonLoading] = useState(false);

    const getReport = async () => {
        setButtonLoading(true);

        // refresh Firebase token if needed
        const user = getAuth().currentUser;
        let token = await user?.getIdToken();

        // format query
        const url = parentPage === "stats" ? stringifyUrl({
                url: `${urls.API}/partner/${partnerID}/truck/${truck?.ID}/report`,
                query: {
                    start: startDate.format(API_TIMESTAMP_FORMAT),
                    end: endDate.format(API_TIMESTAMP_FORMAT),
                    ...(areaID && { areaID }),
                },
            }) : stringifyUrl({
                url: `${urls.REPORTS_API}/collection/excel`,
                query: {
                    partnerID,
                    truckID: collection?.truckID,
                    collectionID: collection?.ID,
                },
            });

        // query report Excel file
        const res = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/vnd.ms-excel',
            }
        })
        
        // save response data to Excel file
        const data = await res.blob();
        const defaultFilename = `rapport_${parentPage === "stats" ? truck?.identificationNumber : collection?.schedule.route.label}.xlsx`;
        const filename = getResponseFilename(res, defaultFilename);

        downloadDataAsFile(data, filename);

        setButtonLoading(false);
    };

    return (
        <ReportFormatButton
            imgSource={`${urls.ICONS_FOLDER}/excel_icon.png`}
            label={t("download_report.excel", { ns: Namespace.DIALOGS, context: parentPage })}
            onClick={getReport}
            loading={buttonLoading}
        />
    );
}

export default DownloadExcelButton;