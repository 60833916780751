
import DownloadRounded from '@mui/icons-material/DownloadRounded';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { DANGER_COLORS } from 'helpers/draw';
import { AddressPointProperties } from 'helpers/geo';
import { downloadDataAsFile } from 'helpers/urls';
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectAllSortingPoints } from 'store/reducers/batches/sorting_map';

type ExportToGISButtonProps = {
    parentLoading?: boolean;
}

const EXPORTED_PROPS = ["address", "batchesCount", "batchesWithErrorsCount", "errors"];

export default function ExportToGISButton({ parentLoading }: ExportToGISButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.MAPS, Namespace.WASTES]);

    const allPoints = useAppSelector(selectAllSortingPoints);

    const [loading, setLoading] = useState(parentLoading);

    useEffect(() => {
        setLoading(parentLoading);
    }, [parentLoading]);

    /**
     * Translate the properties of the points to be clearer for use of GeoJSON data outside Ficha
     */
    const formatPropertiesForExport = (props: AddressPointProperties) => ({
        ...EXPORTED_PROPS.reduce((acc, prop) => {
            if (prop === "errors") { // also translate errors
                acc[t(`points_properties.${prop}`, { ns: Namespace.MAPS })] = JSON.stringify(
                    Object.entries(props.errors)
                        .reduce((trashCount, [trashType, count]) => {
                            if (count > 0) { // only include positive number of errors
                                trashCount[t(trashType, { ns: Namespace.WASTES })] = count;
                            }
                            return trashCount;
                        }, {} as { [key: string]: number })
                );
            }
            else acc[t(`points_properties.${prop}`, { ns: Namespace.MAPS }).toString()] = props[prop as keyof AddressPointProperties];
            return acc;
        }, { 
            "marker-color": DANGER_COLORS[props.dangerLevel], 
            "marker-size": "small",
        } as { [key: string]: any })
    });

    /**
     * Encode the selected points and export them into a GeoJSON file
     */
    const handleClick = () => {
        setLoading(true);
        
        const file = new Blob(
            [JSON.stringify({
                type: 'FeatureCollection',
                features: allPoints.map(point => ({ 
                    ...point, 
                    geometry: {
                        ...point.geometry,
                        coordinates: [point.geometry.coordinates[1], point.geometry.coordinates[0]], // GeoJSON inverts lat and lng
                    }, 
                    properties: formatPropertiesForExport(point.properties),
                }))
            })],
            { type: 'application/json' }
        );
        downloadDataAsFile(file, "ficha_data.geojson");

        setLoading(false);
    }

    return (
        <ActionButton
            color="primary"
            onClick={handleClick}
            loading={loading}
            style={{
                pointerEvents: "all",
            }}
            startIcon={<DownloadRounded />}
            size="compact"
            disabled={allPoints.length === 0}
        >
            {t("download_as", { ns: Namespace.ACTIONS, format: "GeoJSON" })}
        </ActionButton>
    );
}