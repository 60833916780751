import { VALIDATION_WASTES_COLORS } from "constants/trash";
import { TrashType } from 'constants/trash';
import { Point, Polygon } from 'constants/types';
import { Timestamp } from "firebase/firestore";
import { cleanCanvas, drawLine, drawRectangle } from 'helpers/draw';
import { BBOX_ID_PREFIX, getBoundingBoxSurface, BoundingBox } from "models/BoundingBox";
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MediaOverlayActions, selectResultBboxesIds } from 'store/reducers/batches/media_overlay';
import { WastesInputsActions } from 'store/reducers/batches/wastes_inputs';
import { Shortcut } from "store/reducers/preferences/validation_shortcuts";

type BoundingBoxPainterProps = {
    trashType: TrashType;
    width: number;
    height: number;
    left?: number;
    top?: number;
}

/**
 * A new bbox drawn manually will have a score between MINIMUM_SCORE and 1
 */
const MINIMUM_SCORE = 0.8;

/**
 * Don't draw boxes that are less than 225 square pixels, to prevent miss-clicks
 */
const MINIMUM_BBOX_AREA = 225;

/**
 * A canva on which users can draw new bounding boxes to add to the batch's results
 */
export default function BoundingBoxPainter({ trashType, ...canvasDims }: BoundingBoxPainterProps) {

    const stopDrawingKey = useAppSelector(state => state.preferences.validation[Shortcut.EXIT_DRAWING_MODE]);

    const imageWidth = useAppSelector(state => state.batches.mediaOverlay.imageWidth);
    const imageHeight = useAppSelector(state => state.batches.mediaOverlay.imageHeight);

    const dispatch = useAppDispatch();

    /**
     * Reference to the index for the next box created
     */
    const nextBoxID = useAppSelector(state => selectResultBboxesIds(state).length);
    const nextBoxIDRef = useRef<number>();
    useEffect(() => {
        nextBoxIDRef.current = nextBoxID;
    }, [nextBoxID]);

    /**
     * Reference to the color for the next box
     */
    const trashTypeRef = useRef<TrashType>();
    useEffect(() => {
        trashTypeRef.current = trashType;
    }, [trashType]);

    /**
     * Add a box to the Redux store when drawing is done
     */
    const finalizeBox = (points: Polygon) => {
        const bboxSurface = getBoundingBoxSurface(points, imageWidth, imageHeight);

        if (bboxSurface * imageWidth * imageHeight < MINIMUM_BBOX_AREA) return; // require a minimum size for the box, preventing miss-clicks
       
        // Add current timestamp
        const createdAt = Timestamp.now().toMillis();
        
        // add box to drawing layer
        const box: BoundingBox = {
            ID: `${BBOX_ID_PREFIX}${nextBoxIDRef.current}`,
            class: trashTypeRef.current!,
            box: points,
            score: Math.random() * (1 - MINIMUM_SCORE) + MINIMUM_SCORE,
            hidden: false,
            createdAt: createdAt,
        }

        dispatch(MediaOverlayActions.addOne(box));

        // increment count for this type of waste
        dispatch(WastesInputsActions.incrementOne({
            trashType: trashTypeRef.current!, 
            surface: bboxSurface / 2,
        }));
    }

    /**
     * Reference the canvas element
     */
    const canvasRef = useRef<HTMLCanvasElement>(null);


    /**
     * Reference the position of the cursor when the user pressed down on the canvas
     */
    const firstPointRef = useRef<Point>();
    const setFirstPoint = (point?: Point) => {
        firstPointRef.current = point;

        dispatch(MediaOverlayActions.setDrawing(Boolean(point)));
    };

    /**
     * Get the cursor position relatively to the canvas dimensions
     * @param clientX The x of the cursor relative to the viewport
     * @param clientY The y of the cursor relative to the viewport
     */
    const getCanvasCursorPosition = (clientX: number, clientY: number) => {
        const canvas = canvasRef.current;

        if (!canvas) return [clientX, clientY];

        const rect = canvas.getBoundingClientRect(); // contains the coordinates of the canvas relative to the viewport
        const scaleX = canvas.width / rect.width;    // size of the canvas (natural size of the image it's overlaying) relative to the element's size
        const scaleY = canvas.height / rect.height;

        return [
            (clientX - rect.left) * scaleX,
            (clientY - rect.top) * scaleY,
        ];
    }

    /**
     * Start drawing a line when the user presses down on the canvas
     */
    const startDrawingLine = (e: MouseEvent) => {
        // get mouse position
        setFirstPoint(getCanvasCursorPosition(e.clientX, e.clientY));
    }

    /**
     * Follow the user's cursor to draw rules and rectangle when dragging after clicking on the canvas.
     */
    const followCursor = (e: MouseEvent) => {
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) return; // not drawing line

        cleanCanvas(ctx, imageWidth, imageHeight); // remove previous drawings

        const [x, y] = getCanvasCursorPosition(e.clientX, e.clientY);

        drawLine(ctx, [x, 0], [x, imageHeight], "#fff", 1); // draw horizontal rule
        drawLine(ctx, [0, y], [imageWidth, y], "#fff", 1); // draw vertical rule

        if (firstPointRef.current) { // draw bounding box preview
            drawRectangle(ctx, firstPointRef.current, [x, y], VALIDATION_WASTES_COLORS[trashTypeRef.current!]);
        }
    }

    /**
     * Finalize the line when the user's stops dragging on the canvas
     */
    const finishDrawingLine = (e: MouseEvent) => {
        if (!firstPointRef.current) return;

        const point1 = firstPointRef.current;
        const point2 = getCanvasCursorPosition(e.clientX, e.clientY);
        
        finalizeBox([
            [
                Math.max(0, Math.round(Math.min(point1[0], point2[0]))), // left
                Math.max(0, Math.round(Math.min(point1[1], point2[1]))), // top
            ],
            [
                Math.min(imageWidth, Math.round(Math.max(point1[0], point2[0]))), // right
                Math.min(imageHeight, Math.round(Math.max(point1[1], point2[1]))), // bottom
            ]
        ]);

        const canvas = canvasRef.current!;
        cleanCanvas(canvas.getContext('2d')!, imageWidth, imageHeight); // remove previous drawings

        setFirstPoint();
    }

    /**
     * Attach mouse events to the canvas
     */
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        canvas.addEventListener('mousedown', startDrawingLine);
        canvas.addEventListener('mousemove', followCursor);
        canvas.addEventListener('mouseup', finishDrawingLine);
        canvas.addEventListener('mouseout', finishDrawingLine);

        return () => {
            const canvas = canvasRef.current;
            if (!canvas) return;
            canvas.removeEventListener('mousedown', startDrawingLine);
            canvas.removeEventListener('mousemove', followCursor);
            canvas.removeEventListener('mouseup', finishDrawingLine);
            canvas.addEventListener('mouseout', finishDrawingLine);
        }

    }, [canvasRef.current]);

    /**
     * Handle keyboard shortcuts
     */
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === stopDrawingKey) { // stop drawing
            dispatch(MediaOverlayActions.selectTrashType(null));
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => { window.removeEventListener("keydown", handleKeyDown); };
    }, [stopDrawingKey]);

    return (
        <canvas
            ref={canvasRef}
            width={imageWidth}
            height={imageHeight}
            style={{
                position: "absolute",
                ...canvasDims,
            }} />
    );
}