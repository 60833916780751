import { SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, styled } from "@mui/material";
import { ACCEPTED_EXCEL_DATES_FORMATS } from "constants/dates";
import { hexToRgba } from "helpers/draw";
import { Namespace } from "locales/translations";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CollectionRFIDActions, ColumnType } from "store/reducers/collections/rfid";

type TableProps = {
    type: ColumnType;
}

const Cell = styled(TableCell)(({ theme }) => ({
    whiteSpace: "nowrap",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
    cursor: "pointer",
}));

export default function SelectColumnTable({ type }: TableProps) {
    
    const { t } = useTranslation([Namespace.FORMS]);

    const collectionDate = useAppSelector(state => state.collections.selected.data?.startAt);

    const tableFirstRow: { [key: string]: string | number } = useAppSelector(state => state.collections.rfid.data?.[0]);

    const selectedColumn = useAppSelector(state => state.collections.rfid.selectedColumns[type]);

    const dispatch = useAppDispatch();

    /** Select/unselect the column of the cell clicked by the user. */
    const handleColumnSelected = useCallback((key: string, value?: string | number) => {
        let datetimeFormat = "";
        
        if (type === "datetime") { // figure out datetime format used
            for (let format of ACCEPTED_EXCEL_DATES_FORMATS) {
                const sampleMoment = moment(value, format, true);
                if (sampleMoment.isValid()) { // valid format
                    // check that day of collection matches
                    const collectionStart = moment(collectionDate);
                    const collectionEnd = collectionStart.clone().add(1, "day");
                    if (sampleMoment.isBetween(collectionStart, collectionEnd)) {
                        datetimeFormat = format;
                    }
                    else {
                        alert(t("upload_rfid.invalid_day", { ns: Namespace.FORMS, date: collectionDate }));
                        return;
                    }
                }
            }

            if (!datetimeFormat) {
                alert(t("upload_rfid.invalid_date_format", { ns: Namespace.FORMS }));
                return;
            }
        }

        dispatch(CollectionRFIDActions.setSelectedColumn({
            type,
            key: selectedColumn === key ? null : key,
            ...(type === "datetime" && { datetimeFormat }),
        }));
    }, [dispatch, selectedColumn]);

    const selectedStyle: SxProps<Theme> = {
        bgcolor: theme => hexToRgba(theme.palette.success.main, 0.4)
    };

    if (!tableFirstRow) return <></>;

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {Object.entries(tableFirstRow).map(([cellLabel, cellValue]) => (
                            <Cell
                                key={cellLabel}
                                sx={{
                                    ...(cellLabel === selectedColumn && selectedStyle),
                                }}
                                onClick={() => handleColumnSelected(cellLabel, cellValue)}
                            >
                                {cellLabel}
                            </Cell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Object.entries(tableFirstRow).map(([cellLabel, cellValue]) => (
                            <Cell
                                key={cellLabel}
                                sx={{
                                    ...(cellLabel === selectedColumn && selectedStyle),
                                }}
                                onClick={() => handleColumnSelected(cellLabel, cellValue)}
                            >
                                {cellValue}
                            </Cell>
                        ))}
                    </TableRow>
                    <TableRow>
                        {Object.keys(tableFirstRow).map((cellLabel) => (
                            <Cell
                                key={cellLabel}
                                sx={{
                                    borderStyle: "dashed",
                                    borderBottom: "none",
                                }}
                            />
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
