import { ListItemButton, ListItemText, ListItemIcon, useTheme, } from "@mui/material";
import { AppIcon } from "constants/pages";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

export type NavigationMenuItemProps = {
    link?: string;
    label: string;
    Icon: AppIcon;
    onClick?: () => void;
}

function NavigationMenuItem(props: NavigationMenuItemProps) {
    const { link, label, Icon, onClick } = props;

    const { t } = useTranslation([Namespace.TITLES]);

    const { search } = useLocation();

    const theme = useTheme();

    return (
        <NavLink
            to={link + search}
            style={({ isActive }) => ({
                ...(isActive && { // style button when active
                    backgroundColor: theme.palette.secondary.main,
                })
            })}
        >
            <ListItemButton
                sx={{
                    color: (theme) => theme.palette.common.white,
                    backgroundColor: "inherit",
                    py: 1,
                    px: "10%",
                    textDecoration: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                }}
                onClick={onClick}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 'auto',
                        marginRight: 2,
                    }}>
                    {<Icon />}
                </ListItemIcon>
                <ListItemText
                    primary={t(label, { ns: Namespace.TITLES })}
                    primaryTypographyProps={{
                        fontSize: "0.9rem",
                        lineHeight: "1rem",
                    }}
                />
            </ListItemButton>
        </NavLink>
    );
}

export default NavigationMenuItem;