import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CollectionSchedule from "models/CollectionSchedule";

type CollectionScheduleContext = LoadableContext & {

};

const initialState: CollectionScheduleContext = {
    loading: false,
    error: null,
};

const collectionsSchedulesAdapter = createEntityAdapter<CollectionSchedule>({
    selectId: collectionSchedule => collectionSchedule.ID,
});

const collectionsSchedulesSlice = createSlice({
    name: 'collectionsSchedulesList',
    initialState: collectionsSchedulesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: collectionsSchedules }: PayloadAction<CollectionSchedule[]>) => {
            state.loading = false;
            collectionsSchedulesAdapter.setAll(state, collectionsSchedules);
        },
        addItem: (state, { payload: data }: PayloadAction<CollectionSchedule>) => {
            state.loading = false;
            collectionsSchedulesAdapter.addOne(state, data);
        },
        updateItem: (state, { payload: { id , data } }: PayloadAction<{ id: string, data: Partial<CollectionSchedule> }>) => {
            state.loading = false;
            collectionsSchedulesAdapter.updateOne(state, {
                id: id,
                changes: data,
            });
        },
        removeItem: (state, { payload: scheduleID }: PayloadAction<string>) => {
            state.loading = false;
            collectionsSchedulesAdapter.removeOne(state, scheduleID);
        },
        removeMany: (state, { payload: schedulesIDs }: PayloadAction<string[]>) => {
            state.loading = false;
            collectionsSchedulesAdapter.removeMany(state, schedulesIDs);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CollectionsSchedulesListActions = collectionsSchedulesSlice.actions;

export const {
    selectAll: selectAllCollectionsSchedules,
    selectById: selectCollectionScheduleByID,
    selectIds: selectCollectionsSchedulesIDs
} = collectionsSchedulesAdapter.getSelectors((state: RootState) => state.collectionsSchedules.list);

const CollectionsSchedulesListReducer = collectionsSchedulesSlice.reducer;

export default CollectionsSchedulesListReducer;