import { ButtonBase, SxProps, Theme } from "@mui/material";
import { getGradient } from "helpers/draw";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { useEffect, useState } from "react";

type ScrollControlProps = {
    direction: "left" | "right";
    hide: boolean;
    onHover: () => void;
}

export default function ScrollControl({ direction, hide, onHover }: ScrollControlProps) {

    const Icon = direction === "left" ? ChevronLeftIcon : ChevronRightIcon;

    const [interval, setInterv] = useState<NodeJS.Timeout>();

    const onEnter = () => {
        const interval = setInterval(onHover, 20);
        setInterv(interval);
    }

    const onLeave = () => {
        if (interval) clearInterval(interval);
    }

    useEffect(() => {
        return onLeave;
    }, []);

    return (
        <ButtonBase
            disableRipple
            sx={{
                position: "absolute",
                width: theme => theme.spacing(8),
                top: 0,
                bottom: 0,
                pointerEvents: "all",
                zIndex: 99,
                ...(direction === "left" ? {
                    left: 0,
                    background: getGradient("horizontal", "rgba(255,255,255,1)", "rgba(255,255,255,0)"),
                    borderBottomLeftRadius: theme => theme.shape.borderRadius * 3,
                } : {
                    right: 0,
                    background: getGradient("horizontal", "rgba(255,255,255,0)", "rgba(255,255,255,1)"),
                    borderBottomRightRadius: theme => theme.shape.borderRadius * 3,
                }),
                ...(hide && { display: "none" })
            }}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onClick={(e) => e.preventDefault()}
        >
            <Icon
                sx={{
                    fontSize: theme => theme.spacing(5),
                    opacity: .5,
                }}
            />
        </ButtonBase>
    )
}