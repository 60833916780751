import MostFrequentErrorsBarChart, { ERROR_CATEGORY, BAR_COLORS, TEXT_COLORS } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsBarChart";
import { DisplayedSortingError, DISPLAYED_SORTING_ERRORS, MergedTrashCount } from "constants/trash";
import { mergeTrashCounts } from "helpers/trash";
import _ from "lodash";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { ErrorPoint } from "../../../_include/Charts/AddressesWithMostErrors/AddressesWithMostErrors";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

export default function MostFrequentErrorsChart() {
    const collectionResults = useAppSelector(state => state.collections.selected.data?.results);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);


    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!collectionResults) return errorsTypes; // results not loaded yet

        const mergedResults = mergeTrashCounts(collectionResults);
        const maxErrorCount = _.max(
            Object.entries(mergedResults)
                .filter(([trashType, _]) => DISPLAYED_SORTING_ERRORS.includes(trashType as DisplayedSortingError))
                .map(([_, count]) => count)
        )!;

        for (const errorType of DISPLAYED_SORTING_ERRORS) {
            const count = mergedResults[errorType as keyof MergedTrashCount] ?? 0;

            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.1) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [collectionResults]);

    // const pieData: WasteSlice[] = useMemo(() => {
    //     let wastesList: WasteSlice[] = [];
    //     if (!collectionResults) return wastesList; // results not loaded yet

    //     let wastes = mergeTrashCounts(collectionResults);

    //     let mergedResults: ErrorsCount = getErrors(collectionResults);

    //     let totalErrors = _.sum(Object.values(mergedResults));

    //     for (const errorType of FILTER_ERRORS) {
    //         const count = wastes[errorType as DisplayedSortingError] ?? 0;

    //         const portion = count / totalErrors;

    //         let category: ERROR_CATEGORY;
    //         if (portion > 0.8) category = ERROR_CATEGORY.WORST;
    //         else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
    //         else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
    //         else category = ERROR_CATEGORY.GOOD;

    //         const value = count / totalErrors * 100;
    //         if (value > 0) {
    //             wastesList.push({
    //                 id: t(errorType, { ns: Namespaces.wastes }),
    //                 label: t(errorType, { ns: Namespaces.wastes }),
    //                 value: Math.round(value),
    //                 color: CHART_COLORS[errorType as TrashTypes],
    //             });
    //         }
    //     }

    //     return wastesList;
    // }, [collectionResults]);

    return (
        <MostFrequentErrorsBarChart
            data={data}
            // pieData={pieData}
            interactive
            height={(theme) => theme.spacing(36)} px={3}
            icons={false}
        />
    )
}