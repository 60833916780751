import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DocumentScannerIcon from '@mui/icons-material/DocumentScannerRounded';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CollectionRFIDActions } from 'store/reducers/collections/rfid';
import SelectColumnTable from './SelectColumnTable';
import ConfirmUploadRFIDButton from './ConfirmUploadRFIDButton';

type UploadRFIDDialogProps = {
    
}

function UploadRFIDDialog({ }: UploadRFIDDialogProps) {

    const open = useAppSelector(state => Boolean(state.collections.rfid.data));

    const collection = useAppSelector(state => state.collections.selected.data);
    
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DATES, Namespace.FORMS]);

    const closeDialog = () => {
        dispatch(CollectionRFIDActions.setData(null));
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="md"
            fullWidth
        >
            <DialogIcon
                Icon={DocumentScannerIcon}
            />
            <DialogTitle align='center'>
                {t("upload_rfid.title", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent 
                sx={{ 
                    display: "flex",
                    flexDirection: "column",
                    gap: 3, 
                }} 
                >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    >
                    <Typography variant="body2">
                        {t("upload_rfid.warning", { ns: Namespace.FORMS })}
                    </Typography>

                    {collection && (
                        <Typography variant="h5" textAlign="center">
                            {`${collection.schedule.label} - ${t("date_full_day", { ns: Namespace.DATES, date: collection.startAt })}`} 
                        </Typography>
                    )}
                </Box>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    >
                    <Typography variant="button">
                        {t("upload_rfid.select_datetime_column", { ns: Namespace.FORMS })}
                    </Typography>

                    <SelectColumnTable type="datetime" />
                </Box>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    >
                    <Typography variant="button">
                        {t("upload_rfid.select_rfid_column", { ns: Namespace.FORMS })}
                    </Typography>

                    <SelectColumnTable type="rfid" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={closeDialog}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <ConfirmUploadRFIDButton />
            </DialogActions>
        </Dialog>
    );
}

export default UploadRFIDDialog;