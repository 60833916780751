import { ErrorsCount, DisplayedSortingError, SORTING_ERRORS, MergedTrashCount, TrashCount, TrashType, SortingError } from "constants/trash";
import { upperFirst, omit } from "lodash";
import { BatchResults, HasErrorType } from "models/Batch";

/**
 * Sum 2 counts of errors into a single one.
 * @returns A new ErrorsCount whose values are the sum of both ErrorsCount passed as parameter.
 */
export function sumErrors(results1: ErrorsCount, results2: ErrorsCount): ErrorsCount {
    const summedResults: ErrorsCount = {};
    Object.entries(results1).forEach(([k, value]) => {
        const key = k as DisplayedSortingError;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    Object.entries(results2).forEach(([k, value]) => {
        const key = k as DisplayedSortingError;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    return summedResults;
}

/**
 * Returns true if the parameter is a sorting error, false if it can go into the sorting bin.
 */
export function isSortingError(trash: string) {
    for (const e of SORTING_ERRORS) {
        if (e === trash) return true;
    }
    return false;
}

/**
 * For some subclasses of waste, the AI is not mature enough to trust its results.
 * Therefore we want to merge its results into some larger classes.
 *  
 * List of re-mapping:
 *  - consider big cardboard as recycle waste
 *  - consider cable, ceramic, chemical as others
 *  - consider polystyrene as bulky
 *  - consider glass jars as glass bottles ("glass")
 *  - consider surgical masks, undesirable fibrous as textiles
 */
export const MERGING_WASTE_MAPPING: Partial<Record<TrashType, TrashType>> = {
    [TrashType.BIG_CARDBOARD]: TrashType.RECYCLE_WASTE,
    [TrashType.CABLE]: TrashType.OTHER,
    [TrashType.CERAMIC]: TrashType.OTHER,
    [TrashType.CHEMICAL]: TrashType.OTHER,
    [TrashType.POLYSTYRENE]: TrashType.BULKY,
    [TrashType.GLASS_JAR]: TrashType.GLASS_BOTTLE,
    [TrashType.SURGICAL_MASK]: TrashType.TEXTILE,
    [TrashType.UNDESIRABLE_FIBROUS]: TrashType.TEXTILE,
}

/**
 * Cluster some waste count by merging classes that go together,
 * to create object with keys that can be displayed to the user.
 * For example, the AI detects "big cardboards", but clients consider them as "recyclable waste",
 * so we merge the counts for both classes.
 */
export function mergeTrashCounts(count: TrashCount): MergedTrashCount;
export function mergeTrashCounts(count: Partial<TrashCount>): Partial<MergedTrashCount>;
export function mergeTrashCounts(count: TrashCount | Partial<TrashCount>) {
    let mergedCount: MergedTrashCount | Partial<MergedTrashCount> = {
        ...omit(count, Object.keys(MERGING_WASTE_MAPPING)),
    };

    Object.entries(MERGING_WASTE_MAPPING).forEach(([subType, parentType]) => {
        const subKey = subType as TrashType;
        const parentKey = parentType as keyof typeof mergedCount;
        const subCount = count[subKey];
        if (subCount) mergedCount[parentKey] = (mergedCount[parentKey] || 0) + subCount;
    });

    return mergedCount;
}


/**
 * Create an empty TrashCount (all values at 0)
 */
export function initTrashCount(): TrashCount {
    return {
        [TrashType.RECYCLE_WASTE]: 0,
        [TrashType.BIG_CARDBOARD]: 0,
        [TrashType.BULKY]: 0,
        [TrashType.CABLE]: 0,
        [TrashType.CERAMIC]: 0,
        [TrashType.CHEMICAL]: 0,
        [TrashType.ELECTRONIC_WASTE]: 0,
        [TrashType.GARBAGE_BAG]: 0,
        [TrashType.GAS_CYLINDER]: 0,
        [TrashType.GLASS_BOTTLE]: 0,        
        [TrashType.GLASS_JAR]: 0,
        [TrashType.GREEN_WASTE]: 0,
        [TrashType.ORGANIC_WASTE]: 0,
        [TrashType.POLYSTYRENE]: 0,
        [TrashType.SURGICAL_MASK]: 0,
        [TrashType.TEXTILE]: 0,
        [TrashType.UNDESIRABLE_FIBROUS]: 0,
        [TrashType.OTHER]: 0,
    };
}

/**
 * Create an empty ErrorsCount (all values at 0)
 */
export function initErrorsCount(): ErrorsCount {
    return {
        [TrashType.BULKY]: 0,
        [TrashType.ELECTRONIC_WASTE]: 0,
        [TrashType.GARBAGE_BAG]: 0,
        [TrashType.GAS_CYLINDER]: 0,
        [TrashType.GLASS_BOTTLE]: 0,        
        [TrashType.GREEN_WASTE]: 0,
        [TrashType.ORGANIC_WASTE]: 0,
        [TrashType.TEXTILE]: 0,
        [TrashType.OTHER]: 0,
    };
}


/**
 * Returns true if the waste results object contains at least 1 sorting error, false otherwise
 */
export const hasError = (results: BatchResults) => Object.entries(results).some(([trashType, count]) => isSortingError(trashType) && count > 0);

/**
 * Calculate the total count of errors in some waste results object
 */
export function getErrorsCount(results: BatchResults) {
    let count = 0;
    for (const errorType of SORTING_ERRORS) {
        const errorCount = results[errorType];
        if (errorCount && errorCount > 0) {
            count += errorCount;
        }
    }
    return count;
}

/**
 * Extract the counts of errors from some waste results object
 * @returns An object with merged waste types so that it can be displayed to the user
 */
export function getErrors(results: BatchResults): Partial<MergedTrashCount> {
    let errors: Partial<TrashCount> = {};
    for (const errorType of SORTING_ERRORS) {
        const errorCount = results[errorType];
        if (errorCount && errorCount > 0) {
            errors[errorType] = errorCount;
        }
    }

    return mergeTrashCounts(errors);
}

/**
 * Calculate the quality (good sorting / total sorting) 
 * using the surfaces from some waste results object
 */
export function getSurfaceQuality(surfaces: BatchResults): number {
    let correctSortingSurface = 0;
    let totalSurface = 0;
    Object.entries(surfaces).forEach(([trashType, surface]) => {
        if (!isNaN(surface)) { // make sure that surface has been calculated
            if (!isSortingError(trashType)) correctSortingSurface += surface;
            totalSurface += surface;
        }
    });
    
    return correctSortingSurface / totalSurface;
}

/**
 * Get the list of trash types included under a "super" trash type (displayed to the user).
 * For example, when displaying to the user, we group "glass bottle" and "glass jar" under "glass bottle"
 * @param displayedError The "super" trash type displayed to the user
 * @returns A list of trash type (sorting errors) grouped under the "super" trash type
 */
export function unmergeTrashType(displayedError: SortingError): SortingError[];
export function unmergeTrashType(displayedError: TrashType): TrashType[];
export function unmergeTrashType(displayedError: SortingError | TrashType) {
    const mergedKeys = Object.keys(MERGING_WASTE_MAPPING) as TrashType[];
    const mergedTrashTypes = mergedKeys.filter(mergedTrashType => MERGING_WASTE_MAPPING[mergedTrashType] === displayedError);

    return [...mergedTrashTypes, displayedError];
}

/**
 * Get the "has..." boolean field name for a given trash type.
 * @example "bulky" --> "hasBulky"
 */
export function formatHasError(trashType: TrashType): HasErrorType {
    const capitalizedTrashType = upperFirst(trashType) as Capitalize<TrashType>;
    return `has${capitalizedTrashType}`;
}