import SectionLoader from '../_include/SectionLoader';
import { DrawerProps, Drawer as MuiDrawer, List, Box, } from '@mui/material';
import NavigationMenuItem from './NavigationMenuItem';
import { getAuth, signOut } from 'firebase/auth';
import { useAppSelector, useCanWrite, useHasPartner, useIsAdmin, usePartnerID } from 'store/hooks';
import { getDrawer, Section } from 'constants/pages';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMemo } from 'react';
import DrawerTitle from './DrawerTitle';

export const DRAWER_WIDTH = 200;

interface DrawersProps extends DrawerProps {
    onClose?: () => void;
}

function Drawer(props: DrawersProps) {
    const { onClose, ...other } = props;

    const isAdmin = useIsAdmin();
    const partnerID = usePartnerID();
    const canWrite = useCanWrite(partnerID);

    const userLoaded = useAppSelector(state => !state.user.loading && !state.user.error);

    const hasPartners = useHasPartner();

    const canAccessSettings = useAppSelector(state => Boolean(state.partners.selected.data?.canAccessSettings));

    const drawer = useMemo(() => {
        return getDrawer(isAdmin, canWrite, canAccessSettings, hasPartners);
    }, [isAdmin, canWrite, canAccessSettings, hasPartners]);

    return (
        <MuiDrawer
            variant="permanent"
            anchor="left"
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: DRAWER_WIDTH,
                    background: "none",
                    border: "none"
                },
            }}
            {...other}
        >
            {userLoaded ? (
                <List
                    disablePadding
                    sx={{
                        m: 1,
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                    }}
                >
                    <DrawerTitle />
                    <Box
                        flexGrow={1}
                        py={2}
                        borderRadius={4}
                        bgcolor='#18202c'
                        boxShadow={4}
                        position="relative"
                        overflow="hidden"
                    >
                        {drawer.map(({ label, Icon, path }) => (
                            <NavigationMenuItem
                                key={path}
                                label={label ?? path ?? ""}
                                Icon={Icon}
                                link={path}
                            />
                        ))}

                        <NavigationMenuItem
                            key="logout"
                            label="logout"
                            Icon={LogoutIcon}
                            link={Section.LOGIN}
                            onClick={() => {
                                signOut(getAuth()); // log out
                            }}
                        />

                        <Box
                            component="span"
                            position="absolute"
                            right={0}
                            fontSize={100}
                            bottom={-60}
                            color="#ffffff"
                            sx={{
                                textShadow: "4px 4px 10px rgba(0,0,0,.5)",
                                transform: "rotate(-30deg)",
                            }}
                        >
                            fi
                        </Box>
                    </Box>
                </List>
            ) : (
                <div style={{ flexGrow: 1 }}>
                    <SectionLoader />
                </div>
            )}
        </MuiDrawer>
    );
}

export default Drawer;
