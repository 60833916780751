import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { Namespace } from 'locales/translations';
import InterestsRoundedIcon from '@mui/icons-material/InterestsRounded';
import { useTranslation } from 'react-i18next';
import MapLegendMarker from './MapLegendMarker';
import { DangerLevel } from 'constants/stats';

type MapLegendDialogProps = {
    open: boolean;
    onClose: () => void;
}

function MapLegendDialog({ open, onClose, }: MapLegendDialogProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.MAPS]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogIcon
                Icon={InterestsRoundedIcon}
            />
            <DialogTitle align='center'>
                {t("legend.dialog.title", { ns: Namespace.MAPS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("legend.dialog.body", { ns: Namespace.MAPS })}
                    <List>
                        {Object.values(DangerLevel).map(level => (
                            <MapLegendMarker key={level} level={level} />
                        ))}
                    </List>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("close", { ns: Namespace.ACTIONS })}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default MapLegendDialog;