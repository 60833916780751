import { ResponsiveBar } from "@nivo/bar";
import { Box, BoxProps, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import _ from 'lodash';
import ChartTitle from "components/Statistics/ChartTitle";
import { red, orange, yellow, green, brown } from "@mui/material/colors";
import ErrorBarsLayer from "./ErrorBarsLayer";
import SectionLoader from "components/_include/SectionLoader";
import PieChartIcon from '@mui/icons-material/PieChart';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { IconButton } from '@mui/material';
import { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { WasteSlice } from "components/Statistics/WasteRepartitionPieChart";
import { PIE_CHART_THEME, useSpacing } from "constants/theme";
import { TrashType } from "constants/trash";
import TrashTypeTooltip from "components/Statistics/TrashTypeTooltip";


type MostFrequentErrorsBarChartProps = Pick<BoxProps, | "px"> & {
    height: (theme: Theme) => string;
    data: ErrorPoint[];
    pieData?: WasteSlice[];
    loading?: boolean;
    interactive?: boolean;
    icons: boolean;
}

export enum ERROR_CATEGORY {
    WORST,
    BAD,
    MEDIUM,
    GOOD,
};

export const BAR_COLORS = {
    [ERROR_CATEGORY.WORST]: red[400],
    [ERROR_CATEGORY.BAD]: orange[500],
    [ERROR_CATEGORY.MEDIUM]: yellow[400],
    [ERROR_CATEGORY.GOOD]: green[400],
};

export const TEXT_COLORS = {
    [ERROR_CATEGORY.WORST]: "#ffffff",
    [ERROR_CATEGORY.BAD]: "#ffffff",
    [ERROR_CATEGORY.MEDIUM]: brown[400],
    [ERROR_CATEGORY.GOOD]: green[600],
};

export type ErrorPoint = {
    trashType: string;
    count: number;
    color: string;
    textColor: string;
};

type ChartType = 'pie' | 'equalizer';


const MARGIN_HORIZONTAL = 2;

function MostFrequentErrorsBarChart({ data, pieData, loading, interactive, height, px, icons }: MostFrequentErrorsBarChartProps) {

    const [activeChart, setActiveChart] = useState<ChartType>('equalizer');

    const handleChartButtonClick = (chartType: ChartType) => {
        setActiveChart(chartType);
    }

    const { t } = useTranslation([Namespace.CHARTS, Namespace.GLOSSARY, Namespace.STATS, Namespace.WASTES]);

    const theme = useTheme();

    const heightPixels = Number(height(theme).slice(0, -2));
    const chartBarsHeight = useSpacing(data.length * 4);

    return (
        <Box
            justifyContent="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ChartTitle sx={{ px: px, }}>
                    {t("most_frequent_errors.title", { ns: Namespace.CHARTS })}
                    {interactive && (
                        <>
                            <br />
                            <Typography variant="caption">
                                {t("click_to_filter_map", { ns: Namespace.CHARTS })}
                            </Typography>
                        </>
                    )}
                </ChartTitle>{icons ? (
                    <Grid container direction="row" justifyContent="end" alignItems="end" item sm={4} md={2} >
                        <IconButton color={activeChart === "pie" ? "secondary" : "default"}  >
                            <PieChartIcon onClick={() => handleChartButtonClick('pie')} />
                        </IconButton>
                        <IconButton color={activeChart === "equalizer" ? "secondary" : "default"} >
                            <EqualizerIcon onClick={() => handleChartButtonClick('equalizer')} />
                        </IconButton>
                    </Grid>)
                    : null}
            </Grid>
            <Box
                height={height}
                position="relative"
                sx={{
                    ...(activeChart === "equalizer" && { overflow: "scroll" })
                }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    activeChart === "pie"
                        ? ( // pie chart
                            <ResponsivePie
                                data={pieData!}
                                theme={PIE_CHART_THEME}
                                valueFormat={(value) => `${value}%`}
                                margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                                padAngle={2}
                                innerRadius={0.4}
                                cornerRadius={4}
                                borderWidth={1}
                                borderColor={{
                                    from: "color",
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                colors={{ datum: "data.color" }}
                                arcLabelsTextColor={(d) => d.value > 5 ? "#ffffff" : "rgba(0,0,0,0.8)"}
                                arcLinkLabelsStraightLength={8}
                                arcLinkLabel={d => d.id === TrashType.OTHER ? t('other_small', { ns: Namespace.WASTES }) : `${d.label}`}
                                startAngle={-20}
                                tooltip={({ datum }) => (
                                    <TrashTypeTooltip 
                                        trashType={datum.id as TrashType} 
                                        label={datum.data.label} 
                                        value={datum.formattedValue}                                
                                        />
                                )}  
                            />
                        ) : ( // horizontal bar chart 
                            <Box
                                width="100%"
                                height={Math.max(chartBarsHeight, heightPixels)}
                                px={px}
                                position="relative"
                            >
                                <ResponsiveBar
                                    data={data}
                                    keys={['count']}
                                    layout="horizontal"
                                    indexBy="trashType"
                                    margin={{ top: 0, right: MARGIN_HORIZONTAL, bottom: 0, left: MARGIN_HORIZONTAL }}
                                    colors={({ id, data }) => data.color}
                                    // valueFormat={value => `${value} m\u00b2`}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableLabel={false}
                                    layers={[
                                        // 'bars',
                                        // custom layer for labels
                                        ({ bars }) => (
                                            <ErrorBarsLayer
                                                bars={bars}
                                                interactive={interactive}
                                                mx={MARGIN_HORIZONTAL}
                                            />
                                        )
                                    ]}
                                />
                            </Box>
                        )
                )}
            </Box>
        </Box >
    );
}

export default MostFrequentErrorsBarChart;