import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { UNAUTHORIZED_ERROR } from "constants/api_errors";
import { getAuth } from "firebase/auth";
import { showError } from "store/reducers/snacks";
import { AppDispatch } from "store/store";

export class CodeError extends Error {
    code: number;

    constructor(code: number, message: string) {
        super(message);
        this.code = code;
    }
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit): Promise<any> {
    // retrieve API token
    const user = getAuth().currentUser;
    let token = await user?.getIdToken();
    if (!token) {
        throw UNAUTHORIZED_ERROR;
    }
    else {
        let headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            ...init?.headers,
        };

        return fetch(input, {
            ...init,
            headers: headers,
        })
            .then(handleAPIResponse);
    }
}

/**
 * Format body of the response to be readable.
 * Throw an Error to be caught and handled in reducers upon API errors (e.g. 400, 404...)
 * @param res The Response of the request to the API
 */
export const handleAPIResponse = async (res: Response) => {
    try {
        if (res.headers.get("Content-Type") === "application/pdf") {
            const data = await res.blob();
            const filename = getResponseFilename(res , "");
            return { data, filename };
        }

        const body = await res.text();
        try {
            const json = JSON.parse(body);
            if (!res.ok) throw new CodeError(res.status, json); // failed request
            return json; // successful request
        }
        catch (e) { // not json
            if (!res.ok) throw new CodeError(res.status, body || res.statusText); // failed request
            return body; // successful request but not JSON was returned
        }                               
    }
    catch (e) { // failed parsing json
        const error = e as CodeError;
        console.error(error); // print the error in console
        throw error;
    }
};

/**
 * Error handler for API request, displaying an error snackbar 
 * and setting the error field of the given reducer
 * @param e The thrown error
 * @param action A displayable name for the action to identify the log
 * @param 
 */
export const handleAPIError = (e: unknown, action: string, reducerErrorAction: ActionCreatorWithPayload<string, string>) => (dispatch: AppDispatch) => {
    const error = e as Error;
    console.error("Failed", action, error);
    dispatch(reducerErrorAction(error.message));

    // show error message
    dispatch(showError(error.message));
};
/**
 * Extract the filename returned in the response's headers
 * The 'Content-Disposition' header should be like 'attachment; filename="truck_report.xlsx"'
 */

export const getResponseFilename = (res: Response, defaultName: string) => {
    const contentDisposition = res.headers.get("Content-Disposition");
    if (!contentDisposition) return defaultName;
    const filenameMatch = RegExp(/filename="(.*)"/).exec(contentDisposition);
    if (!filenameMatch) return defaultName;
    return filenameMatch[1];
};
