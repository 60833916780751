import { Box, CssBaseline } from '@mui/material';
import { useAppDispatch, useAppSelector, useCanWrite, useHasPartner, useIsAdmin, usePartnerID } from 'store/hooks';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import UserController from "controllers/user";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getRoutes } from 'constants/pages';
import { useMemo } from 'react';


type PageProps = {

}

function Pages(props: PageProps) {
    const { } = props;

    const partnerID = usePartnerID();
    const canWrite = useCanWrite(partnerID);

    const isAdmin = useIsAdmin();
    
    const hasPartners = useHasPartner();

    // check if user is logged in
    const auth = getAuth();

    const dispatch = useAppDispatch();

    onAuthStateChanged(auth, firebaseUser => {
        if (firebaseUser) { // authentication state has changed
            dispatch(UserController.setUser(firebaseUser)); // populate user to store
        }
    });

    const canAccessSettings = useAppSelector(state => Boolean(state.partners.selected.data?.canAccessSettings));

    const router = useMemo(() => {
        const routes = getRoutes(isAdmin, canWrite, canAccessSettings, hasPartners);
        return createBrowserRouter(routes);
    }, [isAdmin, canWrite, canAccessSettings, hasPartners]);

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            bgcolor: (theme) => theme.palette.background.default,
        }}>
            <CssBaseline />

            <RouterProvider router={router} />
        </Box>
    );
}

export default Pages;