import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

type AreaNameDialogProps = {
    defaultName: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (name: string) => void;
}

function AreaNameDialog({ defaultName, open, onClose, onSubmit }: AreaNameDialogProps) {

    const loading = useAppSelector(state => state.areas.selected.loading);

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.AREAS]);

    const [areaName, setAreaName] = useState(defaultName);

    useEffect(() => {
        setAreaName(defaultName);
    }, [defaultName]);

    const handleClose = () => {
        setAreaName(defaultName);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogIcon
                Icon={ZoomInMapIcon}
            />
            <DialogTitle align='center'>
                {t("name_dialog.title.new", { ns: Namespace.AREAS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("name_dialog.body", { ns: Namespace.AREAS })}
                </DialogContentText>
                <Box mt={3}>
                    <TextField
                        margin='normal'
                        value={areaName}
                        onChange={(e) => setAreaName(e.target.value)}
                        label={t("name_dialog.placeholder", { ns: Namespace.AREAS })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={handleClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="success"
                    sx={{
                    }}
                    onClick={() => onSubmit(areaName)}
                    loading={loading}
                    disabled={areaName.length === 0}
                >
                    <span>{t("save", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AreaNameDialog;