import ActionButton from "components/_include/Buttons/ActionButton";
import urls from "constants/urls";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppSelector } from "store/hooks";
import _ from "lodash";
import { useMemo, useState } from "react";
import { checkFileExists } from "helpers/urls";
import { Dialog, DialogTitle } from "@mui/material";
import DownloadReportDialog from "./DowloadReportDialog";

export default function DownloadReportButton() {

    const { t } = useTranslation([Namespace.ACTIONS]);

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <ActionButton
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={() => setDialogOpen(true)}
            >
                {t("download_report", { ns: Namespace.ACTIONS })}
            </ActionButton>

            <DownloadReportDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                />
        </>
    );
}