import ActionButton from 'components/_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import DocumentScannerIcon from '@mui/icons-material/DocumentScannerRounded';
import * as XLSX from 'xlsx';
import UploadRFIDDialog from './UploadRFIDDialog/UploadRFIDDialog';
import { useAppDispatch } from 'store/hooks';
import { CollectionRFIDActions } from 'store/reducers/collections/rfid';

export default function UploadRFIDExcelButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const dispatch = useAppDispatch();

    const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (!selectedFiles || selectedFiles.length < 1) return; // user didn't select any file

        const file = selectedFiles[0];

        // read files content
        const reader = new FileReader();
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target?.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            
            /* Convert Excel sheet to JSON */
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            
            /* Update state */
            dispatch(CollectionRFIDActions.setData(data));
        };
        reader.readAsArrayBuffer(file);

        e.target.value = ''; // allow several uploads
    }

    return (
        <>
            <ActionButton
                color="secondary"
                component="label" // use label so that click forwards to hidden file input
                startIcon={<DocumentScannerIcon />}
            >
                {t("upload rfid", { ns: Namespace.ACTIONS })}
                <input type="file" style={{ display: "none" }} onChange={handleFileSelected} accept='.csv, .xlsx' />
            </ActionButton>

            <UploadRFIDDialog />
        </>
    )
}