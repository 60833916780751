import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import PartnerSelect from 'components/_include/Filters/PartnerSelect';
import TruckSelect from 'components/_include/Filters/TruckSelect';

type PartnerAndTruckSelectProps = {
    parentLoading: boolean;
}

export default function PartnerAndTruckSelect({ parentLoading, }: PartnerAndTruckSelectProps) {

    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamPartnerID = searchParams.get("partnerID");

    return (
        <Box display="flex" flexDirection="column">
            <PartnerSelect parentLoading={parentLoading} canSelectAll />

            {searchParamPartnerID && (
                <Box mt={1}>
                    <TruckSelect parentLoading={parentLoading} />
                </Box>
            )}
        </Box>
    );
}