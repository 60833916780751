import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import RoutesController from "controllers/routes";
import { Namespace } from "locales/translations";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { selectAllRoutes } from "store/reducers/routes/list";
import AddIcon from "@mui/icons-material/AddRounded";
import Route from "models/Route";
import { selectCollectionById } from "store/reducers/collections/list";
import SectionLoader from "components/_include/SectionLoader";

type MenuProps = {
    collectionID: string;
    selectedRouteID: string;
    anchorEl?: HTMLElement;
    onClose: () => void;
    onRouteSelected: (route: Route) => void;
    onAddRouteClicked: () => void;
}

export default function CollectionScheduleRouteMenu({ collectionID, selectedRouteID, anchorEl, onClose, onRouteSelected, onAddRouteClicked, }: MenuProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.GLOSSARY]);

    const partnerID = usePartnerID();

    const collectionLoading = useAppSelector(state => selectCollectionById(state, collectionID)?.loading);

    const routes = useAppSelector(selectAllRoutes);

    const loading = collectionLoading || routes.length === 0;

    const dispatch = useAppDispatch();

    /** Load list of routes if not loaded yet */
    useEffect(() => {
        if (partnerID && routes.length === 0) { // routes not loaded yet
            dispatch(RoutesController.list(partnerID, []));
        }
    }, [routes, partnerID]);

    const getMenuItems = useCallback(() => {
        const items = [
            <MenuItem key="header" disabled>
                {t("route", { ns: Namespace.GLOSSARY })}
            </MenuItem>,
            ...routes.map(route => (
                <MenuItem
                    key={route.ID}
                    selected={route.ID === selectedRouteID}
                    onClick={() => onRouteSelected(route)}
                >
                    <ListItemText>
                        {route.label}
                    </ListItemText>
                </MenuItem>
            )),
            <Divider key="action-divider" />,
            <MenuItem 
                key="add-route" 
                onClick={onAddRouteClicked}
                >
                <ListItemIcon>
                    <AddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    {t("add route", { ns: Namespace.ACTIONS })}
                </ListItemText>
            </MenuItem>,
        ];
        
        if (loading) items.push(<SectionLoader key="loader" />);

        return items;
    }, [loading, routes]);

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={onClose}
        >
            {getMenuItems()}
        </Menu >
    )
}