import GoogleMapReact, { Coords } from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getBoundsZoomLevel, getMapOptions, getPointsBoundsAndCenter, mvcArrayToCoords } from "helpers/geo";
import { useTheme } from "@mui/material";
import { CenterAndZoom } from "constants/types";
import { SelectedAreaActions } from "store/reducers/areas/selected";
import _ from "lodash";
import { SAVE_AREA_BUTTON_ID } from "../AreasControls/AreasControls";
import { selectAllAreas, selectAreasIds } from "store/reducers/areas/list";
import { AreasMapActions } from "store/reducers/areas/map";
import { OsmAreasActions } from "store/reducers/areas/osm";

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type GoogleApi = { map: google.maps.Map, maps: typeof google.maps };

type AreasMapProps = {
    
}

export default function AreasMap({ }: AreasMapProps) {

    const areasIDs = useAppSelector(selectAreasIds);

    const defaultCenter = useAppSelector(state => state.areas.map.defaultCenter);
    const defaultBounds = useAppSelector(state => state.areas.map.defaultBounds);
    const defaultZoom = useAppSelector(state => state.areas.map.defaultZoom);

    const selectedOsmArea = useAppSelector(state => state.areas.osm.selected);

    const dispatch = useAppDispatch();

    // handle map
    const [{ zoom, center }, setMapState] = useState<CenterAndZoom>({
        zoom: defaultZoom,
        center: defaultCenter,
    });

    const selectedArea = useAppSelector(state => state.areas.selected.data);
    const editing = useAppSelector(state => state.areas.selected.editing);
    const newPath = useAppSelector(state => state.areas.selected.newPath);

    const mapRef = useRef<GoogleMapReact>(null);

    useEffect(() => {
        setMapState({ center, zoom: defaultZoom });
    }, [defaultZoom]);

    useEffect(() => {
        if (defaultBounds) {
            const mapDims = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            let newZoom = getBoundsZoomLevel(defaultBounds, mapDims);

            setMapState({
                zoom: newZoom,
                center: defaultCenter,
            });
        }
    }, [defaultBounds]);

    useEffect(() => {
        // reset selected area when areas change (change of partner)
        dispatch(SelectedAreaActions.selectArea({ area: null }));
    }, [areasIDs]);

    const theme = useTheme();

    const [googleApi, _setGoogleApi] = useState<GoogleApi>();
    // use ref to access latest state from listeners
    const googleApiRef = useRef<GoogleApi>();
    const setGoogleApi = (api: GoogleApi) => {
        googleApiRef.current = api;
        _setGoogleApi(api);
    }

    const [lassoPolyline, setLassoPolyline] = useState<google.maps.Polyline>();
    
    const [editingPolyline, setEditingPolyline] = useState(false);
    const [polylineEvents, setPolylineEvents] = useState<google.maps.MapsEventListener[]>([]);

    const noSelectedArea = !selectedArea;

    useEffect(() => {
        if (editing && noSelectedArea) { // user has just clicked on "New area"
            setUpPolyline();
        }
    }, [editing, noSelectedArea]);

    useEffect(() => {
        if (!editing && lassoPolyline) { // user has just clicked on "Cancel"
            removePolyline(true);
        }
    }, [editing, lassoPolyline]);

    useEffect(() => {
        const linePath = lassoPolyline?.getPath();
        if (newPath && linePath && !_.isEqual(newPath, mvcArrayToCoords(linePath))) {
            // set points one by one to keep events on polyline path
            linePath.clear();
            for (let point of newPath) {
                linePath.push(new google.maps.LatLng(point));
            }
        }
    }, [newPath, lassoPolyline]);

    const [areaPolygon, setAreaPolygon] = useState<google.maps.Polygon>();
    const [polygonEvents, setPolygonEvents] = useState<google.maps.MapsEventListener[]>([]);

    /**
     * Draw a new Polygon on the map. 
     * Erase the previously drawn polygon or editing polyline.
     * @param path List of Coords representing the Polygon to draw
     * @param editable If set to true, allow edition of the polygon
     */
    const drawPolygon = (path: Coords[], editable: boolean) => {
        if (!googleApi) return;

        // setLassoPolyline(polyline);
        const polygon = new googleApi.maps.Polygon({
            paths: path,
            strokeOpacity: 0.7,
            strokeColor: theme.palette.secondary.main,
            fillOpacity: 0.2,
            fillColor: theme.palette.secondary.main,
            editable: editable,
        });

        removePolyline(!editable);

        polygon.setMap(googleApi.map);

        const handlePathChanged = (e: google.maps.MapMouseEvent) => {
            dispatch(SelectedAreaActions.setNewPath(mvcArrayToCoords(polygon.getPath())));
        }

        setPolygonEvents([
            polygon.getPath().addListener("set_at", handlePathChanged),
            polygon.getPath().addListener("insert_at", handlePathChanged),
            polygon.getPath().addListener("remove_at", handlePathChanged),
        ]);
        
        setAreaPolygon(polygon);
    }

    const removePolygon = () => {
        for (let event of polygonEvents) { // remove polygon events listeners
            event.remove();
        }

        if (areaPolygon) { // erase previous polygon
            areaPolygon.setMap(null);
        }
    }

    /**
     * Erase the polyline currently drawn
     * @param stopEditing If set to true, stop "editing" a new area
     */
    const removePolyline = (stopEditing: boolean) => {
        for (let event of polylineEvents) { // remove polyline events listeners
            event.remove();
        }

        lassoPolyline?.setMap(null);
        setLassoPolyline(undefined);

        if (selectedOsmArea) dispatch(OsmAreasActions.setSelected(null)); // clear selected OSM area

        if (stopEditing) dispatch(SelectedAreaActions.setNewPath(null));
    }

    const setUpPolyline = () => {
        if (!googleApi) return;

        // prepare lasso polyline
        const polyline: google.maps.Polyline = new googleApi.maps.Polyline({
            strokeColor: theme.palette.secondary.main,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: true,
        });
        polyline.setMap(googleApi.map);

        // handle drag, insert and click interactions
        const handlePathChanged = (e: google.maps.MapMouseEvent) => {
            dispatch(SelectedAreaActions.setNewPath(mvcArrayToCoords(polyline.getPath())));
        }

        setPolylineEvents([
            polyline.addListener("mousedown", (e: google.maps.MapMouseEvent) => {
                setEditingPolyline(true);
            }),
            polyline.getPath().addListener("set_at", handlePathChanged), // drag node
            polyline.getPath().addListener("insert_at", handlePathChanged), // drag middle of segment
            polyline.addListener("click", handlePolylineClose), // click on polyline to close 
        ]);

        setLassoPolyline(polyline);
    }

    const handlePolylineClose = (e: google.maps.PolyMouseEvent) => {
        if (e.latLng) {
            addPointToPolyline(e.latLng);

            if (e.vertex === 0) { // click on the first point
                // trigger click on "Save button"
                document.getElementById(SAVE_AREA_BUTTON_ID)?.click();
            }
        }
    }

    /**
     * Existing area just selected by user
     */
    useEffect(() => {
        // erase previous polygon
        removePolygon();

        // different area selected
        if (selectedArea && googleApi) {

            // draw selected polygon
            drawPolygon(selectedArea.path, false);
        }
    }, [selectedArea?.ID, googleApi]);

    /**
     * OpenStreetMap result just selected by user
     */
    useEffect(() => {
        // erase previous polygon
        removePolygon();
        
        if (selectedOsmArea) {
            const areaPath = selectedOsmArea.path.map(point => ({ lat: point[1], lng: point[0] })); // convert from [lng, lat] to { lat, lng }

            // draw selected OSM polygon
            // drawPolygon(areaPath, true);
            dispatch(SelectedAreaActions.setNewPath(areaPath));
            
            dispatch(AreasMapActions.setMapBounds(getPointsBoundsAndCenter(areaPath)));
        }
    }, [selectedOsmArea]);

    const addPointToPolyline = (point: google.maps.LatLng) => {
        if (editingPolyline) { // editing existing point -> don't add new one
            setEditingPolyline(false);
        }
        else { // add new point to path
            dispatch(SelectedAreaActions.addPointToNewPath({ lat: point.lat(), lng: point.lng() }));
        }
    }

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={defaultCenter}
            zoom={zoom}
            options={getMapOptions}
            onChange={({ zoom, center }) => {
                setMapState({
                    zoom,
                    center,
                });
            }}
            onGoogleApiLoaded={({ map, maps }: { map: google.maps.Map, maps: typeof google.maps }) => {
                setGoogleApi({ map, maps });
            }}
            onClick={(e) => {
                if (!editing) return;

                const { lat, lng } = e;
                addPointToPolyline(new google.maps.LatLng(lat, lng))
            }}
        >
        </GoogleMapReact>
    );
}