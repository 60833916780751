import { Box } from "@mui/material";
import { VALIDATION_WASTES_COLORS, WASTES_COLORS } from "constants/trash";
import { TrashType } from "constants/trash";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";

type SquareProps = {
    trashType: TrashType;
    validation?: boolean;
}

export default function TrashColorSquare({ trashType, validation }: SquareProps) {
    const { t } = useTranslation([Namespace.WASTES]);

    return (
        <Box
            title={t(trashType, { ns: Namespace.WASTES })}
            component="span"
            display="inline-block"
            width={(theme) => theme.spacing(2)}
            height={(theme) => theme.spacing(2)}
            borderRadius={1}
            border={1}
            borderColor="#ffffff"
            ml={.5}
            sx={{
                bgcolor: validation ? VALIDATION_WASTES_COLORS[trashType] : WASTES_COLORS[trashType],
            }}
        />
    )
}