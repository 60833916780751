import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Collaborator from "models/Collaborator";

const initialState: LoadableContext = {
    loading: false,
    error: null,
};

const collaboratorsAdapter = createEntityAdapter<Collaborator>({
    selectId: Collaborator => Collaborator.ID,
});

export const CollaboratorsSlice = createSlice({
    name: 'collaborators',
    initialState: collaboratorsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: collaborators }: PayloadAction<Collaborator[]>) => {
            state.loading = false;
            collaboratorsAdapter.setAll(state, collaborators);
        },
        addOne: (state, { payload: collaborator }: PayloadAction<Collaborator>) => {
            state.loading = false;
            collaboratorsAdapter.addOne(state, collaborator);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<Collaborator>}>) => {
            collaboratorsAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        removeOne: (state, { payload: collaboratorID }: PayloadAction<string>) => {
            state.loading = false;
            collaboratorsAdapter.removeOne(state, collaboratorID);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CollaboratorsActions = CollaboratorsSlice.actions;

export const {
    selectAll: selectAllCollaborators,
    selectById: selectCollaboratorById,
    selectIds: selectCollaboratorsIds
} = collaboratorsAdapter.getSelectors((state: RootState) => state.collaborators.list)

const CollaboratorsReducer = CollaboratorsSlice.reducer;

export default CollaboratorsReducer;