import { Grid } from "@mui/material";
import SaveResultsButton from "./SaveResultsButton";
import ToggleDisplayButton from "./ToggleDisplayButton";
import GoToPreviousBatch from "./GoToPreviousBatch";
import GoToNextBatch from "./GoToNextBatch";
import ViewAIResultsButton from "./ViewAIResultsButton";

type ActionsProps = {
    canEditResults?: boolean;
}

export default function WastesFormActions({ canEditResults }: ActionsProps) {

    return canEditResults ? ( // display buttons to save batch
        <Grid
            container
            rowSpacing={3}
            alignItems="center"
            justifyContent="center"
            pt={1}
            pb={2}
        >
            <GoToPreviousBatch />

            <SaveResultsButton
                goToNext={false}
            />

            <ToggleDisplayButton />

            {/* <SaveResultsButton
                goToNext={true}
            /> */}

            <GoToNextBatch />
        </Grid>
    ) : ( // cannot update results
        <Grid
            container
            rowSpacing={3}
            alignItems="center"
            justifyContent="center"
        >
            <GoToPreviousBatch />

            <Grid item xs={8} />

            <GoToNextBatch />
        </Grid>
    )
}