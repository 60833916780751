import { blue, green, purple, red, yellow } from "@mui/material/colors";
import { CenterAndZoom } from "constants/types";
import { GeoPoint } from "firebase/firestore";

export enum PartnerStatus {
    ONBOARDING = "onboarding",
    ACTIVE = "active",
    INACTIVE = "inactive",
    ARCHIVED = "archived",
    DEMO = "demo",
}

export const PARTNER_STATUSES_COLORS = {
    [PartnerStatus.ONBOARDING]: purple[400],
    [PartnerStatus.ACTIVE]: green[400],
    [PartnerStatus.INACTIVE]: yellow[400],
    [PartnerStatus.ARCHIVED]: red[400],
    [PartnerStatus.DEMO]: blue[400],
}

export type PartnerDbData = {
    ID: string;
    name: string;
    status: PartnerStatus;
    defaultMapViewport?: {
        center: GeoPoint;
        zoom: number;
    };
    logoURL: string;

    /** If set to true, let partner edit some of their settings (collections schedules...). */
    canAccessSettings?: boolean;
}

export type PartnerData = Omit<PartnerDbData, "defaultMapViewport"> & {
    ID: string;
    defaultMapViewport?: CenterAndZoom;
}

export type NewPartnerData = Pick<PartnerDbData, "name" | "status" | "logoURL">;

export enum PartnerType {
    BAILLEUR = "bailleur", 
    COLLECTIVITY = "collectivity", 
    COLLECTION_OPERATOR = "collection_operator", 
    COMPANY = "company", 
    OTHER = "other",
}