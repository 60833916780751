import { Box } from '@mui/material';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type SaveDefaultMapBoundsButtonProps = {
    visible: boolean;
    onClick: () => void;
}

function SaveDefaultMapBoundsButton({ visible, onClick }: SaveDefaultMapBoundsButtonProps) {
    const partnerLoading = useAppSelector(state => state.partners.selected.loading);

    const { t } = useTranslation([Namespace.ACTIONS]);

    return (
        <Box
            {...(!visible && { display: "none" })}
            position="absolute"
            right={theme => theme.spacing(2)}
            top={theme => theme.spacing(2)}
        >
            <ActionButton
                onClick={onClick}
                loading={partnerLoading}
                color="success"
            >
                {t("save", { ns: Namespace.ACTIONS })}
            </ActionButton>
        </Box>
    );
}

export default SaveDefaultMapBoundsButton;