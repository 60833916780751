import moment from "moment";
import { useEffect, useState } from "react";
import { DEFAULT_TIMEZONE, Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from 'react-rainbow-components';
import { useAppSelector } from "store/hooks";
import { Value } from "react-rainbow-components/components/DatePicker";
import { useTheme } from "@mui/material";
import { selectAllCollectionsSchedules } from "store/reducers/collections_schedules/list";
import { adjustToDefaultTimezone } from "helpers/dates";
import { Weekday } from "models/CollectionSchedule";

type DatesRangeSelectProps = {
    parentLoading: boolean;
}

function DatesRangeSelect(props: DatesRangeSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;
    
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS,]);
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const defaultValue = startDate
        ? (
            endDate ? [moment(startDate).toDate(), moment(endDate).toDate()] : [moment(startDate).toDate()]
        ) : [];

    const [rangeValue, setRangeValue] = useState<Date[]>(defaultValue);

    const selectedTruck = useAppSelector(state => state.trucks.selected.data);
    
    const firstCollection: Date | undefined = selectedTruck?.firstCollection ? moment(selectedTruck.firstCollection).toDate() : undefined;
    const lastCollection: Date | undefined = selectedTruck?.lastCollection ? moment(selectedTruck.lastCollection).toDate() : undefined;

    useEffect(() => {
        if (firstCollection && lastCollection && (firstCollection > rangeValue[0] || lastCollection < rangeValue[1])) { 
            // set dates to first and last collection, or 3 months to last collection
            let dates: { start: Date, end: Date } = { start: rangeValue[0], end: rangeValue[1] };
            dates.end = lastCollection;            
            dates.start = moment.max(moment(firstCollection), moment(lastCollection).subtract(3, "months")).toDate();
            handleChange([dates.start, dates.end]);
        }
    }, [selectedTruck?.firstCollection, selectedTruck?.lastCollection]);

    const theme = useTheme();

    // handle automatic changes in the search params
    useEffect(() => {
        const start = adjustToDefaultTimezone(moment(startDate));
        const end = adjustToDefaultTimezone(moment(endDate));
        if (!start.isSame(moment(rangeValue[0])) || !end.isSame(moment(rangeValue[1]))) {
            const value = startDate
            ? (
                endDate ? [start.toDate(), end.toDate()] : [start.toDate()]
            ) : [];
            setRangeValue(value);
        }
    }, [startDate, endDate]);

    const handleChange = (value: Date | Date[]) => {
        if (Array.isArray(value)) {
            if (!rangeValue[1] && value.length == 1 && moment(value[0]).unix() == moment(rangeValue[0])?.unix()) {
                // same start and end day
                setRangeValue([value[0], value[0]]);
                const adjustedValue = moment(value[0]).tz(moment.tz.guess()); // need to adjust because default timezone may differ from user's 
                searchParams.set("startDate", adjustedValue.format("YYYY-MM-DD"));
                searchParams.set("endDate", adjustedValue.format("YYYY-MM-DD"));
                setSearchParams(searchParams);
                document.getElementById("modal-close-button")?.click(); // close datepicker
            }
            else if (value.length == 1) { // start date selected
                setRangeValue(value);
            }
            else if (value.length == 2) { // end date selected
                setRangeValue(value);
                searchParams.set("startDate", moment(value[0]).tz(moment.tz.guess()).format("YYYY-MM-DD"));
                searchParams.set("endDate", moment(value[1]).tz(moment.tz.guess()).format("YYYY-MM-DD"));
                setSearchParams(searchParams);
            }
        }
    }

    const handleClose = (event: Value) => {
        if (rangeValue.length < 2) { // not both start and end dates selected
            setRangeValue(defaultValue);
        }
    }


    const collectionsSchedules = useAppSelector(state => selectAllCollectionsSchedules(state));

    useEffect(() => {
        // mark sortable days on calendar
        let truckSchedules = [...collectionsSchedules];
        if (selectedTruck) truckSchedules = truckSchedules.filter(schedule => schedule.truck.ID === selectedTruck.ID);

        const STYLE_BLOCK_ID = "sortableCollectionDaysCalendarStyling";
        let styleBlock = document.getElementById(STYLE_BLOCK_ID);
        if (!styleBlock) {
            document.head.insertAdjacentHTML("beforeend", `<style id="${STYLE_BLOCK_ID}"></style>`);
            styleBlock = document.getElementById(STYLE_BLOCK_ID);
        }
        let sortableCollectionsWeekdays: Weekday[] = [];
        for (let schedule of truckSchedules) {
            for (let weekday of Object.keys(schedule.calendar)) {
                if (!sortableCollectionsWeekdays.includes(weekday as Weekday)) {
                    sortableCollectionsWeekdays.push(weekday as Weekday);
                }
            }
        }
        let sortableCollectionsDaysStyling = "";
        for (let weekday of sortableCollectionsWeekdays) {
            sortableCollectionsDaysStyling += `tbody tr td:nth-child(${(Number(weekday) + 1) % 7}) button {
                border: 1px solid ${theme.palette.success.main};
            }
            `;
            styleBlock!.innerHTML = sortableCollectionsDaysStyling;
        }
    }, [selectedTruck, collectionsSchedules]);

    return (
        <DatePicker
            onFocus={handleClose}
            placeholder={t("select_dates.range", { ns: Namespace.ACTIONS })}
            selectionType="range"
            formatStyle="medium"
            variant="double"
            locale="fr"
            value={rangeValue}
            onChange={handleChange}
            className="datepicker-range"
            style={{
                pointerEvents: "all",
                minWidth: 208,
            }}
            disabled={loading}
            minDate={firstCollection}
            maxDate={lastCollection}
            icon={<></>} // no icon
        />
    );
}

export default DatesRangeSelect;