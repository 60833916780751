import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CollaboratorsController from "controllers/collaborators";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { SelectedCollaboratorActions } from "store/reducers/collaborators/selected";

export default function RemoveCollaboratorDialog() {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DIALOGS,]);

    const partnerID = usePartnerID();

    const collaborator = useAppSelector(state => state.collaborators.selected.data);
    const loading = useAppSelector(state => state.collaborators.selected.loading);

    const dispatch = useAppDispatch();

    const closeDialog = () => dispatch(SelectedCollaboratorActions.setSelected(null));

    /** Remove a collaborator from this partner. */
    const removeCollaborator = () => {
        if (!partnerID) return;

        if (collaborator) dispatch(CollaboratorsController.removeFromPartner(partnerID, collaborator));
    };

    return (
        <Dialog 
            open={Boolean(collaborator)}
            onClose={closeDialog}
            >
            <DialogTitle>
                {t("remove_collaborator.title", { ns: Namespace.DIALOGS, name: `${collaborator?.firstName} ${collaborator?.lastName}` })}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {t("remove_collaborator.body", { ns: Namespace.DIALOGS })}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    sx={{
                        color: theme => theme.palette.grey[700],
                    }}
                    onClick={closeDialog}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>

                <LoadingButton
                    loading={loading}
                    color="error"
                    onClick={removeCollaborator}
                >
                    {t("remove", { ns: Namespace.ACTIONS })}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}