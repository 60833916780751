import { API_TIMESTAMP_FORMAT } from 'constants/dates';
import urls from 'constants/urls';
import { getAuth } from 'firebase/auth';
import { downloadDataAsFile } from 'helpers/urls';
import { Namespace } from 'locales/translations';
import moment from 'moment';
import { stringifyUrl } from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, usePartnerID } from 'store/hooks';
import ReportFormatButton from './ReportFormatButton';
import { getResponseFilename } from "controllers/actions";
import { DownloadReportDialogContentProps } from './DownloadReportDialogContent';

function DownloadPDFButton({ parentPage }: DownloadReportDialogContentProps) {
    const { t } = useTranslation([Namespace.DIALOGS]);
    
    const partnerID = usePartnerID();
    const collection = useAppSelector(state => state.collections.selected.data);
    const truck = useAppSelector(state => state.trucks.selected.data);

    const [searchParams] = useSearchParams();
    const startDate = moment(searchParams.get("startDate")).startOf("day");
    const endDate = moment(searchParams.get("endDate")).endOf("day");
    const areaID = searchParams.get("areaID");

    const [buttonLoading, setButtonLoading] = useState(false);

    const onClick = async () => {
        setButtonLoading(true);

        // refresh Firebase token if needed
        const user = getAuth().currentUser;
        let token = await user?.getIdToken();

        // format query
        const url = parentPage === "stats" ? stringifyUrl({
            url: `${urls.API}/partner/${partnerID}/truck/${truck?.ID}/pdf-report`,
            query: {
                start: startDate.format(API_TIMESTAMP_FORMAT),
                end: endDate.format(API_TIMESTAMP_FORMAT),
                ...(areaID && { areaID }),
            },
        }) : stringifyUrl({
            url: `${urls.REPORTS_API}/collection/pdf`,
            query: {
                partnerID,
                truckID: collection?.truckID,
                collectionID: collection?.ID,
            },
        })

        // query report PDF file
        const res = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/pdf',
            }
        })

        // save response data to PDF file
        const data = await res.blob();
        const defaultFilename = `rapport_${parentPage === "stats" ? truck?.identificationNumber : collection?.schedule.route.label}.pdf`
        const filename = getResponseFilename(res, defaultFilename);
        downloadDataAsFile(data, filename);

        setButtonLoading(false);
    };

    return (
        <ReportFormatButton
            imgSource={`${urls.ICONS_FOLDER}/pdf_icon.png`}
            label={t("download_report.pdf", { ns: Namespace.DIALOGS, context: parentPage })}
            onClick={onClick}
            loading={buttonLoading}
            />
    );
}

export default DownloadPDFButton;