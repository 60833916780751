import { DbCollection, ModelData } from "constants/db";
import { DbOrder, FilterValue, QueryFilter } from "constants/types";
import { Query, DocumentData, query, where, limit, orderBy, getDocs, getFirestore, collection, collectionGroup, QueryDocumentSnapshot, DocumentSnapshot, doc, getDoc, addDoc, DocumentReference, deleteDoc, FieldPath, startAfter, Timestamp } from "firebase/firestore";
import _ from "lodash";

export function formatQuery(collectionRef: Query<DocumentData>, filters?: QueryFilter<any>[], order?: DbOrder<any>, limitCount?: number, startAfterValue?: string | number | Timestamp) {
    let q = query(collectionRef);

    const inequalityFilters = filters?.filter(f => f.opStr !== "==");
    let applyFiltersLater = false;
    if (inequalityFilters && inequalityFilters.length > 1) {
        let uniqueFieldPath: (string | FieldPath)[] = [];
        for (const filter of inequalityFilters) {
            const fieldPath = filter.fieldPath as string | FieldPath;
            if (!uniqueFieldPath.includes(fieldPath)) {
                uniqueFieldPath.push(fieldPath);
                if (uniqueFieldPath.length > 1) { // inequality filter on more that one field
                    applyFiltersLater = true;
                    break;
                }
            }
        }
    }
    if (filters) {
        filters.forEach(({ fieldPath, opStr, value }) => {
            if (opStr === "==" || !applyFiltersLater) { // more than 1 inequality filters: cannot be applied to Firestore
                q = query(q, where(fieldPath as string | FieldPath, opStr, value));
            }
        });
    }

    if (order) {
        const fieldPath = order.fieldPath as string | FieldPath;
        q = query(q, orderBy(fieldPath, order.directionStr));
    }

    if (limitCount) {
        q = query(q, limit(limitCount));
    }

    if (startAfterValue) {
        q = query(q, startAfter(startAfterValue));
    }

    return {
        query: q,
        inequalityFilters: applyFiltersLater ? inequalityFilters : undefined,
    };
}

export function applyQueryFilterToDoc(filter: QueryFilter<any>, doc: QueryDocumentSnapshot) {
    const data = doc.data();
    const fieldValue = data[filter.fieldPath.toString()] as FilterValue;
    switch (filter.opStr) {
        case "<": return fieldValue < filter.value;
        case "<=": return fieldValue <= filter.value;
        case ">": return fieldValue > filter.value;
        case ">=": return fieldValue >= filter.value;

        case "in":
            if (!Array.isArray(filter.value)) return false;
            const filterValue = filter.value.map(item => item.toString());
            return filterValue.includes(fieldValue.toString());

        case "array-contains-any":
            // eg: doc field [{id: 1}, {id: 3}] contains filter [{id: 1}, {id: 2}]
            if (!Array.isArray(filter.value) || !Array.isArray(fieldValue)) return false;
            return filter.value.some(
                (filterItem: any) => fieldValue.some(
                    (fieldItem: any) => _.isEqual(filterItem, fieldItem)
                )
            );
    }
    return true;
}

export function applyQueryFilter(filter: QueryFilter<any>, docs: QueryDocumentSnapshot[]) {
    return docs.filter(doc => applyQueryFilterToDoc(filter, doc));
}

export const getCollectionRef = (path: string[]) => {
    const db = getFirestore();
    return collection(db, path.join("/"));
}

export const getCollectionGroupRef = (col: DbCollection,) => {
    const db = getFirestore();
    return collectionGroup(db, col);
}

export const getDocumentReference = (id: string, collection: DbCollection, path?: string,) => {
    const db = getFirestore();
    if (path) return doc(db, path, collection, id);
    return doc(db, collection, id);
}

/**
 * List all the documents in a given Firestore collection
 * @param pathSegments List of the segments of the path to the collection, alternating between collection name and document ID (e.g `["partners", "partner123"]`)
 * @param filters List of filters to apply on the Firestore query
 * @param order Order to apply to the Firestore query
 * @param limitCount Max number of results to return
 * @param isCollectionGroup Set to true to perform the request on a Collection Group instead of a specific collection
 * @returns A list of Firestore documents corresponding to the query results
 */
export async function listDocs(pathSegments: string[], filters?: QueryFilter<any>[], order?: DbOrder<any>, limitCount?: number, isCollectionGroup?: boolean): Promise<QueryDocumentSnapshot<DocumentData>[]> {
    const collectionRef = isCollectionGroup ? getCollectionGroupRef(pathSegments[0] as DbCollection) : getCollectionRef(pathSegments);

    const { query, inequalityFilters } = formatQuery(collectionRef, filters, order, limitCount);

    const snapshot = await getDocs(query);

    // apply inequality filters if more than 1 was specified
    let documents = snapshot.docs;
    inequalityFilters?.forEach(filter => {
        documents = applyQueryFilter(filter, documents);
    });

    return documents;
}

export async function retrieveDocument(collection: DbCollection, id: string, parentPath?: string): Promise<ModelData | null> {
    const docRef = getDocumentReference(id, collection, parentPath);
    const modelDoc = await getDoc(docRef);
    const data = modelDoc.data();
    if (!data) return null;
    return {
        ...data,
        ID: modelDoc.id,
    };
}

export async function createDocument(collectionPath: string[], data: DocumentData): Promise<ModelData> {
    const dbDoc = await addDoc(getCollectionRef(collectionPath), data);

    return {
        ...data,
        ID: dbDoc.id,
        // ref: dbDoc,
    };
}

export async function deleteDocument(ref: DocumentReference) {
    return await deleteDoc(ref);
}
