import { Box, TextField, Typography } from '@mui/material';
import UserController from '../../controllers/user';
import ActionButton from '../_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import { isValidEmail } from 'helpers/strings';
import { TextInput } from 'helpers/forms';

type LoginPageProps = {

}

type InputTypes = {
    email: TextInput;
}

function LoginPage(props: LoginPageProps) {

    const userLoading = useAppSelector(state => state.user.loading);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS, Namespace.SNACKS, Namespace.TITLES,]);

    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');

    const [inputs, setInputs] = useState<InputTypes>({
        email: {
            value: emailParam ?? "",
            error: null,
        },
    });

    const { value: email, error: emailError } = inputs.email;

    const handleEmailChanged = (value: string) => {
        setInputs({
            ...inputs,
            email: {
                value,
                error: null,
            }
        });
    }

    const handleReceiveLinkPressed = () => {
        if (isValidEmail(email)) {
            dispatch(UserController.requestMagicLink(email));
        }
        else { // invalid email
            setInputs({
                ...inputs,
                email: {
                    ...inputs.email,
                    error: t("sign_in.invalid_email", { ns: Namespace.FORMS }),
                },
            });
        }
    }

    /** Login if coming from a Firebase auth magic link */
    useEffect(() => {
        const link = window.location.href;

        // log user in with Firebase auth
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, link)) {
            // check if email is set in URL params
            if (!emailParam) {
                alert("Invalid link");
                return;
            }

            dispatch(UserController.signInWithMagicLink(emailParam, link));
        }

    }, [emailParam]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
        >
            <Typography variant="h1" sx={{
                mb: 5
            }}>
                {t("dashboard", { ns: Namespace.TITLES })}
            </Typography>
            <form
                autoComplete="on"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    flexGrow: 1,
                    maxHeight: 400,
                }}
            >
                <Box>
                    <TextField
                        label={t("sign_in.email", { ns: Namespace.FORMS })}
                        value={email}
                        margin="normal"
                        variant="outlined"
                        type="email"
                        InputProps={{
                            onChange: (event) => handleEmailChanged(event.target.value),
                        }}
                        disabled={userLoading}
                        error={emailError !== null}
                        helperText={emailError}
                        sx={{
                            maxWidth: "sm",
                            mb: 4,
                        }}
                    />

                    <Box
                        textAlign="center"
                    >
                        <ActionButton
                            color="primary"
                            disabled={!email}
                            loading={userLoading}
                            onClick={handleReceiveLinkPressed}
                            type="submit"
                        >
                            {t("receive signin link", { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </Box>
                </Box>

                {/* 
                //TODO: implement that
                <Box>
                    <Typography>
                        {t("sign_up.no_account", { ns: Namespaces.forms })}
                        <Link
                            to='/signup'
                        >
                            <Button>
                                {t("sign_up.get_in_touch", { ns: Namespaces.forms })}
                            </Button>
                        </Link>
                    </Typography>
                </Box> */}
            </form>
        </Box>
    )
}

export default LoginPage;