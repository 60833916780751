import { Stack, Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { VALIDATION_SHORTCUTS_LIST } from "store/reducers/preferences/validation_shortcuts";
import ValidationShortcutItem from "./ValidationShortcutItem";

export default function ValidationShortcutsList() {

    const { t } = useTranslation([Namespace.PREFERENCES]);

    return (
        <Stack direction="column" gap={3}>
            {Object.keys(VALIDATION_SHORTCUTS_LIST).map(section => (
                <Stack
                    key={section}
                    direction="column" 
                    gap={2}
                >
                    <Typography variant="h5">
                        {t(section, { ns: Namespace.PREFERENCES })}
                    </Typography>

                    {VALIDATION_SHORTCUTS_LIST[section as keyof typeof VALIDATION_SHORTCUTS_LIST].map(shortcut => (
                        <ValidationShortcutItem
                            key={shortcut}
                            shortcut={shortcut}
                        />
                    ))}
                </Stack>
            ))}
        </Stack>
    )
}