import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { MissionsAddressWithBatchCount } from 'constants/types';
import { formatAddress } from 'helpers/geo';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SelectedMissionActions } from 'store/reducers/missions/selected';

type SelectedAddressListItemProps = {
    address: MissionsAddressWithBatchCount;
};

const SelectedAddressListItem: React.FC<SelectedAddressListItemProps> = ({ address }) => {
    const dispatch = useAppDispatch();
    const selectedAddresses = useAppSelector(state => state.mission.selected.selectedMission?.addresses);

    const handleCheckboxChange = useCallback(() => {
        dispatch(SelectedMissionActions.updateAddresses(address));
    }, [dispatch, address]);

    return (
        <ListItem sx={{ padding: 0 }}>
            <Checkbox
                disableRipple={true}
                sx={{ paddingLeft: 0, paddingRight: 1 }}
                checked={selectedAddresses?.some(a => a.addressKey === address.addressKey) || false}
                onChange={handleCheckboxChange}
            />
            <ListItemText primary={formatAddress(address.address)} />
        </ListItem>
    );
};

export default SelectedAddressListItem;
