import { useEffect, } from "react";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAppDispatch, useAppSelector, useIsAdmin, usePartnerID } from "store/hooks";
import { selectAllTrucks } from "store/reducers/trucks/list";
import { SelectedTruckActions } from "store/reducers/trucks/selected";
import TrucksMethods from "controllers/trucks";

type TruckSelectProps = {
    parentLoading: boolean;
    needDefault?: boolean;
}

function TruckSelect(props: TruckSelectProps) {
    const { parentLoading, needDefault } = props;

    const userIsAdmin = useIsAdmin();

    const loading = useAppSelector(state => state.trucks.list.loading) || parentLoading;
    const partnerID = usePartnerID();
    const trucks = useAppSelector(state => selectAllTrucks(state));
    const selectedTruck = useAppSelector(state => state.trucks.selected.data);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.COMMONS, Namespace.FORMS, Namespace.GLOSSARY,]);

    const [searchParams, setSearchParams] = useSearchParams();

    const selectedTruckID = searchParams.get("truckID");

    useEffect(() => {
        if (partnerID) {
            dispatch(TrucksMethods.list(partnerID, []));
        }
    }, [partnerID]);

    // load truck based on URL params
    useEffect(() => {
        if (needDefault && !selectedTruckID && trucks.length > 0) { // set first truck as selected
            handleTruckSelected(trucks[0].ID);
        }
    }, [trucks]);
    
    useEffect(() => {
        if (trucks) {
            if (selectedTruckID) { // set selected truck
                const truckToSelect = trucks.find(t => t.ID === selectedTruckID);
                if (truckToSelect) {            
                    dispatch(SelectedTruckActions.selectTruck(truckToSelect));
                }
            }
            else if (selectedTruck) { // unselect selected truck
                dispatch(SelectedTruckActions.selectTruck(null));
            }
        }
    }, [trucks, selectedTruckID]);

    let trucksOptions: Option[] = [];

    if (needDefault) {
        if (!selectedTruck) {
            trucksOptions.push({
                label: t("loading", { ns: Namespace.COMMONS }),
                value: "",
            });
        }
    }
    else {
        trucksOptions.push({
            label: t("select.all_trucks", { ns: Namespace.FORMS }),
            value: "",
        });
    }
    trucks.forEach(truck => {
        trucksOptions.push({
            label: truck.displayID + (userIsAdmin ? " - " + truck.sensorNumber : ""),
            value: truck.ID,
        });
    });

    const handleTruckSelected = (id: string) => {
        if (id) {
            searchParams.set("truckID", id);
            setSearchParams(searchParams);
        }
        else {
            searchParams.delete("truckID");
            setSearchParams(searchParams);
        }
    };

    const defaultValue = selectedTruckID || trucksOptions[0].value;
    const title = needDefault ? (selectedTruck?.displayID || trucksOptions[0].label) : t("select.all_trucks", { ns: Namespace.FORMS });

    return (
        <DropdownMenu
            id="truck-select-menu"
            title={title}
            loading={loading}
            values={trucksOptions}
            defaultValue={defaultValue}
            startIcon={<LocalShippingIcon />}
            onChange={(truckID) => handleTruckSelected(truckID)}
        />
    );
}

export default TruckSelect;