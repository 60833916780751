import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES } from "helpers/geo";
import { Bounds, Coords } from "google-map-react";
import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AreasMapContextInterface {
    defaultCenter: Coords;
    defaultBounds: Bounds | undefined;
    defaultZoom: number;
};

const initialState: AreasMapContextInterface = {
    defaultCenter: GRENOBLE_COORDINATES,
    defaultBounds: {
        nw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 },
        sw: { lat: 0, lng: 0 },
        se: { lat: 0, lng: 0 },
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
};

export const areasMapSlice = createSlice({
    name: 'areasMap',
    initialState: initialState,
    reducers: {
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: Coords, bounds: Bounds }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;            
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: Coords, zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
    },
});

export const AreasMapActions = areasMapSlice.actions;

const AreasMapReducer = areasMapSlice.reducer;

export default AreasMapReducer;