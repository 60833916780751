import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

export enum StorageType {
    BATCHES = "batches",
    PARTNERS = "partners",
};

/**
 * Get the formatted base URL for a Google Cloud Storage bucket, using gsutil protocol
 * @example "gs://truck-staging-batches"
 */
export function getStorageURL(storage: StorageType) {
    return `gs://${process.env.REACT_APP_PROJECT_ID}-${storage}`;
}

export async function getFileURL(filepath: string) {
    const storage = getStorage();
    const gsReference = ref(storage, filepath);

    return getDownloadURL(gsReference)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}

export async function getPictureURL(imageURL: string) {
    if (process.env.REACT_APP_IS_LOCALHOST) { // simulate a loading delay on localhost

        function waitMilliseconds(ms: number): Promise<void> {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        
        await waitMilliseconds(500);
    }

    return await getFileURL(`${getStorageURL(StorageType.BATCHES)}/${imageURL}`);
}

export async function getVideoURL(videoURL: string) {    
    if (process.env.REACT_APP_IS_LOCALHOST) { // alway display the same picture on localhost
        videoURL = "Grand Lyon/XX-000-XX/2022-06-17/test_truck.mp4";
    }
        
    return await getFileURL(`${getStorageURL(StorageType.BATCHES)}/${videoURL}`);
}

export async function uploadFile(file: File, filepath: string) {
    const storage = getStorage();
    const storageRef = ref(storage, filepath);

    // 'file' comes from the Blob or File API
    const snapshot = await uploadBytes(storageRef, file);
    const imageURL = await getDownloadURL(snapshot.ref);
    return imageURL;
}

