import { Checkbox } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BatchesActions, selectBatchById } from 'store/reducers/batches/list';
import { ImagesToAnnotateActions, selectBatchToDownloadByID } from 'store/reducers/batches/to_download';

type SelectForDownloadCheckboxProps = {
    batchID: string;
    partnerID: string;
    imageURL: string;
}

function SelectForDownloadCheckbox({ batchID, partnerID, imageURL }: SelectForDownloadCheckboxProps) {
    const checked = useAppSelector(state => selectBatchToDownloadByID(state, batchID) !== undefined);
    const dispatch = useAppDispatch();

    const toggleCheck = (check: boolean) => {
        if (check) { // mark batch as "to download"
            dispatch(ImagesToAnnotateActions.addItem({ ID: batchID, partnerID: partnerID, imageURL: imageURL }));
        }
        else { // remove batch from "to download" list
            dispatch(ImagesToAnnotateActions.removeItem(batchID));
        }
    }

    return (
        <Checkbox 
            checked={checked}
            color="white" 
            onChange={(e, val) => toggleCheck(val)} 
            sx={{ 
                position: "absolute",
                top: theme => theme.spacing(1),
                left: theme => theme.spacing(1),
                color: "#fff",
                '& .MuiSvgIcon-root': { fontSize: 28 }
            }}
            />
    );
}

export default SelectForDownloadCheckbox;