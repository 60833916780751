import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import { Divider, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector, useIsAdmin, usePartnerID } from "store/hooks";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import BatchesController from "controllers/batches";
import { downloadDataAsFile } from "helpers/urls";
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { getErrorsCount } from "helpers/trash";

export default function BatchDetailsActions() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const dispatch = useAppDispatch();

    const isAdmin = useIsAdmin();

    const partnerID = usePartnerID();
    const batchID = useAppSelector(state => state.batches.list.selectedBatch!.ID);
    const loading = useAppSelector(state => state.batches.list.selectedBatch!.loading);
    const display = useAppSelector(state => state.batches.list.selectedBatch!.display);
    const hasError = useAppSelector(state => getErrorsCount(state.batches.list.selectedBatch?.results ?? {}) > 0);

    const handleVisibilityButtonClick = () => {
        if (partnerID && batchID) {
            dispatch(BatchesController.update(partnerID, batchID, { display: !display }));
        }
    }

    /**
     * Handle click on "Get PDF" button
     */
    const handleGetPDFClick = () => {
        if (!partnerID || !batchID) return;

        dispatch(BatchesController.getBatchPDF(partnerID, batchID))
            .then(({ data, filename }) => {
                if (data) { // successful PDF generation
                    downloadDataAsFile(data, filename);
                }
            });
    }

    return (
        <Stack
            px={3}
            py={2}
            width="100%"
            bgcolor="#fff"
        >
            {hasError && (
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    color="secondary"
                    onClick={handleGetPDFClick}
                    startIcon={<PictureAsPdfRoundedIcon />}
                >
                    {t("download_as", { ns: Namespace.ACTIONS, format: "PDF" })}
                </LoadingButton>
            )}

            {isAdmin && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        onClick={handleVisibilityButtonClick}
                        color="error"
                        startIcon={display ? ( // click to hide
                            <VisibilityOffIcon />
                        ) : ( // click to show
                            <VisibilityIcon />
                        )}
                    >
                        {t(display ? "hide" : "show", { ns: Namespace.ACTIONS })}
                    </LoadingButton>
                </>
            )}
        </Stack>
    )
}