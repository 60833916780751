import { Card, CardContent, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { EntityId } from '@reduxjs/toolkit';
import { getGradient } from 'helpers/draw';
import { Namespace } from 'locales/translations';
import Area from 'models/Area';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAreaById } from 'store/reducers/areas/list';

type AreaCardProps = {
    areaID: EntityId;
    onClick: (area: Area) => void;
}

function AreaCard({ areaID, onClick }: AreaCardProps) {
    const area = useAppSelector(state => selectAreaById(state, areaID));

    const selected = useAppSelector(state => state.areas.selected.data?.ID === areaID);

    const { t } = useTranslation([Namespace.DATES]);

    if (!area) return <></>;
    
    return (
        <Card
            onClick={() => onClick(area)}
            {...(selected && { elevation: 6 })}
            sx={{
                display: "block",
                mb: 2,
                cursor: "pointer",
                color: "#fff",
                ...(selected ? { // selected style
                    background: theme => getGradient("vertical", theme.palette.info.main, theme.palette.secondary.main),
                } : { // not selected style
                    backgroundColor: blueGrey[200]
                }),
            }}
            >
            <CardContent>
                <Typography 
                    fontSize={20}
                    fontWeight="bold"
                    gutterBottom
                    sx={{
                        opacity: 0.9,
                    }}
                    >
                    {area.name}
                </Typography>
                <Typography 
                    fontSize={14}
                    sx={{
                        opacity: 0.9,
                    }}
                    >
                    {t("created_on", { ns: Namespace.DATES, datetime: area.createdAt })}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default AreaCard;