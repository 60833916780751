import { Button } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

interface DropdownMenuProps {
    menuId: string;
    disabled?: boolean;
    loading?: boolean;
    label: string;
    startIcon?: JSX.Element;
    open: boolean;
    onToggleMenu: (open: boolean) => void;
}

function OutlinedDropdownMenu({ menuId, disabled, loading, label, startIcon, open, onToggleMenu }: DropdownMenuProps, anchorRef: ForwardedRef<HTMLButtonElement>) {

    return (
        <Button
            id={`open-${menuId}`}
            ref={anchorRef}
            onClick={(e) => onToggleMenu(true)}
            aria-controls={menuId}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            startIcon={startIcon}
            endIcon={<UnfoldMoreIcon />}
            color="inherit"
            disabled={disabled || loading}
            sx={{
                bgcolor: "#fff",
                "&:hover": {
                    bgcolor: "#fff",
                },
                fontWeight: 400,
                borderRadius: 3,
                borderColor: "rgba(0,0,0, 0.1)",
                width: "100%",
                justifyContent: "flex-start",
                display: "flex",
                "& .MuiButton-endIcon": {
                    flex: 1,
                    justifyContent: "flex-end"
                }
            }}
        >
            {label}
        </Button>
    );
}

export default forwardRef(OutlinedDropdownMenu);