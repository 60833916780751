import SectionLoader from "components/_include/SectionLoader";
import { useRef } from "react";
import { useAppSelector } from "store/hooks";
import SortingMap from "./SortingMap";
import { Box } from "@mui/material";
import { DRAWER_WIDTH } from "components/Navigation/Drawer";

type SortingMapProps = {

}

function SortingMapLayout(props: SortingMapProps) {
    const loading = useAppSelector(state => state.batches.sortingMap.loading);

    const mapWrapperRef = useRef<HTMLDivElement>(null);

    return (
        <Box 
            ref={mapWrapperRef} 
            height='100%'
            width={`calc(100% + ${DRAWER_WIDTH}px)`}
            left={-DRAWER_WIDTH}
            position='relative'
            >
            {loading && (
                <SectionLoader />
            )}
            <SortingMap
                width={mapWrapperRef.current?.offsetWidth}
                height={mapWrapperRef.current?.offsetHeight}
                />
        </Box>
    );
}

export default SortingMapLayout;