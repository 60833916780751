import { List } from "@mui/material";
import { MOST_COMMON_TRASH_TYPES } from "constants/trash";
import WasteTypeDrawer from "./WasteTypeDrawer/WasteTypeDrawer";

type DrawersProps = {
    canEditResults?: boolean;
}

/**
 * List of drawers for each most common class of waste.
 * @see MOST_COMMON_TRASH_TYPES
 */
export default function MostCommonWastesDrawers({ canEditResults }: DrawersProps) {
    return (
        <List>
            {MOST_COMMON_TRASH_TYPES.map((wasteType) => (
                <WasteTypeDrawer
                    key={wasteType}
                    wasteType={wasteType}
                    canEditResults={canEditResults}
                />
            ))}

        </List>
    )
}