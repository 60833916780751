import { Box } from "@mui/material";
import CollaboratorsSectionHeader from "./CollaboratorsSectionHeader";
import CollaboratorsTable from "./CollaboratorsTable";

export default function CollaboratorsSection() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={3}
        >
            <CollaboratorsSectionHeader />

            <Box bgcolor="#fff">
                <CollaboratorsTable />
            </Box>
        </Box>
    )
}