

import { Box, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PartnerCard from '../PartnerCard/PartnerCard';

type NewPartnerModalProps = {
    open: boolean;
    onClose: () => void;
}

export default function NewPartnerModal({ open, onClose }: NewPartnerModalProps) {
    // const x = useAppSelector(state => state);
    // const dispatch = useAppDispatch();

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
            }}
        >
            <Box
                position='absolute'
                top='50%'
                left='50%'
                width={theme => theme.spacing(40)}
                sx={{
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <PartnerCard partnerID={null} onPartnerCreated={onClose} />
            </Box>
        </Modal>
    );
}