import { useAppDispatch, useAppSelector, useDebouncedEffect, usePartnerID } from "store/hooks";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { useEffect, useState } from "react";
import PartnersController from "controllers/partners";

export default function PartnerCanAccessSettingsCheckbox() {
    const { t } = useTranslation([Namespace.PARTNERS])

    const partnerID = usePartnerID();

    const partnerLoaded = useAppSelector(state => Boolean(state.partners.selected.data));
    const canAccessSettings = useAppSelector(state => Boolean(state.partners.selected.data?.canAccessSettings));

    const [checked, setChecked] = useState(canAccessSettings);

    /** Set checked when partner is loaded. */
    useEffect(() => {
        if (checked !== canAccessSettings) setChecked(canAccessSettings);
    }, [canAccessSettings]); 

    const dispatch = useAppDispatch();

    /** 
     * Will send a request to update the partner after 300ms without change,
     * to prevent to many calls to the database.
     */
    useDebouncedEffect(() => {
        if (partnerID && partnerLoaded && checked !== canAccessSettings) {
            dispatch(PartnersController.update(partnerID, {
                canAccessSettings: checked,
            }));
        }
    }, [partnerID, partnerLoaded, checked], 300);

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                }
                label={t("can access settings", { ns: Namespace.PARTNERS })}
            />
        </FormGroup>
    );
}