import { AppDispatch } from "store/store";
import { StatsActions } from "store/reducers/stats";
import { showError } from "store/reducers/snacks";
import moment from "moment";
import { fetchAPI } from "./actions";
import { stringifyUrl } from "query-string";
import urls from "constants/urls";
import { API_TIMESTAMP_FORMAT } from "constants/dates";
import Stats from "models/Stats";

const getStats = (partnerID: string, truckID: string, start: moment.Moment, end: moment.Moment, areaID?: string ) => async (dispatch: AppDispatch) => {
    dispatch(StatsActions.startLoading());

    const query = stringifyUrl({
        url: `${urls.API}/partner/${partnerID}/truck/${truckID}/stats`,
        query: {
            start: start.format(API_TIMESTAMP_FORMAT),
            end: end.format(API_TIMESTAMP_FORMAT),
            areaID: areaID,
        },
    });

    try {
        const stats: Stats = await fetchAPI(query);

        dispatch(StatsActions.setData(stats));

        return stats;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load stats", error);
        dispatch(showError(error.message));
        dispatch(StatsActions.setError(error.message));
        return null;
    }
};

const StatsMethods = {
    getStats,
};

export default StatsMethods;