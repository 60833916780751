import { useEffect, useState } from 'react';
import { Snackbar, Alert, Typography, Button, AlertTitle, } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideMessage, showMessage } from 'store/reducers/snacks';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';

type SnackbarProps = {

}

function GlobalSnackbar(props: SnackbarProps) {

    const message = useAppSelector(state => state.snacks.message);
    const variant = useAppSelector(state => state.snacks.variant);
    const moreInfo = useAppSelector(state => state.snacks.moreInfo);

    const { t } = useTranslation([Namespace.ACTIONS]);

    const dispatch = useAppDispatch();

    // callback to display messages pushed into localStorage or redux store
    useEffect(() => {
        let storedActionResult = localStorage.getItem("actionResult");

        if (storedActionResult) {
            const actionResult = JSON.parse(storedActionResult);
            localStorage.removeItem("actionResult");

            dispatch(showMessage(actionResult));
        }
    }, [localStorage]);

    const handleClose = () => {
        dispatch(hideMessage());
    }

    const [moreInfoVisible, setMoreInfoVisible] = useState(false);

    useEffect(() => {
        setMoreInfoVisible(false);
    }, [message]);

    return (
        <Snackbar
            open={Boolean(message)}
            onClose={handleClose}
            sx={{
                margin: '0 auto 2rem',
                alignItems: "center",
            }}
        >
            <Alert
                severity={variant}
                onClose={handleClose}
                aria-describedby="message-id"
                action={moreInfo && (
                    <Button
                        color="inherit"
                        onClick={() => setMoreInfoVisible(!moreInfoVisible)}
                    >
                        {t((moreInfoVisible ? "hide" : "view_error"), { ns: Namespace.ACTIONS })}
                    </Button>
                )}
            >
                <AlertTitle id="message-id" sx={{ mt: .25, mb: 0, }}>
                    {message}
                </AlertTitle>
                {moreInfoVisible && (
                    <Typography variant="body2">
                        {moreInfo}
                    </Typography>
                )}
            </Alert>
        </Snackbar>
    );
}

export default GlobalSnackbar;