import { PayloadAction, createSlice, } from "@reduxjs/toolkit";
import Batch from "models/Batch";
import { LoadableContext } from "store/store";

export type SelectedBatchContext = LoadableContext & {
    data: Batch | null,
};

const initialState: SelectedBatchContext = {
    loading: false,
    error: null,
    data: null,
};

export const selectedBatchSlice = createSlice({
    name: 'selected_batch',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        set: (state, { payload: data }: PayloadAction<Batch | null>) => {
            state.loading = false;
            state.data = data;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.error = error;
        },
    },
});

export const SelectedBatchActions = selectedBatchSlice.actions;

const SelectedBatchReducer = selectedBatchSlice.reducer;

export default SelectedBatchReducer;