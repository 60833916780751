import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CollectionSchedule, { Weekday, StartAndEndTimes } from "models/CollectionSchedule";

type CollectionScheduleContext = LoadableContext & {
    data: CollectionSchedule | null;
    editingTruck: CollectionSchedule["truck"] | undefined;
    editingRoute: CollectionSchedule["route"] | undefined;
    editingCalendar: CollectionSchedule["calendar"] | undefined;
    editingFrequency: CollectionSchedule["frequency"] | undefined;
};

const initialState: CollectionScheduleContext = {
    loading: false,
    error: null,
    data: null,
    editingTruck: undefined,
    editingRoute: undefined,
    editingCalendar: undefined,
    editingFrequency: undefined,
};

const collectionScheduleSlice = createSlice({
    name: 'selectedCollectionSchedule',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        selectCollectionSchedule: (state, { payload: schedule }: PayloadAction<CollectionSchedule | null>) => {
            state.loading = false;
            state.data = schedule;
            state.editingTruck = schedule?.truck;
            state.editingRoute = schedule?.route;
            state.editingCalendar = schedule?.calendar;
            state.editingFrequency = schedule?.frequency;
        },
        setTruck: (state, { payload: truck }: PayloadAction<CollectionSchedule["truck"]>) => {
            state.editingTruck = truck;
        },
        setRoute: (state, { payload: route }: PayloadAction<CollectionSchedule["route"]>) => {
            state.editingRoute = route;
        },
        setFrequency: (state, { payload: frequency }: PayloadAction<CollectionSchedule["frequency"]>) => {
            state.editingFrequency = frequency;
        },
        setWeekday: (state, { payload: { weekday, times }}: PayloadAction<{ weekday: Weekday, times: StartAndEndTimes }>) => {
            state.editingCalendar = {
                ...state.editingCalendar,
                [weekday]: times,
            };
        },
        removeWeekday: (state, { payload: weekday }: PayloadAction<Weekday>) => {
            if (state.editingCalendar) delete state.editingCalendar[weekday];
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedCollectionScheduleActions = collectionScheduleSlice.actions;

const SelectedCollectionScheduleReducer = collectionScheduleSlice.reducer;

export default SelectedCollectionScheduleReducer;