import Collaborator, { APICollaborator, CollaboratorDbData, NewCollaborator } from "models/Collaborator";
import { AppDispatch } from "store/store";
import { CollaboratorsActions } from "store/reducers/collaborators/list";
import { formatQuery, getCollectionRef } from "helpers/db";
import { DbCollection } from "constants/db";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { QueryDocumentSnapshot, DocumentData, DocumentSnapshot, getDocs } from "firebase/firestore";
import { SelectedCollaboratorActions } from "store/reducers/collaborators/selected";
import { fetchAPI } from "./actions";
import urls from "constants/urls";
import { stringToTimestamp } from "helpers/dates";

function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Collaborator;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Collaborator | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as CollaboratorDbData;

    const collaborator: Collaborator = {
        ...data,
        ID: dbDoc.id,
        addedAt: data.addedAt.toMillis(),
    }
    return collaborator;
}

/**
 * List all the collaborators added to a given partner.
 */
const list = (partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(CollaboratorsActions.startLoadingList());

    const collaboratorPath = [DbCollection.PARTNERS, partnerID, DbCollection.COLLABORATORS];

    const collaboratorRef = getCollectionRef(collaboratorPath);
    const query = formatQuery(collaboratorRef);

    try {
        const collaboratorsSnapshot = await getDocs(query.query);
        const collaborators = collaboratorsSnapshot.docs.map(doc => fromDbDoc(doc));

        dispatch(CollaboratorsActions.setList(collaborators));
        return collaborators;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load collaborators", error);
        dispatch(showError(error.message));
        dispatch(CollaboratorsActions.setError(error.message));
        return [];
    }
};

/**
 * Invite a new collaborator to access to a given partner.
 */
const invite = (partnerID: string, collaboratorData: NewCollaborator) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollaboratorActions.startLoading());

    try {
        const collaborator: APICollaborator = await fetchAPI(`${urls.API}/partner/${partnerID}/collaborators`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...collaboratorData,
                partnerID,
            }),
        });

        // add to list
        dispatch(CollaboratorsActions.addOne({
            ...collaboratorData,
            ID: collaborator.ID,
            addedAt: stringToTimestamp(collaborator.addedAt).toMillis(),
        }));

        dispatch(SelectedCollaboratorActions.stopLoading());

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collaborators.invite.success",
            context: { email: collaborator.email },
        }));

        return collaborator;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to invite collaborator", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollaboratorActions.setError(error.message));
        return null;
    }
};

/**
 * Remove a given collaborator from accessing a partner's data.
 * Note that it doesn't delete the Firebase user of the collaborator.
 */
const removeFromPartner = (partnerID: string, collaborator: Collaborator) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollaboratorActions.startLoading());

    try {
        await fetchAPI(
            `${urls.API}/partner/${partnerID}/collaborators/${collaborator.ID}`,
            {
                method: "DELETE",
            }
        );

        // remove from list
        dispatch(CollaboratorsActions.removeOne(collaborator.ID));

        dispatch(SelectedCollaboratorActions.setSelected(null));

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collaborators.remove.success",
            context: { email: collaborator.email },
        }));

        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to remove collaborator", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollaboratorActions.setError(error.message));
        return false;
    }
};

const CollaboratorsController = {
    list,
    invite,
    removeFromPartner,
};

export default CollaboratorsController;