import { Grid } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectPartnersIds } from 'store/reducers/partners/list';
import PartnerCard from './PartnerCard/PartnerCard';
import NewPartnerButton from './NewPartner/NewPartnerButton';

type PartnersGridProps = {

}

function PartnersGrid({ }: PartnersGridProps) {
    const partnersIDs = useAppSelector(selectPartnersIds);

    return (
        <Grid container spacing="3">
            {partnersIDs.map(partnerID => (
                <Grid key={partnerID} item xs={12} sm={6} md={3} xl={3}>
                    <PartnerCard partnerID={partnerID.toString()} />
                </Grid>
            ))}
            <Grid key="add-partner" item xs={12} sm={6} md={3} xl={3} height={theme => theme.spacing(47)}>
                <NewPartnerButton />
            </Grid>
        </Grid>
    );
}

export default PartnersGrid;