import { Box } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import BatchDrawingOverlay from "./BatchDrawingOverlay";
import BatchImageOrVideo from "./BatchImageOrVideo";
import OverlayChoiceToggle from "../OverlayChoiceToggle";
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";
import DrawingBoundingBoxIndicator from "./BoundingBoxes/DrawingBoundingBoxIndicator";
import { BatchesActions } from "store/reducers/batches/list";
import TruckExceptionsModal from "./TruckExceptionsModal";

/** Increase value to make zoom faster, decrease for more control. */
const ZOOM_SPEED = 0.01;

type CardMediaProps = {
    canEditResults?: boolean;
}

/**
 * The left part of validation modal, 
 * displaying the media (image or video) associated with the batch
 * and the boundinx boxes on top 
 */
export default function BatchCardMedia({ canEditResults }: CardMediaProps) {

    const containerRef = useRef<HTMLElement>(null);

    const selectedBatchID = useAppSelector(state => state.batches.list.selectedBatch?.ID);

    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    const dispatch = useAppDispatch();

    const handleImageLoaded = (image: HTMLImageElement) => {
        dispatch(MediaOverlayActions.setImageDimensions({
            width: image.naturalWidth,
            height: image.naturalHeight,
        }));

        setImageLoaded(true);
    }

    const [zoomState, setZoomState] = useState({ delta: 0, clientX: 0, clientY: 0 });

    useEffect(() => {
        if (!selectedBatchID) return;

        const { delta, clientX, clientY } = zoomState;

        // Dispatch action to update zoom
        dispatch(BatchesActions.adjustZoom({
            batchID: selectedBatchID,
            delta: delta * -0.01,
            clientX: clientX,
            clientY: clientY
        }));
    }, [zoomState]);

    return (
        <Box
            ref={containerRef}
            position="relative"
            minWidth="66%"
            flexGrow={1}
            overflow="hidden"
            onWheel={(e) => setZoomState({ delta: e.deltaY, clientX: e.clientX, clientY: e.clientY })}
        >
            {/* Media associated with the batch and processed by AI, 
                either image or several frames encoded as a video */}
            <BatchImageOrVideo
                handleImageRef={(node) => { imageRef.current = node; }}
                onImageLoaded={handleImageLoaded}
            />

            {/* Bounding boxes overlay */}
            {imageRef.current && imageLoaded && (
                <BatchDrawingOverlay
                    canEditResults={canEditResults}
                    image={imageRef.current}
                />
            )}

            {/* Toggle buttons for user to select what they want to see on top of the batch's media */}
            <OverlayChoiceToggle />

            {/* Drawing indicator, click to stop drawing */}
            {containerRef.current && (
                <DrawingBoundingBoxIndicator container={containerRef.current} />
            )}

            {/* Small modal on the top right to quickly visualize results */}
            <TruckExceptionsModal />
        </Box>
    )
}