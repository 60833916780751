import ActionButton from 'components/_include/Buttons/ActionButton';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

type ClearCollectionsFiltersButtonProps = {

}

function ClearCollectionsFiltersButton({ }: ClearCollectionsFiltersButtonProps) {
    const { t } = useTranslation([Namespace.ACTIONS]);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const truckID = searchParams.get("truckID");
    const date = searchParams.get("date");

    const handleClick = () => {
        searchParams.delete("truckID");
        searchParams.delete("date");
        setSearchParams(searchParams);
    }

    return (
        <ActionButton
            onClick={handleClick}
            color='secondary'
            size='compact'
            disabled={!truckID && !date}
            >
            {t("clear", { ns: Namespace.ACTIONS })}
        </ActionButton>
    );
}

export default ClearCollectionsFiltersButton;