import { PayloadAction, createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import Batch from "models/Batch";

type BatchImageURL = Pick<Batch, "ID" | "partnerID" | "imageURL">;

const toAnnotateArchivesAdapter = createEntityAdapter<BatchImageURL>({
    selectId: batch => batch.ID,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

// const initialLoadableState: LoadableContext = { 
//     loading: false,
//     error: null,
// };

// const initialState: LoadableContext = {
//     ...initialLoadableState,
// }

export const imagesToAnnotateSlice = createSlice({
    name: 'to_annotate',
    initialState: toAnnotateArchivesAdapter.getInitialState(),
    reducers: {
        addItem: (state, { payload }: PayloadAction<BatchImageURL>) => {
            toAnnotateArchivesAdapter.addOne(state, payload);
        },
        setAll: (state, { payload: batches }: PayloadAction<BatchImageURL[]>) => {
            toAnnotateArchivesAdapter.setAll(state, batches);
        },
        removeItem: (state, { payload: batchID }: PayloadAction<string>) => {
            toAnnotateArchivesAdapter.removeOne(state, batchID);
        },
        clearList: (state) => {
            toAnnotateArchivesAdapter.removeAll(state);
        },
    },
});

export const ImagesToAnnotateActions = imagesToAnnotateSlice.actions;

export const {
    selectAll: selectAllBatchesToDownload,
    selectById: selectBatchToDownloadByID,
    selectIds: selectBatchesToDownloadIDs,
    selectTotal: selectNumberOfBatchesToDownload,
} = toAnnotateArchivesAdapter.getSelectors((state: RootState) => state.batches.toDownload);

export default imagesToAnnotateSlice.reducer;