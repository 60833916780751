import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import MapDotMarker from '../../MapDotMarker';
import { DangerLevel } from 'constants/stats';
import { DANGER_COLORS } from 'helpers/draw';

type MapLegendMarkerProps = {
    level: DangerLevel;
}

const MARKER_SIZE = 3;

/** Get the DangerColor key matching a given value */
const getTranslationKey = (level: DangerLevel) => {
    return Object
        .entries(DangerLevel)
        .filter(([_, value]) => value === level)[0][0];
}

function MapLegendMarker({ level }: MapLegendMarkerProps) {
    const { t } = useTranslation([Namespace.MAPS]); 

    const theme = useTheme();


    return (
        <ListItem>
            <ListItemIcon>
                <MapDotMarker 
                    color={DANGER_COLORS[level]} 
                    size={theme.spacing(MARKER_SIZE)} 
                    zIndex={1}  
                    inline                     
                    />
            </ListItemIcon>
            <ListItemText
                primary={t(`legend.dialog.${getTranslationKey(level)}`)}
                />
        </ListItem>
    );
}

export default MapLegendMarker;