import { Button } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface DropdownMenuProps {
    menuId: string;
    disabled?: boolean;
    loading?: boolean;
    label: string;
    startIcon?: JSX.Element;
    open: boolean;
    onToggleMenu: (open: boolean) => void;
}

function ContainedDropdownMenu({ menuId, disabled, loading, label, startIcon, open, onToggleMenu }: DropdownMenuProps, anchorRef: ForwardedRef<HTMLButtonElement>) {

    return (
        <Button
            id={`open-${menuId}`}
            ref={anchorRef}
            onClick={(e) => onToggleMenu(true)}
            aria-controls={menuId}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            startIcon={startIcon}
            endIcon={<ArrowDropDownIcon />}
            color="white"
            disabled={disabled || loading}
            sx={{
                fontWeight: 400,
                width: "100%",
                justifyContent: "flex-start",
                display: "flex",
                "& .MuiButton-endIcon": {
                    flex: 1,
                    justifyContent: "flex-end"
                }
            }}
        >
            {label}
        </Button>
    );
}

export default forwardRef(ContainedDropdownMenu);