import i18next, { Resource } from "i18next";
import { upperFirst } from "lodash";
import moment from "moment";
import { initReactI18next } from 'react-i18next';

export const DEFAULT_TIMEZONE = "Europe/Paris";

export enum Locale {
    ENGLISH = "en",
    FRENCH = "fr",
}

export const LOCALES_LABELS = {
    [Locale.ENGLISH]: "English",
    [Locale.FRENCH]: "Français",
}

export enum Namespace {
    /** Labels for buttons (eg "filter", "confirm") */
    ACTIONS = 'actions',
    /** Related to user-defined areas (eg "Qu'est-ce qu'une zone ?"...) */
    AREAS = 'areas',
    /** Labels for charts (eg "Evolution du tri"...) */
    CHARTS = 'charts',
    /** Labels and strings about collections (eg "Pas de collecte"...) */
    COLLECTIONS = 'collections',
    /** Common words (eg "points", ...) */
    COMMONS = 'commons',
    /** Related to contacts management (eg "ceo", "developer") */
    CONTACTS = 'contacts',
    /** Time and dates display (eg "depuis 3 semaines") */
    DATES = 'dates',
    /** Confirmation dialogs (eg "Confirmer que ... ?") */
    DIALOGS = 'dialogs',
    /** Forms placeholders/labels (eg "Entrez votre adresse...") */
    FORMS = 'forms',
    /** Ficha specific words (eg "Levée de bac"...) */
    GLOSSARY = 'glossary',
    /** Related to batches images (eg "Télécharger les images"...) */
    IMAGES = 'images',
    /** Words related to maps (eg "Légende", "Marqueur"...) */
    MAPS = 'maps',
    /** Vocabulary related to clients/partners (eg "Partenaire"...) */
    PARTNERS = 'partners',
    /** Vocabulary related to user's preferences (eg "Shortcuts"...) */
    PREFERENCES = 'preferences',
    /** Messages for the global snackbar (eg "Action successful!") */
    SNACKS = 'snacks',
    /** eg "kg of CO2"... */
    STATS = 'stats',
    /** eg "Chats", "Statistiques"... */
    TITLES = 'titles',
    /** Types of waste (eg "plastic bottle", ...) */
    WASTES = 'wastes',
    /** Types of waste (eg "plastic bottle", ...) */
    MISSIONS = 'missions',
}

/** 
 * Get the formatted resources for translation to provide to i18next.
 * Link the translations for all the namespaces for all the locales. 
 */
const getResources = () => {
    return Object.values(Locale).reduce((resources, locale) => ({
        ...resources,
        [locale]: Object.values(Namespace).reduce((resource, namespace) => ({
            ...resource,
            [namespace]: require(`./${locale}/${namespace}.json`),
        }), {}),
    }), {} as Resource);
}

export const format = (value: any, format: string | undefined, lng: string | undefined) => {
    switch (format) {
        case "number":
            return new Intl.NumberFormat(lng, {
                style: 'decimal',
                maximumFractionDigits: 2,
            }).format(value);

        case "currency":
        case "rounded_currency":
            const fractionDigits = format === "rounded_currency" ? 0 : 2;
            return value.toLocaleString(lng, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });

        case "coordinate":
            return new Intl.NumberFormat(lng, {
                style: 'decimal',
                maximumFractionDigits: 7,
            }).format(value); 

        case "date_short": return moment(value).format("DD/MM/YYYY");

        case "date": return moment(value).format("LL");
        
        case "datetime_full": 
            return upperFirst(moment(value).toDate().toLocaleString(lng?.toUpperCase(), { dateStyle: "full", timeStyle: "short", timeZone: DEFAULT_TIMEZONE }));

        case "datetime_long": 
            return moment(value).toDate().toLocaleString(lng?.toUpperCase(), { dateStyle: "long", timeStyle: "short", timeZone: DEFAULT_TIMEZONE });

        case "datetime_full_seconds":
            return upperFirst(moment(value).toDate().toLocaleString(
                lng?.toUpperCase(), 
                { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", timeZone: DEFAULT_TIMEZONE }
            ));

        case "hours_and_minutes":
            return moment(value).toDate().toLocaleString(lng?.toUpperCase(), { hour: "numeric", minute: "numeric", timeZone: DEFAULT_TIMEZONE })

        case "day": return moment(value).format("ddd D MMM");

        case "day_full": return upperFirst(moment(value).format("dddd D MMMM"));
        
        case "day_month_year": return upperFirst(moment(value).format("ll"))

        case "weekday": return upperFirst(moment(value).toDate().toLocaleDateString("FR", { weekday: "long", timeZone: DEFAULT_TIMEZONE }));

        case "week": return `${moment(value).startOf("week").format("D MMM")}-${moment(value).endOf("week").format("D MMM")}`;

        case "week_full": return `Semaine du ${moment(value).startOf("week").format("D MMMM")} au ${moment(value).endOf("week").format("D MMMM")}`;
    
        case "month": return upperFirst(moment(value).format("MMMM"));
    }
    
    return value;
}

export const LOCAL_STORAGE_LOCALE_KEY = "preferred_locale";

export const configureTranslations = () => {
    i18next
        .use(initReactI18next)
        .init({
            resources: getResources(),
            lng: localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || 'fr',
            supportedLngs: Object.values(Locale),
            load: 'languageOnly',
            // debug: true,
            interpolation: {
                format: format,
                escapeValue: false,
            }
        });
}