import { Box, useTheme } from "@mui/material";
import MapHelpSpeedDial from "components/_include/Maps/MapHelpSpeedDial/MapHelpSpeedDial";
import { Outlet } from "react-router-dom";
import { useIsAdmin, usePartnerID } from "store/hooks";
import SectionLoader from "../_include/SectionLoader";
import BatchesDrawer from "./SortingMap/DetailsDrawer/BatchesDrawer";
import SortingMapFilters from "./SortingMap/Filters/SortingMapFilters";
import PlacesListDrawer from './SortingMap/PlacesListDrawer/PlacesListDrawer';
import SortingMapLayout from "./SortingMap/SortingMapLayout";
import { HEADER_HEIGHT_SPACING } from "components/Missions/MissionDetail/MissionDetailPage";

type StatisticsProps = {
    showForMissions: boolean;
}

function SortingMapPage({showForMissions}: StatisticsProps) {

    const isAdmin = useIsAdmin();
    const partnerID = usePartnerID();

    const theme = useTheme();

    return (
        !partnerID && !isAdmin ? (
            <SectionLoader />
        ) : ( // partnerID loaded or admin
            <Box
                width="100%"
                height={showForMissions ? `calc(100vh - ${theme.spacing(HEADER_HEIGHT_SPACING)})` : "100vh"}
                position="relative"
                paddingLeft={showForMissions ?  20 : 0}
            >
                <SortingMapLayout />
                <Box
                    position="absolute"
                    top={(theme) => theme.spacing(1)}
                    left={(theme) => theme.spacing(1)}
                    right={(theme) => theme.spacing(1)}
                    height={(theme) => `calc(100% - ${theme.spacing(2)})`}
                    display="flex"
                    flexDirection="row"
                    justifyContent="stretch"
                    sx={{
                        pointerEvents: "none",
                    }}
                >
                    <PlacesListDrawer showForMissions ={showForMissions}/>
                    <SortingMapFilters showForMissions={showForMissions} />
                    <MapHelpSpeedDial />
                    <BatchesDrawer />
                </Box>
                <Outlet />
            </Box>
        )
    )
};

export default SortingMapPage;
