import React from 'react';
import { Contact } from 'constants/contacts';
import ContactsCard from './ContactsCard';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

interface ContactsCompanySectionProps {
    company: string,
    contacts: Contact[],
}

function ContactsCompanySection(props: ContactsCompanySectionProps) {
    const { company, contacts } = props;

    const { t } = useTranslation([Namespace.CONTACTS]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            >
            <Typography variant="h4">
                {t(company, { ns: Namespace.CONTACTS })}
            </Typography>

            <Grid
                container
                spacing={3}
            >
                {contacts.map((contact) => (
                    <Grid item xs={12} sm={6} md={4} key={contact.email}>
                        <ContactsCard
                            contact={contact}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ContactsCompanySection;