import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Area, { AreaDbData, OsmPolygon } from "models/Area";
import { LoadableContext, RootState } from "store/store";

const osmAreasAdapter = createEntityAdapter<OsmPolygon>({
    selectId: area => area.id,
    // sortComparer: (a, b) => a.name.localeCompare(b.name)
});

type OsmAreasContext = LoadableContext & {
    searchString: string;
    selected: OsmPolygon | null;
};

const initialState: OsmAreasContext = {
    loading: false,
    error: null,
    searchString: "",
    selected: null,
};


export const osmAreasSlice = createSlice({
    name: 'osm_areas',
    initialState: osmAreasAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state, { payload: searchString }: PayloadAction<string>) => {
            osmAreasAdapter.removeAll(state); // clear all loaded areas (eg change of selected partner)
            state.loading = true;
            state.error = null;
            state.searchString = searchString;
        },
        setList: (state, { payload: areas }: PayloadAction<OsmPolygon[]>) => {
            state.loading = false;
            osmAreasAdapter.setAll(state, areas);
        },
        setSelected: (state, { payload: selected }: PayloadAction<OsmPolygon | null>) => {
            state.selected = selected;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const OsmAreasActions = osmAreasSlice.actions;

export const {
    selectAll: selectAllOsmResults,
    selectById: selectOsmResultById,
    selectIds: selectOsmResultsIds
} = osmAreasAdapter.getSelectors((state: RootState) => state.areas.osm)

const OsmAreasReducer = osmAreasSlice.reducer;

export default OsmAreasReducer;