import { Box, Typography } from "@mui/material";
import BatchesMap from "components/ManageCollections/CollectionDetail/BatchesMap/BatchesMap";
import { useRef } from "react";
import { useAppSelector } from "store/hooks";
import DefaultBoundsMap from "./DefaultBoundsMap";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import DefaultMapBoundsWrapper from "./DefaultMapBoundsWrapper";

type DefaultMapBoundsSectionProps = {

}

function DefaultMapBoundsSection({ }: DefaultMapBoundsSectionProps) {
    const mapWrapperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation([Namespace.MAPS]);

    return (
        <Box>
            <Typography variant="h3">{t("default_viewport", { ns: Namespace.MAPS })}</Typography>
            <DefaultMapBoundsWrapper />
        </Box>
    );
}

export default DefaultMapBoundsSection;