import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, TextField, DialogActions, Button, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { Namespace } from 'locales/translations';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useTranslation } from 'react-i18next';

type HowItWorksDialogProps = {
    open: boolean;
    onClose: () => void;
}

function HowItWorksDialog({ open, onClose, }: HowItWorksDialogProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.AREAS]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogIcon
                Icon={InfoRoundedIcon}
            />
            <DialogTitle align='center'>
                {t("what_are_areas.label", { ns: Namespace.AREAS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    whiteSpace="break-spaces"
                    >
                    {t("what_are_areas.dialog", { ns: Namespace.AREAS })}
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("close", { ns: Namespace.ACTIONS })}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HowItWorksDialog;