import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Area from "models/Area";
import { LoadableContext, RootState } from "store/store";

const areasAdapter = createEntityAdapter<Area>({
    selectId: area => area.ID,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

type AreasContext = LoadableContext & {

};

const initialState: AreasContext = {
    loading: false,
    error: null,
};

export const areasSlice = createSlice({
    name: 'areas',
    initialState: areasAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            areasAdapter.removeAll(state); // clear all loaded areas (eg change of selected partner)
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: areas }: PayloadAction<Area[]>) => {
            state.loading = false;
            areasAdapter.setAll(state, areas);
        },
        addItem: (state, { payload: area }: PayloadAction<Area>) => {
            areasAdapter.addOne(state, area);
        },
        updateItem: (state, { payload: { areaID, data } }: PayloadAction<{ areaID: string, data: Partial<Area> }>) => {
            let areaData = state.entities[areaID]!;
            areasAdapter.updateOne(state, { 
                id: areaID, 
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const AreasActions = areasSlice.actions;

export const {
    selectAll: selectAllAreas,
    selectById: selectAreaById,
    selectIds: selectAreasIds
} = areasAdapter.getSelectors((state: RootState) => state.areas.list)

const AreasReducer = areasSlice.reducer;

export default AreasReducer;