import ActionButton from "components/_include/Buttons/ActionButton";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import DownloadReportDialog from "./DowloadReportDialog";
import { useState } from "react";

export default function DownloadCollectionReportButton() {
    const { t } = useTranslation([Namespace.COLLECTIONS]);

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <ActionButton
                color="success"
                startIcon={<DownloadIcon />}
                onClick={() => setDialogOpen(true)}
            >
                {t("download_report", { ns: Namespace.COLLECTIONS })}
            </ActionButton>

            <DownloadReportDialog 
                open={dialogOpen} 
                onClose={() => setDialogOpen(false)} 
                />
        </>
    )
}