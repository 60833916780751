import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import ModelTrainingIcon from '@mui/icons-material/ModelTrainingRounded';

type AIResultsOffSelectProps = {
    parentLoading: boolean;
}

export enum SHOW_AI_RESULTS_OFF {
    SHOW_ALL = "0",
    RESULTS_OFF_ONLY = "1",
};

function AIResultsOffSelect(props: AIResultsOffSelectProps) {
    const { parentLoading } = props;

    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const hideAIResultsOff = searchParams.get("ai_off");

    const handleAIResultsOffSelected = (hide: SHOW_AI_RESULTS_OFF) => {
        searchParams.set("ai_off", hide);
        setSearchParams(searchParams);
    };

    const options: Option[] = Object.values(SHOW_AI_RESULTS_OFF).map(value => ({
        label: t(`manage_batches.show_ai_off.${value}`, { ns: Namespace.FORMS }),
        value: value,
    }));

    return (
        <DropdownMenu
            id="ai-off-select-menu"
            title={t(`manage_batches.show_ai_off.title`, { ns: Namespace.FORMS })}
            loading={loading}
            values={options}
            defaultValue={hideAIResultsOff || SHOW_AI_RESULTS_OFF.SHOW_ALL}
            startIcon={<ModelTrainingIcon />}
            onChange={(value) => handleAIResultsOffSelected(value as SHOW_AI_RESULTS_OFF)}
        />
    );
}

export default AIResultsOffSelect;