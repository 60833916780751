import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import RouteIcon from 'components/_include/Icons/RouteIcon';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import RoutesController from 'controllers/routes';
import { usePartnerID } from 'store/hooks';
import { CollectionType } from 'models/Collection';
import { selectRouteById } from 'store/reducers/routes/list';

type EditRouteDialogProps = {
    routeID: string | null;
    onClose: () => void;
}

function EditRouteDialog(props: EditRouteDialogProps) {
    const { routeID, onClose } = props;

    const routeIdentifier = routeID || "";

    const route = useAppSelector(state => selectRouteById(state, routeIdentifier));
    const loading = useAppSelector(state => state.routes.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const [routeLabel, setRouteLabel] = useState("");

    useEffect(() => {
        if (route?.label) setRouteLabel(route.label);
    }, [route?.label]);

    const handleSaveRouteClick = () => {
        if (route) {
            dispatch(RoutesController.update(route.partnerID, route.ID, {
                label: routeLabel,
            }))
                .then((success) => {
                    if (success) { // route successfully added
                        onClose();
                    }
                });
        }
    }

    return (
        <Dialog
            open={route !== undefined}
            onClose={onClose}
        >
            <DialogIcon
                Icon={RouteIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.routes.update_route", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 3 }}>
                    {t("onboarding.routes.description", { ns: Namespace.FORMS })}
                </DialogContentText>
                <Box>
                    <TextField
                        margin='normal'
                        value={routeLabel}
                        onChange={(e) => setRouteLabel(e.target.value)}
                        label={t("onboarding.routes.route_label", { ns: Namespace.FORMS })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="success"
                    sx={{
                    }}
                    onClick={handleSaveRouteClick}
                    loading={loading}
                    disabled={routeLabel.length === 0}
                >
                    <span>{t("save", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default EditRouteDialog;