

import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Box, } from "@mui/material";
import ActionButton from "components/_include/Buttons/ActionButton";
import { useAppDispatch, useAppSelector } from "store/hooks";
import BatchesController from "controllers/batches";

type LoadMoreBatchesProps = {

}

function LoadMoreBatchesPage(props: LoadMoreBatchesProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.TITLES]);

    const batchesLoading = useAppSelector(state => state.batches.list.loading);

    const dispatch = useAppDispatch();

    const loadMoreBatches = () => {
        dispatch(BatchesController.listOlder());
    };

    return (
        <Box
            textAlign="center"
            py={2}
        >
            <ActionButton
                onClick={loadMoreBatches}
                disabled={batchesLoading}
                loading={batchesLoading}
                color="gradient"
            >
                {t("load_more_images", { ns: Namespace.ACTIONS })}
            </ActionButton>
        </Box>
    );
};

export default LoadMoreBatchesPage;
