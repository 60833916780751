import { Box, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ChangeCollectionTypeButton from './ChangeCollectionType/ChangeCollectionTypeButton';
import UploadRFIDExcelButton from './UploadRFIDExcel/UploadRFIDExcelButton';
import DownloadCollectionReportButton from './DownloadCollectionReport/DownloadCollectionReportButton';
import { useCanWrite, usePartnerID } from 'store/hooks';

type CollectionActionsProps = {

}

function CollectionActions({ }: CollectionActionsProps) {
    const partnerID = usePartnerID();
    const canWrite = useCanWrite(partnerID);

    const { t } = useTranslation([Namespace.COMMONS]);

    return (
        <Box>
            <Typography
                variant="h3"
                mb={4}
            >
                {t("actions", { ns: Namespace.COMMONS })}
            </Typography>

            <Box
                display="flex"
                justifyContent="flex-start"
                gap={2}
            >
                <DownloadCollectionReportButton />

                {canWrite && (
                    <UploadRFIDExcelButton />
                )}

                {canWrite && (
                    <ChangeCollectionTypeButton />
                )}
            </Box>
        </Box>
    );
}

export default CollectionActions;