import { DocumentData } from "firebase/firestore";
import { DbOrder, QueryFilter } from "./types";

export enum DbCollection {
    AREAS = "areas",
    BATCHES = "batches",
    COLLABORATORS = "collaborators",
    COLLECTIONS = "collections",
    COLLECTIONS_SCHEDULES = "collections_schedules",
    IMAGES_TO_ANNOTATE = "images_to_annotate",
    INVITATIONS = "invitations",
    PARTNERS = "partners",
    ROUTES = "routes",
    SAVED_ADDRESSES = "saved_addresses",
    TRUCKS = "trucks",
    MISSIONS = "missions",
};

export type NextQuery<T> = { 
    isCollectionGroup: boolean,
    collectionPath: string,
    startAfterValue: any,
    filters: QueryFilter<T>[], 
    order: DbOrder<T>, 
    limit: number,
};

export type ModelData = DocumentData & { ID: string };