import { Box, ButtonBase, Tooltip, useTheme } from "@mui/material";
import MapDotMarker from "components/_include/Maps/MapDotMarker";
import { memo } from "react";
import { useAppSelector } from "store/hooks";
import { isEqual } from "lodash";
import { DangerLevel } from "constants/stats";
import { DANGER_COLORS } from "helpers/draw";

type AddressMarkerProps = {
    addressKey: string;
    dangerLevel: DangerLevel;
    address: string;
    lat: number; // required by google-map-react parent component
    lng: number; // required by google-map-react parent component
    onClick: (placeID: string) => void;
}

const MARKER_HEIGHT = 16;
const SELECTED_MARKER_HEIGHT = 24;

/**
 * Get the z-index value for a map marker, based on its threat level.
 * Biggest threat (worst sorting) should appear on top
 */
export const getZIndex = (color: DangerLevel) => {
    switch (color) {
        case DangerLevel.NONE: return 90;
        case DangerLevel.LOW: return 91;
        case DangerLevel.MEDIUM: return 92;
        case DangerLevel.HIGH: return 93;
        case DangerLevel.VERY_HIGH: return 94;
    }
}

function AddressMarker({ addressKey, dangerLevel, address, onClick }: AddressMarkerProps) {

    const theme = useTheme();

    const selected = useAppSelector(state => state.batches.sortingMap.selectedAddressKey === addressKey);
    const hovered = useAppSelector(state => state.batches.sortingMap.hoveredAddressKey === addressKey);
    const size =  hovered || selected ? SELECTED_MARKER_HEIGHT : MARKER_HEIGHT;

    const displayedColor = selected ? theme.palette.info.main : DANGER_COLORS[dangerLevel];

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <ButtonBase
                onClick={() => onClick(addressKey)}
            >
                <Tooltip
                    title={address}
                    arrow
                >
                    <MapDotMarker 
                        color={displayedColor} 
                        size={size}
                        zIndex={getZIndex(dangerLevel)}  
                        selected={selected}
                        hovered={hovered}                     
                        />
                </Tooltip>
            </ButtonBase>
        </Box>
    );
}
// use memo to only re-render card if props have changed
function areEquals(props1: AddressMarkerProps, props2: AddressMarkerProps) {
    return isEqual(props1, props2);
}

export default memo(AddressMarker, areEquals);