import { UserClaims, UserRole } from 'models/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locale } from 'locales/translations';
import { User as FirebaseUser } from 'firebase/auth';

type UserContext = Omit<UserClaims, "role"> & {
    email: string | null;
    role: UserRole | null;
    viewAsPartner: boolean;
    loading: boolean;
    creating: boolean;
    error: string | null;
};

const initialState: UserContext = {
    email: null,
    role: null,
    viewAsPartner: false,
    locale: Locale.FRENCH,
    loading: false,
    creating: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
        },
        startCreating: (state) => {
            state.creating = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        stopCreating: (state) => {
            state.creating = false;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.creating = false;
            state.error = error;
        },
        resetError: (state) => {
            state.error = null;
        },
        setUser: (state, { payload: data }: PayloadAction<UserClaims & Pick<FirebaseUser, "email">>) => {
            state.loading = false;
            state.error = null;
            state.role = data.role ;
            state.locale = data.locale;
            state.partnerID = data.partnerID;
            state.partners = data.partners;
            state.email = data.email;
        },
        togglePartnerView: (state, { payload: viewAsPartner }: PayloadAction<boolean>) => {
            state.viewAsPartner = viewAsPartner;
            localStorage.setItem("view-as-partner", viewAsPartner ? "1" : "")
        },
    }
});

export const UserActions = userSlice.actions;

const UserReducer = userSlice.reducer;

export default UserReducer;