import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import InterestsRoundedIcon from '@mui/icons-material/InterestsRounded';
import { Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapLegendDialog from './LegendDialog/MapLegendDialog';

type MapHelpSpeedDialProps = {

}

function MapHelpSpeedDial({ }: MapHelpSpeedDialProps) {
    const { t } = useTranslation([Namespace.COMMONS, Namespace.MAPS]);

    const [speedDialOpen, toggleSpeedDial] = useState(false);
    const [legendDialogOpen, toggleLegendDialog] = useState(false);

    const handleCloseSpeedDial = () => {
        // only close speed dial if no sub-dialog was open
        if (!legendDialogOpen) toggleSpeedDial(false);
    }

    useEffect(() => {
        // close speed dial when sub-dialog is closed
        if (!legendDialogOpen) toggleSpeedDial(false);
    }, [legendDialogOpen]);

    return (
        <Box 
            flexGrow={1}
            zIndex={0}
            width={theme => theme.spacing(12)}
            >
            <Backdrop open={speedDialOpen ?? legendDialogOpen} />
            <SpeedDial
                ariaLabel={t("help", { ns: Namespace.COMMONS })}
                sx={{ 
                    position: 'absolute', 
                    top: theme => theme.spacing(1),
                    right: theme => theme.spacing(2),
                }}
                FabProps={{
                    color: "white"
                }}
                icon={
                    <SpeedDialIcon 
                        icon={<HelpRoundedIcon />}
                        openIcon={<CloseRoundedIcon />}
                        />
                }
                onClose={handleCloseSpeedDial}
                onOpen={() => toggleSpeedDial(true)}
                open={speedDialOpen}
                direction='down'
            >
                {/* Map legend */}
                <SpeedDialAction
                    key="legend"
                    icon={<InterestsRoundedIcon />}
                    tooltipTitle={t("legend.tooltip", { ns: Namespace.MAPS })}
                    tooltipOpen
                    onClick={() => toggleLegendDialog(true)}
                />
            </SpeedDial>

            <MapLegendDialog 
                open={legendDialogOpen}
                onClose={() => toggleLegendDialog(false)}
                />
        </Box>
    );
}

export default MapHelpSpeedDial;