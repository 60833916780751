import { Box } from "@mui/material";
import { useAppSelector } from "store/hooks";

export default function CollectionsListBackground() {
    const selected = useAppSelector(state => state.collections.selected.data !== null);

    return (
        <Box
            position="absolute" top={0} right={0} left={0}
            bottom={theme => selected ? theme.spacing(6) : 0} 
            bgcolor="#FFFFFF"
            sx={{
                borderBottomLeftRadius: theme => theme.shape.borderRadius * 3,
                borderBottomRightRadius: theme => theme.shape.borderRadius * 3,
            }}
        >
            
        </Box>
    )
}