import { Container } from '@mui/material';
import PageTitle from 'components/_include/PageTitle';
import MissionsGrid from './MissionsGrid';

export default function MissionsPage() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PageTitle />
            <MissionsGrid />
        </Container>
    );
}
