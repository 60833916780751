import { Box, IconButton, useTheme } from '@mui/material';
import ActionButton from 'components/_include/Buttons/ActionButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import { SyntheticEvent, useCallback, useState } from 'react';
import { cleanCanvas, drawLabel, drawRectangle } from 'helpers/draw';
import { BoundingBox } from 'models/BoundingBox';
import { MERGING_WASTE_MAPPING, isSortingError } from 'helpers/trash';
import { TrashType, WASTES_COLORS } from 'constants/trash';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { Moment } from 'moment';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { useAppDispatch, usePartnerID } from 'store/hooks';
import BatchesController from 'controllers/batches';
import { downloadDataAsFile } from 'helpers/urls';

type FullScreenBatchImageProps = {
    batchID: string;
    loading: boolean;
    pictureDate: Moment;
    pictureURL: string;
    bboxes: BoundingBox[];
    onClose?: () => void;
}

const IMAGE_ID = "full-screen-batch-image";

export default function FullScreenBatchImage({ batchID, loading, pictureDate, pictureURL, bboxes, onClose }: FullScreenBatchImageProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.WASTES]);

    const partnerID = usePartnerID();

    const dispatch = useAppDispatch();

    const theme = useTheme();

    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

    const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>();

    /** Callback once image has been loaded to get its original dimensions. */
    const onImageLoaded = (e: SyntheticEvent<HTMLImageElement>) => {
        if (e.target instanceof HTMLImageElement) {
            setImageDimensions({
                width: e.target.naturalWidth,
                height: e.target.naturalHeight,
            });
        }
    };

    /**
     * Draw the bounding boxes when they're loaded and the canvas is ready
     */
    const handleCanvasRef = useCallback((node: HTMLCanvasElement | null) => {
        setCanvas(node);

        const ctx = node?.getContext("2d");

        if (node && ctx) {
            cleanCanvas(ctx, node.width, node.height);

            const resultsToDraw = bboxes.filter(box => isSortingError(box.class));

            for (let bbox of resultsToDraw) {
                const classToDraw = MERGING_WASTE_MAPPING[bbox.class] ?? bbox.class; // display "parent" class if merged

                const wasteColor = WASTES_COLORS[classToDraw];
                drawRectangle(ctx, bbox.box[0], bbox.box[1], wasteColor, 4);
                drawLabel(
                    ctx,
                    [bbox.box[0][0], bbox.box[0][1] - 10],
                    t(classToDraw + (classToDraw === TrashType.OTHER ? "_small" : ""), { ns: Namespace.WASTES, count: 1 }),
                    22, "Roboto Mono",
                    wasteColor,
                    "#222222",
                );
            }
        }
    }, [bboxes, t]);

    /**
     * Handle click on download button
     */
    const handleDownloadClick = () => {
        const img = document.getElementById(IMAGE_ID) as HTMLImageElement | null;

        if (!canvas || !img) return;

        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;

        // Create a new canvas to merge the image and canvas content
        const mergedCanvas = document.createElement('canvas');
        mergedCanvas.width = imgWidth;
        mergedCanvas.height = imgHeight;
        const mergedCtx = mergedCanvas.getContext('2d')!;

        // Draw the original image on the merged canvas
        mergedCtx.drawImage(img, 0, 0, imgWidth, imgHeight);

        // Draw the canvas content (including the rectangle) on the merged canvas
        const canvasData = canvas.toDataURL('image/jpg');
        const canvasImage = new Image();
        canvasImage.src = canvasData;
        canvasImage.onload = function () {
            mergedCtx.drawImage(canvasImage, 0, 0, imgWidth, imgHeight);

            // Create a download link for the merged image
            const downloadLink = document.createElement('a');
            downloadLink.href = mergedCanvas.toDataURL('image/jpg');
            downloadLink.download = `waste_${pictureDate.format("YYYY-MM-DD_HH:mm:ss")}.jpg`;

            // Simulate a click to trigger the download
            downloadLink.click();
        };
    }

    /**
     * Handle click on "Get PDF" button
     */
    const handleGetPDFClick = () => {
        if (!partnerID || !batchID) return;

        dispatch(BatchesController.getBatchPDF(partnerID, batchID))
            .then(({ data, filename }) => {
                if (data) { // successful PDF generation
                    downloadDataAsFile(data, filename);
                }
            });
    }

    return (
        <Box
            width="100%"
            height="100%"
        >
            <Box
                display="flex"
                justifyContent="flex-end"
                gap={2}
                p={2}
            >
                <ActionButton
                    color='secondary'
                    startIcon={<PictureAsPdfRoundedIcon />}
                    onClick={handleGetPDFClick}
                    loading={loading}
                >
                    {t("download_as", { ns: Namespace.ACTIONS, format: "PDF" })}
                </ActionButton>
                <ActionButton
                    color='success'
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadClick}
                >
                    {t("download", { ns: Namespace.ACTIONS })}
                </ActionButton>
                {onClose && (
                    <IconButton
                        color='white'
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
            <Box
                width="100%"
                height={`calc(100% - ${theme.spacing(4)} - 50px)`} // 100% - top buttons
                position="relative"
            >
                <Box
                    id={IMAGE_ID}
                    component="img"
                    onLoad={onImageLoaded}
                    src={pictureURL}
                    width="100%"
                    height="100%"
                    crossOrigin="anonymous" // required to allow drawing into file to download
                    style={{
                        objectFit: "contain",
                    }}
                />

                {imageDimensions && (
                    <canvas
                        ref={handleCanvasRef}
                        width={imageDimensions.width}
                        height={imageDimensions.height}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: "contain",
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}