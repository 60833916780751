import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Weekday } from 'models/CollectionSchedule';
import WeekdayTimesItem from './WeekdayTimesItem';
import CollectionFrequencyInput from './CollectionFrequencyInput';

export default function ScheduleCalendarSection() {

    const { t } = useTranslation([Namespace.GLOSSARY]);

    return (
        <Grid item xs={12} display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5">
                {t("collection_schedules", { ns: Namespace.GLOSSARY })}
            </Typography>

            <CollectionFrequencyInput />
            
            <Box
                sx={{
                    overflowX: 'auto', // Enable horizontal scrolling
                    '&::-webkit-scrollbar': {
                        height: '0.4em',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                        borderRadius: '4px',
                    },
                }}
            >
                <Stack 
                    direction="row"
                    sx={{ flexWrap: 'nowrap' }}  
                    >
                    {Object.values(Weekday).map((weekday, index) => (
                        <React.Fragment key={weekday}> {/* Assign a key for each item */}
                            {index > 0 && (<Divider orientation="vertical" flexItem />)}
                            <WeekdayTimesItem
                                weekday={weekday}
                                />
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>
        </Grid>
    );
}