import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';

type AnnotatedSelectProps = {
    parentLoading: boolean;
}

export enum VERIFIED_BATCHES {
    SHOW_BOTH = "0",
    HIDE_ANNOTATED = "1",
    ONLY_ANNOTATED = "2",
};

function AnnotatedSelect({ parentLoading }: AnnotatedSelectProps) {
    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const showAnnotated = searchParams.get("annotated");

    const handleAnnotatedSelected = (showAnnotated: string) => {
        searchParams.set("annotated", showAnnotated);
        setSearchParams(searchParams);
    };

    const options: Option[] = Object.values(VERIFIED_BATCHES).map(value => ({
        label: t(`manage_batches.show_annotated.${value}`, { ns: Namespace.FORMS }),
        value: value,
    }));

    return (
        <DropdownMenu
            id="annotated-select-menu"
            title={t(`manage_batches.show_annotated.title`, { ns: Namespace.FORMS })}
            loading={loading}
            values={options}
            defaultValue={showAnnotated || VERIFIED_BATCHES.SHOW_BOTH}
            startIcon={<HowToRegRoundedIcon />}
            onChange={(value) => handleAnnotatedSelected(value)}
        />
    );
}

export default AnnotatedSelect;