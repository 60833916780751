import { Box, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import PlacesSearchBar from './PlacesSearchBar';

type PlacesListDrawerHeaderProps = {
    showForMissions: boolean;
}

export default function PlacesListDrawerHeader({ showForMissions }: PlacesListDrawerHeaderProps) {

    const { t } = useTranslation([Namespace.COLLECTIONS, Namespace.GLOSSARY, Namespace.MISSIONS]);

    const totalBatchesCount = useAppSelector(state => state.batches.sortingMap.totalBatchesCount);

    const addressesSelectedCount = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.length);

    return (
        <Box
            bgcolor={theme => theme.palette.secondary.main}
            px={2}
            py={1.5}
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Box>
                <Typography
                    variant='body1'
                    color="#ffffff"
                    fontWeight="bold"
                >
                    {showForMissions ? t("addresses with most errors", { ns: Namespace.MISSIONS }) : t("bins_addresses", { ns: Namespace.GLOSSARY })}
                </Typography>
                <Typography
                    variant='body2'
                    color="#ffffff"
                >
                    {showForMissions ?  t("selected", { ns: Namespace.MISSIONS, count: addressesSelectedCount }) : t("batches_count", { ns: Namespace.COLLECTIONS, count: totalBatchesCount })}
                </Typography>
            </Box>
            <PlacesSearchBar />
        </Box>
    );
}