import { Box, ButtonBase, darken, useTheme } from "@mui/material";
import { getZIndex } from "components/Map/SortingMap/AddressMarker";
import { DangerLevel } from "constants/stats";
import { DANGER_COLORS } from "helpers/draw";
import { useAppSelector } from "store/hooks";

const MARKER_SIZE = 12;
const SELECTED_PLACE_MARKER_SIZE = 16;
const SELECTED_MARKER_SIZE = 20;

type BatchMarkerProps = {
    lat: number; // required by google-map-react parent component
    lng: number; // required by google-map-react parent component
    batchID: string;
    addressKey: string;
    dangerLevel: DangerLevel;
    selected?: boolean;
    onClick: (batchID: string) => void;
}

export default function BatchMarker({ selected, batchID, addressKey, dangerLevel, onClick, }: BatchMarkerProps) {

    const hovered = useAppSelector(state => state.batches.list.hoveredBatchID === batchID);
    const placeSelected = useAppSelector(state => addressKey !== undefined && state.batches.places.selectedPlace?.addressKey === addressKey);
    const emphased = selected || hovered;

    const theme = useTheme();

    const markerColor = placeSelected ? theme.palette.info.main : DANGER_COLORS[dangerLevel]; 

    const markerSize = emphased ? SELECTED_MARKER_SIZE : placeSelected ? SELECTED_PLACE_MARKER_SIZE : MARKER_SIZE;

    return (
        <ButtonBase
            onClick={() => { onClick(batchID); }}
        >
            <Box
                width={markerSize}
                height={markerSize}
                mt={`${-markerSize / 2}px`}
                ml={`${-markerSize / 2}px`}
                borderRadius="50%"
                bgcolor={markerColor}
                border={`1px solid ${darken(markerColor, .2)}`}
                boxShadow={1}
                zIndex={emphased ? 99 : getZIndex(dangerLevel)}
            />
        </ButtonBase>
    )
}