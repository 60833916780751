import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext } from "store/store";
import { LESS_COMMON_ERRORS, MOST_COMMON_TRASH_TYPES, TrashType } from "constants/trash";
import { ObjectValueType } from "constants/types";

/** Local storage item where validation shortcuts preferences will be saved. */
export const VALIDATION_PREFERENCES_STORAGE_KEY = "preferences.validation";

export enum Shortcut {
    GO_TO_PREVIOUS_BATCH = "goToPreviousBatch",
    GO_TO_NEXT_BATCH = "goToNextBatch",
    SAVE_AND_GO_TO_NEXT_BATCH = "saveAndGoToNextBatch",
    SAVE = "save",
    HIDE_BATCH = "hideBatch",
    HIDE_BOX = "hideBox",
    DELETE_BOX = "deleteBox",
    EXIT_DRAWING_MODE = "exitDrawingMode",
    /** Select the last class that the user was drawing to draw the next box. */
    REDRAW_LAST_CLASS = "redrawLastClass",
};

/** List of all the available options that can be bound to a key. */
export const VALIDATION_SHORTCUTS_LIST = {
    navigation: [
        Shortcut.GO_TO_PREVIOUS_BATCH,
        Shortcut.GO_TO_NEXT_BATCH,
        Shortcut.SAVE_AND_GO_TO_NEXT_BATCH,
        Shortcut.SAVE,   
        Shortcut.HIDE_BATCH,     
    ],
    other_actions: [
        Shortcut.HIDE_BOX,
        Shortcut.DELETE_BOX,
        Shortcut.EXIT_DRAWING_MODE,
        Shortcut.REDRAW_LAST_CLASS,
    ],
    draw_boxes: [
        ...MOST_COMMON_TRASH_TYPES,
        ...LESS_COMMON_ERRORS,
    ]
};

export type ShortcutKey = ObjectValueType<typeof VALIDATION_SHORTCUTS_LIST>[0];

export type ShortcutsPreferences = { [shortcut in ShortcutKey]?: string };

const DEFAULT_SHORTCUTS: ShortcutsPreferences = {
    [Shortcut.GO_TO_PREVIOUS_BATCH]: "ArrowLeft",
    [Shortcut.GO_TO_NEXT_BATCH]: "ArrowRight",
    [Shortcut.SAVE_AND_GO_TO_NEXT_BATCH]: " ",
    [Shortcut.SAVE]: "s",
    [Shortcut.HIDE_BATCH]: "g",
    [Shortcut.HIDE_BOX]: "h",
    [Shortcut.DELETE_BOX]: "Backspace",
    [Shortcut.EXIT_DRAWING_MODE]: "Escape",
    [Shortcut.REDRAW_LAST_CLASS]: "r",
    [TrashType.GARBAGE_BAG]: "1",
    [TrashType.OTHER]: "2",
    [TrashType.TEXTILE]: "3",
    [TrashType.BULKY]: "4",
    [TrashType.RECYCLE_WASTE]: "5",
    [TrashType.BIG_CARDBOARD]: "6",
};

type ValidationShortcutsContext = LoadableContext & typeof DEFAULT_SHORTCUTS & {
    dialogOpen: boolean;
};

const savedPreferences = localStorage.getItem(VALIDATION_PREFERENCES_STORAGE_KEY);

const initialState: ValidationShortcutsContext = {
    loading: false,
    error: null,
    dialogOpen: false,
    ...(savedPreferences ? JSON.parse(savedPreferences) : DEFAULT_SHORTCUTS),
};


export const validationShortcutsSlice = createSlice({
    name: 'validation_shortcuts',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        /** Show or hide the dialog to edit the shortcuts preferences. */
        toggleDialog: (state, { payload: open }: PayloadAction<boolean>) => {
            state.dialogOpen = open;
        },
        /** Set the full list of shortcuts (for example when first loaded from user's preferences). */
        setAll: (state, { payload: shortcuts }: PayloadAction<ShortcutsPreferences>) => {
            state.loading = false;
            Object.assign(state, shortcuts);
        },
        /** Update a single shortcut. */
        updateOne: (state, { payload: { shortcut, key } }: PayloadAction<{ shortcut: ShortcutKey, key: string }>) => {
            if (Object.values(state).includes(key)) { // key already assigned to another shortcut
                state.error = "already_assigned";
            }
            else {
                state.error = null;
                state[shortcut] = key;
            }
        },
        /** Delete a single shortcut. */
        removeOne: (state, { payload: shortcut }: PayloadAction<ShortcutKey>) => {
            state.error = null;
            delete state[shortcut];
        },
        setError: (state, { payload }: PayloadAction<string | null>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const ValidationShortcutsActions = validationShortcutsSlice.actions;

const ValidationShortcutsReducer = validationShortcutsSlice.reducer;

export default ValidationShortcutsReducer;