import { Button } from "@mui/material";
import ActionButton from "components/_include/Buttons/ActionButton";
import { Namespace } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import AddScheduleDialog from "./AddScheduleDialog";

type ButtonProps = {
    completed: boolean;
}

export default function AddScheduleButton(props: ButtonProps) {

    const { completed } = props;

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const { t } = useTranslation([Namespace.ACTIONS]);

    const ButtonComponent = completed ? Button : ActionButton;

    return (
        <>
            <ButtonComponent
                color="primary"
                fullWidth
                onClick={() => setAddDialogOpen(true)}
                variant={completed ? "outlined" : undefined}
            >
                {t(completed ? "add_another" : "add", { ns: Namespace.ACTIONS })}
            </ButtonComponent>

            <AddScheduleDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
            />
        </>
    );
}