import Area, { AreaDbData, NewAreaData, OsmPolygon } from "models/Area";
import { AppDispatch } from "store/store";
import { QueryFilter } from "constants/types";
import { AreasActions } from "store/reducers/areas/list";
import { createDocument, getDocumentReference, listDocs } from "helpers/db";
import { DbCollection } from "constants/db";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { QueryDocumentSnapshot, DocumentData, DocumentSnapshot, updateDoc, Timestamp } from "firebase/firestore";
import { SelectedAreaActions } from "store/reducers/areas/selected";
import { AreasMapActions } from "store/reducers/areas/map";
import { getPointsBoundsAndCenter } from "helpers/geo";
import osm2geo from 'osmtogeojson';
import { Namespace } from "locales/translations";
import { OsmAreasActions } from "store/reducers/areas/osm";

function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Area;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Area | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as AreaDbData;

    const partnerRef = dbDoc.ref.parent.parent;
    if (!partnerRef) return null;

    const areaData: Area = {
        ...data,
        ID: dbDoc.id,
        partnerID: partnerRef.id,
        createdAt: data.createdAt.toMillis(),
    }
    return areaData;
}

const create = (partnerID: string, data: NewAreaData) => async (dispatch: AppDispatch) => {
    dispatch(SelectedAreaActions.startLoading());

    const areaData = {
        ...data,
        createdAt: Timestamp.now(),
        processing: true, // Cloud function will set to false when batches have been linked
    };

    const areasPath = [DbCollection.PARTNERS, partnerID, DbCollection.AREAS];

    try {
        const { ID } = await createDocument(areasPath, areaData);

        const area: Area = {
            ...areaData,
            ID: ID,
            partnerID: partnerID,
            createdAt: areaData.createdAt.toMillis(),
        }

        dispatch(AreasActions.addItem(area));
        dispatch(SelectedAreaActions.selectArea({ area, editing: false }));

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "areas.create.success",
            context: { ns: Namespace.SNACKS, area: area.name },
        }))

        return area;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to create area", error);
        dispatch(showError(error.message));
        dispatch(SelectedAreaActions.setError(error.message));
        return null;
    }
};

const list = (partnerID: string, filters: QueryFilter<AreaDbData>[]) => async (dispatch: AppDispatch) => {
    dispatch(AreasActions.startLoadingList());

    const areaPath = [DbCollection.PARTNERS, partnerID, DbCollection.AREAS];

    try {
        const areasDocs = await listDocs(areaPath, filters);
        const areas = areasDocs.map(doc => fromDbDoc(doc));
        dispatch(AreasActions.setList(areas));

        return areas;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load areas", error);
        dispatch(showError(error.message));
        dispatch(AreasActions.setError(error.message));
        return [];
    }
};

const select = (area: Area) => (dispatch: AppDispatch) => {
    dispatch(SelectedAreaActions.selectArea({ area, editing: false }));

    // calculate polygon center and bounds
    dispatch(AreasMapActions.setMapBounds(getPointsBoundsAndCenter(area.path)));
};

const update = (area: Area, data: Partial<AreaDbData>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedAreaActions.startLoading());

    const parentPath = `${DbCollection.PARTNERS}/${area.partnerID}`;
    const docRef = getDocumentReference(area.ID, DbCollection.AREAS, parentPath);
    
    try {
        await updateDoc(docRef, data);

        const newData = {
            ...data,
            createdAt: data.createdAt?.toMillis() ?? area.createdAt,
        };

        dispatch(AreasActions.updateItem({
            areaID: area.ID,
            data: newData,
        }));
        dispatch(SelectedAreaActions.update(newData));

        // show success snackbar
        dispatch(showTranslatedMessage({
            messageKey: "areas.update.success",
            variant: "success",
            context: { ns: Namespace.SNACKS, area: area.name },
        }));

        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to update area", error);
        dispatch(showError(error.message));
        dispatch(SelectedAreaActions.setError(error.message));
        return false;
    }
};

/**
 * Use OpenStreetMap's Overpass API to list all entries matching the string passed as parameter.
 */
const lookupOSMArea = (areaName: string) => async (dispatch: AppDispatch) => {
    dispatch(OsmAreasActions.startLoadingList(areaName));

    // lookup all OSM features matching name
    // note: we limit the amount of data transfered to 1MB (= 1048576 Bytes in binary)
    const overpassQuery = `[out:json][maxsize:1048576];relation["name"~"^${areaName}", i];out body;>;out skel qt;`;

    return fetch(`https://overpass-api.de/api/interpreter?data=${encodeURI(overpassQuery)}`)
        .then(res => res.json())
        .then((osmData) => {
            const geojsonData = osm2geo(osmData);
            let areasResults: OsmPolygon[] = [];
            for (let feature of geojsonData.features) { // for each matching OSM entry
                if (feature.geometry.type === "Polygon" && feature.properties && feature.id && feature.properties.postal_code) { 
                    // we are only interested in Polygons with postal codes
                    areasResults.push({
                        id: feature.id.toString(),
                        name: feature.properties.name,
                        postcode: feature.properties.postal_code,
                        // note: OSM points use format [lng, lat]
                        path: feature.geometry.coordinates[0], 
                    });
                }
            }

            dispatch(OsmAreasActions.setList(areasResults));
            
            return areasResults;
        })
        .catch((e: Error) => {
            console.error("failed loading OSM features", e);
            dispatch(showError(e.message));
            dispatch(OsmAreasActions.setError(e.message));
            return [];
        });
};

const AreasMethods = {
    create,
    list,
    select,
    update,
    lookupOSMArea,
};

export default AreasMethods;