import { Grid, PaperProps } from '@mui/material';
import AddressesWithMostErrors from "components/_include/Charts/AddressesWithMostErrors/AddressesWithMostErrors";
import { useSpacing } from 'constants/theme';
import { PositionedNamedErrorsByAddress } from "constants/types";
import { UNNAMED_ROAD } from "helpers/geo";
import { Namespace } from "locales/translations";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBatchKey } from 'store/reducers/batches/places';
import { SortingMapActions, } from "store/reducers/batches/sorting_map";
import WorstAddressesMapWrapper from '../WorstAddressesMap/WorstAddressesMapWrapper';

type WorstAddressesSectionProps = {
    chartsHeight: number;
    PaperComponent: (props: PaperProps) => JSX.Element;
}

const NUMBER_DISPLAYED = 10;

function WorstAddressesSection({ chartsHeight, PaperComponent }: WorstAddressesSectionProps) {

    const { t } = useTranslation([Namespace.CHARTS]);

    const placesStats = useAppSelector(state => state.stats.data?.addresses ?? []);

    const addresses = useMemo(() => {
        return placesStats
            .map(point => {
                const addressKey = getBatchKey(point);
                const addressData: PositionedNamedErrorsByAddress = {
                    addressKey: addressKey,
                    address: point.address,
                    errors: point.errors,
                    errorsCount: point.errorsCount, 
                    lat: point.position?.latitude,
                    lng: point.position?.longitude,
                };
                return addressData;
            })
            .filter(p => p.address.street !== UNNAMED_ROAD)
            .slice(0, NUMBER_DISPLAYED);
    }, [placesStats]);

    const height = useSpacing(chartsHeight);

    const dispatch = useAppDispatch();

    const handleMouseEnter = useCallback((placeID: string) => {
        dispatch(SortingMapActions.hoverAddressKey(placeID));
    }, [dispatch]);

    const handleMouseLeave = useCallback((placeID: string) => {
        dispatch(SortingMapActions.leavePlaceID(placeID));
    }, [dispatch]);

    const handleAddressClick = useCallback((placeID: string) => {
        dispatch(SortingMapActions.selectAddressKey(placeID));
    }, [dispatch]);

    return (
        <>
            <Grid
                item
                md={12}
                lg={6}
            >
                <PaperComponent>
                    <AddressesWithMostErrors
                        title={t("addresses_with_most_errors.top_ten_title", { ns: Namespace.CHARTS })}
                        data={addresses}
                        height={(theme) => theme.spacing(chartsHeight)}
                        onAddressClick={handleAddressClick}
                        onAddressHover={(placeID, enter) => {
                            if (enter) handleMouseEnter(placeID);
                            else handleMouseLeave(placeID);
                        }}
                    />
                </PaperComponent>
            </Grid>

            <Grid
                item
                md={12}
                lg={6}
            >
                <PaperComponent>
                    <WorstAddressesMapWrapper 
                        points={addresses}
                        height={height}  
                        />
                </PaperComponent>
            </Grid>
        </>
    );
}

export default WorstAddressesSection;