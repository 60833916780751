import { Grid } from '@mui/material';
import TruckSelect from 'components/_include/Filters/TruckSelect';
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DatesRangeSelect from 'components/_include/Filters/DatesRangeSelect';
import { DEFAULT_FILTERS_VALUES } from 'constants/stats';
import { getDatesFromDateSelectType } from 'helpers/dates';
import DownloadReportButton from './DownloadReport/DownloadReportButton';
import AreaSelect from 'components/_include/Filters/AreaSelect';
import StatsMethods from 'controllers/stats';

type FiltersProps = {

}

function StatisticsFilters(props: FiltersProps) {
    const selectedTruck = useAppSelector(state => state.trucks.selected.data);
    const loading = useAppSelector(state => state.trucks.list.loading || state.collections.list.loading);

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const areaID = searchParams.get("areaID");

    useEffect(() => {
        // set default filters values
        let needUpdate = false;
        if (!searchParams.has("startDate") || !searchParams.has("endDate")) {
            needUpdate = true;
            const defaultDateSelect = DEFAULT_FILTERS_VALUES.dates;
            const { start, end } = getDatesFromDateSelectType(defaultDateSelect);
            if (start) searchParams.set("startDate", start.format("YYYY-MM-DD"));
            if (end) searchParams.set("endDate", end.format("YYYY-MM-DD"));
        }

        if (needUpdate) {
            setSearchParams(searchParams);
        }
    }, []);

    // reload collections when filters change
    useEffect(() => {
        if (!selectedTruck || !startDate || !endDate) return;

        // dates filter
        let start = moment(startDate).startOf("day");
        let end = moment(endDate).endOf("day");

        const { partnerID, ID: truckID } = selectedTruck;
        dispatch(StatsMethods.getStats(partnerID, truckID, start, end, areaID ?? undefined));
    }, [selectedTruck?.ID, startDate, endDate, areaID]);

    return (
        <Grid
            container
            display="flex"
            alignItems="center"
        >
            <Grid
                item
                xs
            >
                <Grid
                    container
                    alignItems="center"
                    display="flex"
                    columnSpacing={1}
                >
                    <Grid item>
                        <DatesRangeSelect
                            parentLoading={loading}
                        />
                    </Grid>
                    <Grid item>
                        <AreaSelect
                            parentLoading={loading}
                        />
                    </Grid>
                    <Grid item>
                        <TruckSelect
                            needDefault
                            parentLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs="auto"
            >
                <DownloadReportButton />
            </Grid>
        </Grid >
    );
}

export default StatisticsFilters;