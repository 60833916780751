import { ChevronLeft } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AppIcon, useRoute } from "constants/pages";
import { getGradient } from "helpers/draw";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useIsAdmin, usePartnerID } from "store/hooks";
import { selectPartnersIds } from "store/reducers/partners/list";
import PartnerSelect from "./Filters/PartnerSelect";
import LanguageSelect from "./LanguageSelect";

type PageTitleProps = {
    title?: string;
    Icon?: AppIcon;
    hidePartnerSelect?: boolean;
    canGoBack?: boolean;
}

export default function PageTitle({ title, Icon, hidePartnerSelect, canGoBack }: PageTitleProps) {

    const { t } = useTranslation([Namespace.TITLES]);

    const location = useLocation();
    const route = useRoute(location.pathname, true);

    const label = title || t(route.path, { ns: Namespace.TITLES });
    const TitleIcon = Icon || route.Icon;

    const isAdmin = useIsAdmin();
    const hasSeveralPartners = useAppSelector(state => selectPartnersIds(state).length > 1);
    const navigate = useNavigate();
    const partnerID = usePartnerID();

    const handleBackClick = () => {
        navigate(`/missions?partnerID=${partnerID}`);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <Typography variant="h1" display="flex">
                {canGoBack && (
                    <IconButton onClick={handleBackClick} sx={{ ml: -1 }}>
                        <ChevronLeft />
                    </IconButton>
                )}
                {TitleIcon && (
                    <Box
                        width={theme => theme.spacing(6)}
                        height={theme => theme.spacing(6)}
                        sx={{
                            background: theme => getGradient("vertical", theme.palette.info.main, theme.palette.secondary.main),
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius={2}
                        boxShadow={4}
                        mr={2}
                        mt={-.5}
                        ml={1}
                    >
                        <TitleIcon
                            sx={{
                                fontSize: theme => theme.spacing(3),
                                color: "#ffffff",
                            }}
                        />
                    </Box>
                )}
                {label}
            </Typography>

            <Grid container spacing={2} width="auto">
                {(isAdmin || hasSeveralPartners) && !hidePartnerSelect && (
                    <Grid item>
                        <PartnerSelect parentLoading={false} />
                    </Grid>
                )}

                <Grid item>
                    <LanguageSelect />
                </Grid>
            </Grid>
        </Box>
    )
}