import { getAuth } from 'firebase/auth';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { UserRole } from 'models/User';
import { DependencyList, EffectCallback, useEffect } from 'react';

/** Get a dispatch function to modify the Redux store. */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Access nested values from the Redux store. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Get the ID of the currently selected partner. */
export const usePartnerID = () => useAppSelector(state => getAuth().currentUser === null ? undefined : state.partners.selected.data?.ID || state.user.partnerID);

/** Get the status of the currently selected partner. */
export const usePartnerStatus = () => useAppSelector(state => state.partners.selected.data?.status);

/** 
 * True if current user is a Ficha admin and visualizing the dashboard as such, 
 * false if not a Ficha admin or seeing the data as a user.
 */
export const useIsAdmin = () => useAppSelector(state => state.user.role === UserRole.ADMIN && !state.user.viewAsPartner);

/**
 * True if the current user has been added to at least 1 partner, false if none.
 * Returns undefined while user hasn't been loaded.
 */
export const useHasPartner = () => useAppSelector(state => {
    if (state.user.email === null) return undefined; // user not loaded yet
    if (state.user.role === UserRole.ADMIN) return true; // admins can access all partners
    return Object.keys(state.user.partners ?? {}).length > 0;
});

/** 
 * True if current user has permission to modify data on a given partner (Ficha or partner admin).
 */
export const useCanWrite = (partnerID?: string) => useAppSelector(state => {
    // can write if Ficha admin
    if (state.user.role == UserRole.ADMIN) return true;

    // can write if admin for this given partner
    if (partnerID && state.user.partners && [UserRole.PARTNER_ADMIN, UserRole.PARTNER].includes(state.user.partners[partnerID])) return true;

    return false;
});

/**
 * Hook that will wait to perform a useEffect until the state hasn't updated for the duration of the delay.
 * @param effect The callback to execute.
 * @param deps Array of depencies for the effect.
 * @param delay Delay to wait without any update to the state in milliseconds.
 */
export const useDebouncedEffect = (effect: EffectCallback, deps: DependencyList, delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
}