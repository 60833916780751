import { PartnerData, PartnerStatus } from "models/Partner";
import _ from "lodash";
import { Query } from "firebase/firestore";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { useAppSelector } from "store/hooks";

const partnersAdapter = createEntityAdapter<PartnerData>({
    selectId: partner => partner.ID,
    sortComparer: (partner1, partner2) => {
        // put archived partners at then e
        if (partner1.status !== partner2.status) {
            if (partner1.status === PartnerStatus.ARCHIVED) return 1;
            if (partner2.status === PartnerStatus.ARCHIVED) return -1;
        }
        return partner1.name.localeCompare(partner2.name)
    }
});

type PartnersContext = LoadableContext & {

};

const initialState: PartnersContext = {
    loading: false,
    error: null,
};


export const partnersSlice = createSlice({
    name: 'partners',
    initialState: partnersAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: partners }: PayloadAction<PartnerData[]>) => {
            state.loading = false;
            partnersAdapter.setAll(state, partners);
        },
        addItem: (state, { payload: partner }: PayloadAction<PartnerData>) => {
            state.loading = false;
            partnersAdapter.addOne(state, partner);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<PartnerData>}>) => {
            partnersAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const PartnersActions = partnersSlice.actions;

export const {
    selectAll: selectAllPartners,
    selectById: selectPartnerById,
    selectIds: selectPartnersIds
} = partnersAdapter.getSelectors((state: RootState) => state.partners.list);

const PartnersReducer = partnersSlice.reducer;

export default PartnersReducer;