import { Box, Container, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import SortingMapPage from 'components/Map/SortingMapPage';
import PageTitle from 'components/_include/PageTitle';
import SectionLoader from 'components/_include/SectionLoader';
import CollaboratorsController from 'controllers/collaborators';
import MissionsMethods from 'controllers/mission';
import { usePrevious } from 'helpers/refs';
import { Namespace } from 'locales/translations';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import AmbassadorsList from './AmbassadorsList/AmbassadorsList';
import MissionDetailHeader from './MissionDetailheader';
import SelectedAddressesList from './SelectedAddressesList/SelectedAddressesList';

type MissionDetailPageProps = {

};

/** Size in spacing units (8px) covered by the page title and mission details on top of the map. */
export const HEADER_HEIGHT_SPACING = 36;

const MissionDetailPage: React.FC<MissionDetailPageProps> = ({ }) => {
    const { t } = useTranslation([Namespace.MISSIONS, Namespace.TITLES]);

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { missionID } = useParams();

    const loading = useAppSelector(state => state.mission.selected.loading);
    const wasLoading = usePrevious(loading);

    const mission = useAppSelector(state => state.mission.selected.selectedMission);
    const missionName = mission?.name;

    useEffect(() => {
        if (partnerID && missionID) {
            dispatch(CollaboratorsController.list(partnerID));
            dispatch(MissionsMethods.retrieve(partnerID, missionID));
        }
    }, [partnerID, dispatch, missionID]);

    return (
        <Container>
            {!wasLoading || mission ? (
                <Box padding={2}>
                    {!mission || loading ? (
                        <SectionLoader />
                    ) : (
                        <>
                            <PageTitle title={t("edit_mission_name", { ns: Namespace.TITLES, name: missionName })} hidePartnerSelect={true} canGoBack={true} />
                            <MissionDetailHeader />
                            <Box display="flex" flexDirection={'row'} alignItems="flex-start" marginTop={5}>
                                <SortingMapPage showForMissions={true} />
                                {!isSmallScreen && (
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{ mx: 2 }}
                                    />
                                )}
                                <Box display="flex" flexDirection={'column'} width={320}>
                                    <AmbassadorsList />
                                    <Divider sx={{ my: 1 }} />
                                    <SelectedAddressesList />
                                </Box>
                                {isSmallScreen && (
                                    <Divider sx={{ mt: 2 }} />
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            ) : ( // mission not found
                <Box>
                    <PageTitle title={t("edit_mission", { ns: Namespace.TITLES })} hidePartnerSelect={true} canGoBack={true} />
                    <Typography>{t("mission not found", { ns: Namespace.MISSIONS })}</Typography>
                </Box>
            )}
        </Container >
    );
};

export default MissionDetailPage;
