import { Container, Box } from '@mui/material';
import { CONTACTS } from 'constants/contacts';
import ContactsCompanySection from './ContactsCompanySection';
import PageTitle from 'components/_include/PageTitle';
import CollaboratorsSection from './Collaborators/CollaboratorsSection';

interface ContactsPageProps {

}

function ContactPage(props: ContactsPageProps) {

    return (
        <Container>
            <PageTitle />

            <Box
                display="flex"
                flexDirection="column"
                gap={6}
            >
                {Object.entries(CONTACTS).map(([company, contacts]) => (
                    <ContactsCompanySection
                        key={company}
                        company={company}
                        contacts={contacts}
                    />
                ))}

                <CollaboratorsSection />
            </Box>
        </Container>
    );
}

export default ContactPage;