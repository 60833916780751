import { List } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCollectionsSchedulesIDs } from 'store/reducers/collections_schedules/list';
import OnboardingSetupCard from '../OnboardingSetupCard';
import OnboardingSetupDisabled from '../OnboardingSetupDisabled';
import DeleteScheduleDialog from './DeleteScheduleDialog';
import EditScheduleDialog from './EditScheduleDialog';
import ScheduleItem from './ScheduleItem';
import { SelectedCollectionScheduleActions } from 'store/reducers/collections_schedules/selected';
import CollectionSchedule from 'models/CollectionSchedule';

type ScheduleSetupCardProps = {
}

type SelectedCollectionScheduleState = {
    collectionScheduleID: string | null;
    action: "edit" | "delete" | null;
}

function ScheduleSetupCard(props: ScheduleSetupCardProps) {
    const { } = props;

    const collectionSchedulesIDs = useAppSelector(state => selectCollectionsSchedulesIDs(state));
    // const dispatch = useAppDispatch();

    const disabled = useAppSelector(state => state.routes.ids.length === 0)

    const Wrapper = !disabled ? OnboardingSetupCard : OnboardingSetupDisabled;

    const dispatch = useAppDispatch();

    const [selectedCollectionScheduleState, setSelectedCollectionScheduleState] = useState<SelectedCollectionScheduleState>({ collectionScheduleID: null, action: null });

    const onEditClicked = useCallback((collectionSchedule: CollectionSchedule) => {
        dispatch(SelectedCollectionScheduleActions.selectCollectionSchedule(collectionSchedule));
    }, [dispatch, collectionSchedulesIDs]);

    const onDeleteClicked = useCallback((collectionSchedule: CollectionSchedule) => {
        setSelectedCollectionScheduleState({ 
            collectionScheduleID: collectionSchedule.ID,
            action: "delete",
        });
    }, [setSelectedCollectionScheduleState]);

    return (
        <Wrapper
            type="schedule"
            completed={collectionSchedulesIDs.length > 0}
        >
            <List>
                {collectionSchedulesIDs.map((collectionScheduleID) => (
                    <ScheduleItem
                        key={collectionScheduleID}
                        collectionScheduleID={collectionScheduleID}
                        onEditClicked={onEditClicked}
                        onDeleteClicked={onDeleteClicked}
                    />
                ))}
            </List>

            <EditScheduleDialog 
                onClose={() => dispatch(SelectedCollectionScheduleActions.selectCollectionSchedule(null))}
                />
            <DeleteScheduleDialog 
                scheduleID={selectedCollectionScheduleState.action === "delete" ? selectedCollectionScheduleState.collectionScheduleID : null} 
                onClose={() => setSelectedCollectionScheduleState({ collectionScheduleID: null, action: null, })}
                />
        </Wrapper>
    );
}

export default ScheduleSetupCard;