import { Box, lighten } from "@mui/material";

type MapDotMarkerProps = {
    color: string;
    size: number | string;
    zIndex: number;
    selected?: boolean;
    hovered?: boolean;
    inline?: boolean;
}

export default function MapDotMarker({ color, size, zIndex, selected, hovered, inline }: MapDotMarkerProps) {
    return (
        <Box
            width={size}
            height={size}
            borderRadius="50%"
            border={(theme) => `${selected ? 2 : 1}px solid ${hovered ? "white" : lighten(color,.5)}`}
            bgcolor={color}
            boxShadow={1}
            zIndex={zIndex}
            {...(!inline && {
                mt: `${-size / 2}px`,
                ml: `${-size / 2}px`,
            })}
        />
    )
}