import { Button, Theme, ButtonProps, SimplePaletteColorOptions, } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { LabelButtonProps, OnClickAction } from './ActionButton';

export type LabelActionButtonProps = {
    id?: string;
    children?: any;
    color: ButtonProps["color"];
    disabled?: boolean;
    loading?: boolean;
    fullWidth?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    href?: string;
    buttonStyle: SxProps<Theme>;
    size?: "normal" | "compact";
} & (OnClickAction | LabelButtonProps);

function LabelActionButton(props: LabelActionButtonProps) {

    const { id, children, color, disabled, loading, fullWidth, startIcon, endIcon, href, buttonStyle, size, ...other } = props;

    const buttonDisabled = disabled || loading;

    return (
        <Button
            id={id}
            variant="contained"
            disabled={buttonDisabled}
            href={href}
            startIcon={startIcon}
            endIcon={endIcon}
            fullWidth={fullWidth}
            disableRipple
            sx={{
                pt: size === 'compact' ? 0.8 : 1.7,
                pb: size === 'compact' ? 0.5 : 1.5,
                px: size === 'compact' ? 3 : 4,
                ...buttonStyle,
            }}
            color={color}
            {...other}
        >
            {children}
        </Button>
    );
}

export default LabelActionButton;