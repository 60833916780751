import { LoadingButton } from "@mui/lab";
import TrucksMethods from "controllers/trucks";
import { Namespace } from "locales/translations";
import Truck from "models/Truck";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";

type ButtonProps = {
    disabled?: boolean;
    onSaved: () => void;
}

/**
 * Save button for the dialog to edit a Truck's details
 */
export default function SaveTruckSettingsButton({ disabled, onSaved }: ButtonProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);

    const partnerID = usePartnerID();
    const loading = useAppSelector(state => state.trucks.selected.loading);
    const truck = useAppSelector(state => state.trucks.selected.data);

    const exceptions = useAppSelector(state => state.trucks.selected.exceptionsForm);

    const dispatch = useAppDispatch();

    const submitForm = () => {
        if (partnerID && truck) {
            dispatch(TrucksMethods.partialUpdate(partnerID, truck.ID, {
                identificationNumber: truck.identificationNumber?.trim(),
                make: truck.make?.trim(),
                teltonikaSerialNumber: truck.teltonikaSerialNumber?.trim(),
                sensorNumber: truck.sensorNumber?.trim(),
                exceptions: exceptions.reduce((acc, [from, to, description]) => {
                    if (from && to) acc[from] = { to, description };
                    return acc;
                }, {} as Exclude<Truck["exceptions"], undefined>),
            }))
                .then(() => {
                    onSaved();
                });
        }
    };

    const formIsValid = truck?.identificationNumber && truck?.make;

    return (
        <LoadingButton
            color="success"
            sx={{
            }}
            onClick={submitForm}
            loading={loading}
            disabled={disabled || !formIsValid}
        >
            <span>{t("save", { ns: Namespace.ACTIONS })}</span>
        </LoadingButton>
    )
}