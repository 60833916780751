import { Box, CardContent, Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';
import WastesFormActions from './WastesFormActions/WastesFormActions';
import { useAppSelector } from 'store/hooks';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import LessCommonWastesDrawer from './LessCommonWastes/LessCommonWastesDrawer';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import LessCommonWastesList from './LessCommonWastes/LessCommonWastesList';
import MostCommonWastesDrawers from './MostCommonWastesDrawers';
import AutoVerifiedIcon from 'components/_include/Icons/AutoVerifiedIcon';
import ToggleValidationShortcutsDialogButton from 'components/ManageBatches/ShortcutsPreferences/ToggleValidationShortcutsDialogButton';

type WastesFormProps = {
    canEditResults?: boolean;
}


function WastesForm({ canEditResults, }: WastesFormProps) {

    const batchDate = useAppSelector(state => state.batches.list.selectedBatch?.timestamp);
    const batchVerified = useAppSelector(state => state.batches.list.selectedBatch?.verified);
    const batchAutoVerified = useAppSelector(state => state.batches.list.selectedBatch?.autoVerified);

    const { t } = useTranslation([Namespace.WASTES]);

    /** Collapsible drawer on validation form, simple list on "To annotate" */
    const LessCommonWasteWrapper = canEditResults ? LessCommonWastesDrawer : Fragment;

    const VerifiedIcon = batchAutoVerified ? AutoVerifiedIcon : HowToRegRoundedIcon;

    return (
        <CardContent
            sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
                alignItems: "center",
                gap: 2,
                maxWidth: 400,
            }}
        >
            <Box
                display="flex"
                gap={1.25}
                alignItems="center"
                alignSelf="stretch"
            >
                <Typography
                    fontWeight="bold"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {t("datetime_full", { ns: Namespace.DATES, datetime: batchDate })}
                </Typography>
                {batchVerified && (
                    <VerifiedIcon
                        color="success"
                        fontSize="large"
                        sx={{
                            filter: `drop-shadow(2px 2px 2px rgb(0, 0, 0, .2))`,
                        }}
                    />
                )}
            </Box>


            <WastesFormActions
                canEditResults={canEditResults}
            />

            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                gap={2}
                pt={1} // tiny bit of padding to not partially hide first inputs' placeholders
                sx={{
                    overflowX: "hidden",
                    overflowY: "scroll",
                    minWidth: theme => theme.spacing(40)
                }}
            >
                <MostCommonWastesDrawers
                    canEditResults={canEditResults}
                />

                <LessCommonWasteWrapper>
                    <LessCommonWastesList canEditResults={canEditResults} />
                </LessCommonWasteWrapper>

            </Box>

            <Box width="100%">
                <ToggleValidationShortcutsDialogButton />
            </Box>
        </CardContent>
    );
}

export default WastesForm;