import { ParsedToken } from "firebase/auth";
import { Locale } from "locales/translations";

/** 
 * List of possible roles for a user, 
 * managing their access throughout the Camia services. 
 */
export enum UserRole {
    /** Ficha admin with full rights. */
    ADMIN = "admin",
    /** @deprecated Single common account for a given partner. */
    PARTNER = "partner",
    /** Client with admin rights on the partner's account. */
    PARTNER_ADMIN = "partner_admin",
    /** Client with readonly rights on the partner's account. */
    PARTNER_MEMBER = "partner_member",
    /** Ambassador that can access to the partner's data. */
    AMBASSADOR = "ambassador",
};


export type FirebaseUserClaims = ParsedToken & UserClaims;


/** Object presenting the access level of a user for each partner it has access to. */
export type PartnersAccess = {
    [partnerID: string]: UserRole;
}

/** 
 * Extra information stored in the Firebase auth user's custom claims. 
 */
export type UserClaims = {
    /** Preferred language of the user. */
    locale: Locale;
    /** @legacy General role of the user. @todo Change to "isAdmin" boolean field. */
    role: UserRole;
    /** @deprecated Single ID of the partner the user has access to. */
    partnerID?: string;
    /** Access level of a user for each partner it has access to. */
    partners?: PartnersAccess;
}

export type UserData = {
    name: string;
    role: UserRole;
    partnerID: string;
    email?: string;
}