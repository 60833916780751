import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';

type DownloadLimitSelectProps = {
    value: number;
    onChange: (value: number) => void;
}

function DownloadLimitSelect(props: DownloadLimitSelectProps) {
    const { t } = useTranslation([Namespace.GLOSSARY]);
    const { value, onChange } = props;
    const  Loading_value = [50, 100, 200, 300, 500];
    return (
        <Box sx={{ minWidth: 170 }}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t("load_no_of_images", { ns: Namespace.GLOSSARY }).toString()}
</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                onChange={(event) => onChange(event.target.value as number)}
                label="Max Images Per trashType"
            >
                {Loading_value.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </Select>
        </FormControl>
        </Box>
    );
}

export default DownloadLimitSelect;
