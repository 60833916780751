import { BatchPoint, DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES } from "helpers/geo";
import { Bounds, Coords } from "google-map-react";
import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BatchesMapContextInterface {
    defaultCenter: Coords;
    defaultBounds: Bounds | undefined;
    defaultZoom: number;
    points: BatchPoint[];
};

const initialState: BatchesMapContextInterface = {
    defaultCenter: GRENOBLE_COORDINATES,
    defaultBounds: {
        nw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 },
        sw: { lat: 0, lng: 0 },
        se: { lat: 0, lng: 0 },
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
    points: [],
};

export const batchesMapSlice = createSlice({
    name: 'batchesMap',
    initialState: initialState,
    reducers: {
        removeMapPoints: (state) => {
            state.points = [];
        },
        setMapPoints: (state, { payload: { center, bounds, points } }: PayloadAction<{ center: Coords, bounds: Bounds, points: BatchPoint[] }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
            state.points = points;
        },
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: Coords, bounds: Bounds }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: Coords, zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
    },
});

export const BatchesMapActions = batchesMapSlice.actions;

const BatchesMapReducer = batchesMapSlice.reducer;

export default BatchesMapReducer;