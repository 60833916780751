import { Box, ButtonBase } from "@mui/material";
import { useEffect, useState } from "react";
import ScrollControl from "./ScrollControl";

type ControlsProps = {
    list: HTMLDivElement;
}

const SCROLL_SPEED = 16;

export default function CollectionsListControls({ list }: ControlsProps) {

    const scrollLeft = () => {
        list.scrollBy(-SCROLL_SPEED, 0);
    }

    const scrollRight = () => {
        list.scrollBy(SCROLL_SPEED, 0);
    }

    useEffect(() => {
        if (list) {
            return list.addEventListener("scroll", (e) => {
                setScroll(list.scrollLeft);
            });
        }
    }, [list]);

    const [scroll, setScroll] = useState(list.scrollLeft);

    return (
        <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            height={theme => theme.spacing(33)}
            sx={{
                pointerEvents: "none"
            }}
            >
            <ScrollControl direction="left" hide={scroll <= 0} onHover={scrollLeft} />
            <ScrollControl direction="right" hide={scroll > list.scrollWidth - list.offsetWidth} onHover={scrollRight} />
        </Box>
    )
}