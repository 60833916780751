import { ThemeProvider, } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Pages from './components/Pages';
import GlobalSnackbar from './components/_include/GlobalSnackbar';
import theme from './constants/theme';
import store from './store/store';

function App() {

  return (
    // <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Pages />
          <GlobalSnackbar />
        </ThemeProvider>
      </Provider>
    // </BrowserRouter>
  );
}


export default App;
