import GoogleMapReact from "google-map-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatAddress, getBoundsZoomLevel, getMapOptions } from "helpers/geo";
import AddressMarker from "./AddressMarker";
import { BatchesActions } from "store/reducers/batches/list";
import { SortingMapActions, selectAllSortingPoints } from "store/reducers/batches/sorting_map";
import { getBatchKey } from "store/reducers/batches/places";

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type SortingMapProps = {
    width: number | undefined;
    height: number | undefined;
}

export default function SortingMap(props: SortingMapProps) {

    const { width, height } = props;

    const defaultCenter = useAppSelector(state => state.batches.collectionMap.defaultCenter);
    const defaultBounds = useAppSelector(state => state.batches.collectionMap.defaultBounds);
    const defaultZoom = useAppSelector(state => state.batches.collectionMap.defaultZoom);

    const dispatch = useAppDispatch();

    // handle map
    const [zoom, setZoom] = useState(defaultZoom);
    useEffect(() => {
        setZoom(defaultZoom);
    }, [defaultZoom]);

    const points = useAppSelector(selectAllSortingPoints);

    const mapRef = useRef<GoogleMapReact>(null);

    useEffect(() => {
        if (defaultBounds && width && height) {
            const mapDims = {
                width: width,
                height: height,
            };
            let newZoom = getBoundsZoomLevel(defaultBounds, mapDims);

            setZoom(newZoom);
        }
    }, [defaultBounds]);

    const handleMarkerClick = useCallback((addressKey: string) => {
        dispatch(BatchesActions.unselectBatch());
        dispatch(SortingMapActions.selectAddressKey(addressKey));
    }, [dispatch]);

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={defaultCenter}
            zoom={zoom}
            options={getMapOptions}
            onChange={({ zoom: newZoom }) => {
                if (newZoom !== zoom) setZoom(newZoom);
            }}
        >
            {points.map((point) => {
                const [lat, lng] = point.geometry.coordinates;
                const {
                    address,
                    dangerLevel,
                } = point.properties;

            const addressKey =  getBatchKey(point.properties);  

                return (
                    <AddressMarker
                        key={addressKey}
                        addressKey={addressKey}
                        dangerLevel={dangerLevel}
                        address={formatAddress(address)}
                        lat={lat}
                        lng={lng}
                        onClick={handleMarkerClick}
                    />
                );
            })}
        </GoogleMapReact>
    );
}