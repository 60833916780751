import { Box } from "@mui/material";
import CollectionsController from "controllers/collections";
import SectionLoader from "components/_include/SectionLoader";
import { usePrevious } from "helpers/refs";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks"

type LoadMoreProps = {
    loadPage: number; 
}

export default function LoadMoreCollections({ loadPage }: LoadMoreProps) {
    const loading = useAppSelector(state => state.collections.list.loadingMore);

    const dispatch = useAppDispatch();

    const previousLoadPage = usePrevious(loadPage) ?? 1;

    useEffect(() => {
        if (!loading && loadPage > previousLoadPage) {
            // load 10 more collections
            dispatch(CollectionsController.loadMore());
        }
    }, [loading, loadPage, previousLoadPage, dispatch]);

    return (
        loading ? (
            <Box position="relative" height={theme => `calc(100% - ${theme.spacing(4)})`} width={theme => theme.spacing(10)}>
                <SectionLoader />
            </Box>
        ) : <></>
    )
}