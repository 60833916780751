import { Grid, GridProps } from '@mui/material';
import CollectionsSchedulesMethods from 'controllers/collections_schedules';
import RoutesController from 'controllers/routes';
import TrucksMethods from 'controllers/trucks';
import SectionLoader from 'components/_include/SectionLoader';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { usePartnerID } from 'store/hooks';
import RoutesSetupCard from './OnboardingSetupCard/Routes/RoutesSetupCard';
import ScheduleSetupCard from './OnboardingSetupCard/Schedule/ScheduleSetupCard';
import TrucksSetupCard from './OnboardingSetupCard/Trucks/TrucksSetupCard';

type OnboardingSetupCardsProps = {

}

const CardWrapper = (props: GridProps) => (
    <Grid
        {...props}
        item xs={12} sm={4}
        display="flex" flexDirection="column" alignItems="center" 
        maxHeight="100%"
    />
)

function OnboardingSetupCards(props: OnboardingSetupCardsProps) {
    const { } = props;

    const dispatch = useAppDispatch();

    const partnerID = usePartnerID();

    const [routesReady, setRoutesReady] = useState(false);
    const [trucksReady, setTrucksReady] = useState(false);
    const [schedulesReady, setSchedulesReady] = useState(false);

    useEffect(() => {
        if (partnerID) {
            dispatch(RoutesController.list(partnerID, [])).then(() => setRoutesReady(true));
            dispatch(TrucksMethods.list(partnerID, [])).then(() => setTrucksReady(true));
            dispatch(CollectionsSchedulesMethods.list(partnerID)).then(() => setSchedulesReady(true));
        }
    }, [partnerID]);

    return (
        <Grid container spacing={2} flexGrow={1} alignItems="stretch" position="relative">
            {(!routesReady || !trucksReady || !schedulesReady) ? (
                <SectionLoader />
            ) : (
                <>
                    <CardWrapper>
                        <TrucksSetupCard />
                    </CardWrapper>

                    <CardWrapper>
                        <RoutesSetupCard />
                    </CardWrapper>

                    <CardWrapper>
                        <ScheduleSetupCard />
                    </CardWrapper>
                </>
            )}
        </Grid>
    );
}

export default OnboardingSetupCards;