import { Box } from "@mui/material";
import Batch from "models/Batch";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import AutoVerifiedIcon from "components/_include/Icons/AutoVerifiedIcon";

type BadgeProps = Pick<Batch, "autoVerified">;

/**
 * Badge to display on a Batch item in validation list, indicating that a badge has been verified, 
 * either automatically (skipped human validation) or by human validator
 */
export default function BatchVerifiedBaddge({ autoVerified }: BadgeProps) {
    const Icon = autoVerified ? AutoVerifiedIcon : HowToRegRoundedIcon;

    return (
        <Box
            position="absolute"
            left={4}
            bottom={4}
            width={32}
            height={32}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            sx={{
                backgroundColor: "#ffffff",
            }}>
            <Icon
                color="success"
                sx={{
                    filter: `drop-shadow(2px 2px 2px rgb(0, 0, 0, .2))`,
                }}
            />
        </Box>
    )
}