import { GeoPoint } from "constants/types";
import { SortingError, TrashType } from "constants/trash";
import { BoundingBox, BoundingBoxDb } from "./BoundingBox";
import { CollectionType } from "./Collection";
import { NextQuery } from "constants/db";
import { Timestamp } from "firebase/firestore";
import BatchAIResult from "./BatchAIResult";
import { AddressComponents } from "./SavedAddress";

export type BatchResults = Partial<{
    [type in TrashType]: number;
}>;

export type HasErrorType = `has${Capitalize<TrashType>}`;

export type BatchErrors = {
    [key in HasErrorType]: boolean;
};

/** 
 * Indicate on which side of the truck the bin was raised as well as its size
 * (left and right have 2 wheels, large have 4, hand-lifted have none)
 */
export enum BinType {
    LEFT = "left",
    RIGHT = "right",
    LARGE = "large",
    HAND_LIFTED = "hand_lifted"
};

export type BinsCounts = Partial<Record<BinType, number>>;

export type BatchDbData = {
    /** Firestore ID of the collection this batch belongs to */
    collectionID: string;

    /** Firestore ID of the truck this batch was recorded on */
    truckID: string;

    /** Firestore ID of the address this batch was recorded at */
    addressID?: string | null;

    /** ID of the looked up entry from HERE. */
    hereID?: string;

    /** Formatted address this batch was recorded at */
    address?: Pick<AddressComponents, "city" | "postalCode" | "street" | "houseNumber">;

    /** List of user-defined areas that contain the position of this batch */
    areasIDs: string[];

    /** Timestamp this batch was recorded at */
    timestamp: Timestamp;

    /** Timestamp this batch was recorded at */
    position?: GeoPoint;

    /** List of bounding boxes for wastes present in this batch. */
    bboxes?: BoundingBoxDb[];

    /** Object presenting the number of instances for each class of waste present in this batch */
    results: BatchResults;

    /** 
     * Object presenting the surface of the masks for each class of waste present in this batch,
     * relatively to the area of the image (values between 0 and 1)
     */
    surfaces: BatchResults;

    /** Path to the image file for this batch, relative to the batches GCS bucket */
    imageURL: string;

    /** @deprecated Path to the video file for this batch, relative to the batches GCS bucket */
    videoURL?: string;

    /** Type of waste collected during this batch's collection (e.g. household waste, sortable waste...) */
    collectionType: CollectionType;
    display: boolean;

    /** True if the AI has processed this batch, false otherwise */
    processed: boolean;

    /** True if a human validator has verified this batch, false otherwise */
    verified: boolean;

    /** True if human validation was skipped. */
    autoVerified?: boolean;

    /** True if this batch was downloaded in order to be annotated */
    downloadedToAnnotate?: boolean;

    /** @deprecated True if this batch was downloaded in order to be used for augmentation */
    downloadedToAugment?: boolean;

    /** True if the results from the AI differ from the human-validated ones. */
    aiResultsOff?: boolean;

    /** Object presenting the count of each type of bins raised during this batch */
    binsCounts?: BinsCounts;

    /** RFID numbers of the bins raised just before capturing this batch, if equipped with chips */
    binsRFIDs?: string[];
} & Partial<BatchErrors>;

export type BatchUpdatableFields = Partial<Pick<BatchDbData, "results" | "surfaces" | "bboxes" | "display" | "downloadedToAnnotate" | "downloadedToAugment">>;

type Batch = Omit<BatchDbData, "timestamp" | "bboxes"> & {
    ID: string;
    partnerID: string;
    timestamp: number;
    bboxes?: BoundingBox[];
    /** Should display loading indicator for this Batch. */
    loading: boolean;
    /** Should tick the checkbox to mark this Batch as selected for bulk actions. */
    selected?: boolean;
    aiResults?: BatchAIResult[];
    imageZoom: {zoom: number, transform: {x: number, y: number}};
};

export type PositionedBatch = Batch & {
    position: NonNullable<Batch["position"]>;
    addressID: NonNullable<Batch["addressID"]>;
    addressKey: string;
    address: NonNullable<Batch["address"]>;
}

export default Batch;

export type NextBatchesQuery = NextQuery<BatchDbData> & {
    selectedErrors: SortingError[];
}