import i18next, { TOptions } from "i18next";
import { Namespace } from "../../locales/translations";
import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ActionResultInterface {
    variant: AlertColor;
    message: string;
    moreInfo: string | null;
}

const initialState: ActionResultInterface = {
    variant: "info" as "info",
    message: "",
    moreInfo: null,
};

const tContext = { ns: Namespace.SNACKS };

export const snacksSlice = createSlice({
    name: 'snacks',
    initialState: initialState,
    reducers: {
        showSuccess: (state, { payload }: PayloadAction<string>) => {
            state.variant = "success";
            state.message = payload;
        },
        showError: (state, { payload }: PayloadAction<string>) => {
            state.variant = "error";
            state.message = payload;
        },
        showMessage: (state, { payload: { variant, message, moreInfo } }: PayloadAction<{ variant: AlertColor, message: string, moreInfo?: string }>) => {
            state.variant = variant;
            state.message = message;

            if (moreInfo) state.moreInfo = moreInfo;
        },
        showTranslatedMessage: (state, { payload: { variant, messageKey, context, moreInfo } }: PayloadAction<{ variant: AlertColor, messageKey: string, context?: TOptions, moreInfo?: string }>) => {
            state.variant = variant;
            state.message = i18next.t(messageKey, { ...tContext, ...context });

            if (moreInfo) state.moreInfo = moreInfo;
        },
        hideMessage: () => ({
            ...initialState,
        }),
    },
});

export const { showSuccess, showError, showMessage, showTranslatedMessage, hideMessage, } = snacksSlice.actions;

const SnacksReducer =  snacksSlice.reducer;

export default SnacksReducer;