import GoogleMapReact, { Coords, Maps } from "google-map-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { formatAddress, getBoundsZoomLevel, GRENOBLE_COORDINATES, MAX_ZOOM } from "helpers/geo";
import AddressMarker from "./AddressMarker";
import { PositionedNamedErrorsByAddress } from "constants/types";
import { useAppSelector } from "store/hooks";

const FIREBASE_CONFIG = require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

type MapState = {
    zoom: number;
    center: Coords;
}

type WorstAddressesMapProps = {
    width: number;
    height: number;
    points: PositionedNamedErrorsByAddress[];
}

export default function WorstAddressesMap({ width, height, points }: WorstAddressesMapProps) {

    // handle map
    const [{ zoom, center }, setMapState] = useState<MapState>({
        zoom: 15,
        center: GRENOBLE_COORDINATES,
    });

    const mapRef = useRef<GoogleMapReact>(null);

    const defaultCenter = useAppSelector(state => state.batches.sortingMap.defaultCenter);

    useEffect(() => {
        if (points.length > 0) {
            // calculate map bounds and zoom to view all batches
            let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;
            for (let { lat, lng } of points) {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            }

            const zoom = getBoundsZoomLevel({
                nw: { lat: maxLat, lng: minLng },
                ne: { lat: maxLat, lng: maxLng },
                se: { lat: minLat, lng: maxLng },
                sw: { lat: minLat, lng: minLng },
            }, { width, height });

            const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2, };

            setMapState({
                zoom,
                center,
            });
        }
        else { // focus on default partner's center
            setMapState({
                zoom,
                center: defaultCenter,
            });
        }
    }, [points, defaultCenter]);

    // const [googleApi, setGoogleApi] = useState<{ map: any, maps: any }>();
    // const [routeSegment, setRouteSegment] = useState<any>();

    const handleMarkerClick = useCallback((lat: number, lng: number) => {
        setMapState({
            center: { lat, lng },
            zoom: 17,
        });
    }, [setMapState]);

    return (
        <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: FIREBASE_CONFIG.apiKey }}
            center={center}
            zoom={zoom}
            onGoogleApiLoaded={({ map, maps, ref }) => {
            }}
            options={(maps: Maps) => ({
                maxZoom: MAX_ZOOM,
                fullscreenControl: false,
                mapTypeControl: false,
                clickableIcons: false,
                keyboardShortcuts: false,
            })}
            onChange={({ zoom, center }) => {
                setMapState({
                    zoom: zoom,
                    center: center,
                });
            }}
        >
            {points.map((point) => (
                <AddressMarker
                    key={point.addressKey}
                    lat={point.lat}
                    lng={point.lng}
                    addressKey={point.addressKey}
                    address={formatAddress(point.address)}
                    onClick={handleMarkerClick}
                />
            ))}
        </GoogleMapReact>
    );
}