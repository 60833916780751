import { InputAdornment, TextField, Autocomplete, AutocompleteChangeReason, CircularProgress, Popper, Paper, AutocompleteRenderInputParams, InputLabelProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";

type SearchBarProps<T extends { name: string }> = {
    value: string;
    placeholder: string;
    autocompleteOptions: T[];
    loading?: boolean;
    minSearchLength: number;
    search: (value: string) => void;
    onOptionSelected: (value: T | null) => void;
    getOptionLabel?: (option: T) => string;
}

export default function SearchBar<T extends { name: string }>({ value, placeholder, autocompleteOptions, loading, minSearchLength, search, onOptionSelected, getOptionLabel }: SearchBarProps<T>) {

    const { t } = useTranslation([Namespace.COMMONS]);

    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    const [autocompleteTimeout, setAutocompleteTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (autocompleteTimeout) {
            clearTimeout(autocompleteTimeout);
        }

        setAutocompleteTimeout(
            setTimeout(function () {
                search(text);
            }, 300)
        );
    }, [text, value]);

    const handleTextChange = (e: SyntheticEvent<Element, Event>, value: string) => {
        if (!e || e.type === "click") { // click on autocomplete option
            return;
        }

        setText(value);
    }

    const handleChange = (e: SyntheticEvent<Element, Event>, newValue: T | null, reason: AutocompleteChangeReason) => {
        switch (reason) {
            case "selectOption": // existing manager
                onOptionSelected(newValue);
                break;

            case "clear":
                onOptionSelected(null);
                break;
        }
    }

    const noOptionsText = loading ? t("loading", { ns: Namespace.COMMONS }) // loading
        : text.length >= minSearchLength ? t("no_option", { ns: Namespace.COMMONS }) // no matching result
            : t("enter_at_least_characters", { count: minSearchLength, ns: Namespace.COMMONS }); // not enough characters

    return (
        <Autocomplete
            id="osm-search"
            options={loading ? [] :autocompleteOptions}
            getOptionLabel={getOptionLabel}
            noOptionsText={noOptionsText}
            clearOnBlur={false}
            inputValue={text}
            onInputChange={handleTextChange}
            onChange={(e, value, reason) => handleChange(e, value, reason)}
            PaperComponent={(props) => (
                <Paper 
                    sx={{ borderRadius: 1, mx: "2px" }} 
                    {...(props as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} 
                    />
            )}
            renderInput={(params) => (
                <TextField
                    {...(params as AutocompleteRenderInputParams & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })} 
                    placeholder={placeholder}
                    sx={{
                        maxWidth: "none"
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: loading ? (
                            <CircularProgress size={24} sx={{ position: "relative", }} />
                        ) : params.InputProps.endAdornment
                    }}
                />
            )}
            
            sx={{
                display: "inline-block",
                width: "100%",
            }}
        />
    );
}