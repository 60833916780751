import { Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useIsAdmin } from 'store/hooks';
import { usePartnerID } from 'store/hooks';
import BatchesMapWrapper from './BatchesMap/BatchesMapWrapper';
import WasteTypesPieChart from './Charts/WasteTypesPieChart';
import CollectionTimeline from './CollectionTimeline/CollectionTimeline';
import MostFrequentErrorsChart from './Charts/MostFrequentErrorsChart';
import WorstAddressesList from './Charts/WorstAddressesList';
import CollectionActions from './Actions/CollectionActions';
import BatchesController from 'controllers/batches';

type CollectionDetailProps = {

}

function ChartWrapper({ children }: { children: React.ReactNode }) {
    return (
        <Grid item sm={12} md={4}>
            <Paper
                sx={{
                    py: 1,
                }}
                variant="outlined"
            >
                {children}
            </Paper>
        </Grid>
    );
}

function CollectionDetail({ }: CollectionDetailProps) {
    const isAdmin = useIsAdmin();
    const partnerID = usePartnerID();

    const collection = useAppSelector(state => state.collections.selected.data);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (partnerID && collection?.ID) {
            dispatch(
                BatchesController.listPartnerBatchesForMap(
                    partnerID, 
                    [{
                        fieldPath: "collectionID",
                        opStr: "==",
                        value: collection.ID,
                    },
                    {
                        fieldPath: "display",
                        opStr: "==",
                        value: true,
                    },
                    {
                        fieldPath: "verified",
                        opStr: "==",
                        value: true,
                    }], 
                    [],
                    "collection"
                )
            );
        }
    }, [collection?.ID, partnerID,]);


    return (
        <Grid container position="relative" alignItems="center" justifyContent="center" columnSpacing={2} rowSpacing={2}>
            <ChartWrapper>
                <WasteTypesPieChart />
            </ChartWrapper>

            <ChartWrapper>
                <MostFrequentErrorsChart />
            </ChartWrapper>

            <ChartWrapper>
                <WorstAddressesList />
            </ChartWrapper>

            <Grid item xs={12}>
                <BatchesMapWrapper />
            </Grid>

            {isAdmin && (
                <Grid item xs={12}>
                    <CollectionTimeline />
                </Grid>
            )}

            <Grid item xs={12}>
                <CollectionActions />
            </Grid>
        </Grid>
    );
}

export default CollectionDetail;