import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Namespace } from "locales/translations";
import CollectionSchedule from "models/CollectionSchedule";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SelectedCollectionScheduleActions } from "store/reducers/collections_schedules/selected";
import { selectAllTrucks } from "store/reducers/trucks/list";

export default function ScheduleTruckSelect() {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const trucks = useAppSelector(state => selectAllTrucks(state));
    const truck = useAppSelector(state => state.collectionsSchedules.selected.editingTruck);

    const dispatch = useAppDispatch();

    const selectTruck = (truckID: string) => {
        const truck = trucks.find(t => t.ID === truckID);
        if (truck) {
            const truckData: CollectionSchedule["truck"] = {
                ID: truck.ID,
                identificationNumber: truck.identificationNumber,
            };
            dispatch(SelectedCollectionScheduleActions.setTruck(truckData));
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="truck-select-label">
                {t("truck", { ns: Namespace.GLOSSARY, count: 1 })}
            </InputLabel>
            <Select
                labelId="truck-select-label"
                value={truck?.ID ?? ""}
                label={t("truck", { ns: Namespace.GLOSSARY })}
                onChange={(e) => selectTruck(e.target.value)}
            >
                {trucks.map(truck => (
                    <MenuItem key={truck.ID} value={truck.ID}>
                        {truck.identificationNumber}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}