import { ResponsivePie } from "@nivo/pie";
import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { chunk, sumBy } from 'lodash';
import { TrashType } from 'constants/trash';
import { isSortingError, mergeTrashCounts } from 'helpers/trash';
import { WASTES_COLORS } from "constants/trash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ChartTitle from "components/Statistics/ChartTitle";
import { PIE_CHART_THEME } from "constants/theme";
import TrashTypeTooltip from "components/Statistics/TrashTypeTooltip";
import BatchesController from "controllers/batches";

type WasteTypesPieChartProps = {

}

type WasteSlice = {
    id: string;
    label: string;
    value: number;
    color: string;
}

// export const FICHA_COLOR_SCHEME = "9E0142D53E4FF46D43FEE08BFEFFBFE6F598ABDDA465C2A43188BD5E4FA2";



function WasteTypesPieChart(props: WasteTypesPieChartProps) {
    const collectionSurfaces = useAppSelector(state => state.collections.selected.data?.surfaces);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);

    const data = useMemo(() => {
        let wastesList: WasteSlice[] = [];
        if (!collectionSurfaces) return wastesList;

        const mergedSurfaces = mergeTrashCounts(collectionSurfaces);

        let totalSurface = sumBy(Object.values(mergedSurfaces)); // total trash surface

        for (let [wasteType, surface] of Object.entries(mergedSurfaces)) {
            const percentage = Math.round(surface / totalSurface * 100);
            if (percentage > 0) {
                wastesList.push({
                    id: wasteType,
                    label: t(wasteType, { ns: Namespace.WASTES }),
                    value: percentage,
                    color: WASTES_COLORS[wasteType as TrashType],
                });
            }
        }

        return wastesList;
    }, [t, collectionSurfaces]);

    const dispatch = useAppDispatch();

    const handleSliceClick = (trashType: TrashType) => {
        dispatch(
            BatchesController.applyMapBatchesFilters(
                isSortingError(trashType) ? [ // filter batches with selected sorting error on map
                    { fieldPath: "error", opStr: "==", value: trashType },
                ] : [], // reset filters
                "collection"
            )
        );
    }

    return (
        <Box
            justifyContent="center"
        >
            <ChartTitle
                sx={{
                    px: 3,
                }}
            >
                {t("truck_content.title", { ns: Namespace.CHARTS })}
                <br />
                <Typography variant="caption">
                    {t("click_to_filter_map", { ns: Namespace.CHARTS })}
                </Typography>
            </ChartTitle>
            <Box
                sx={{
                    width: "100%",
                    height: (theme) => theme.spacing(36),
                }}
            >
                <ResponsivePie
                    data={data}
                    valueFormat={(value) => `${value}%`}
                    theme={PIE_CHART_THEME}
                    margin={{ top: 8, right: 0, bottom: 92, left: 0 }}
                    padAngle={2}
                    innerRadius={0.4}
                    cornerRadius={4}
                    borderWidth={1}
                    borderColor={{
                        from: "color",
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    startAngle={-20}
                    arcLabelsTextColor={(d) => d.value > 5 ? "#ffffff" : "rgba(0,0,0,0.8)"}
                    colors={{ datum: "data.color" }}
                    enableArcLinkLabels={false}
                    legends={chunk(data, 2).map((twoTrashTypes, index) => ({ // group legend items 2 per row
                        anchor: "bottom",
                        direction: "row",
                        itemWidth: 140,
                        itemHeight: 20,
                        symbolShape: 'circle',
                        data: twoTrashTypes,//.map(trashType => ({
                        //     id: trashType.id,
                        //     label: trashType.label,
                        //     color: trashType.color,
                        // })),
                        translateX: 20,
                        translateY: 26 * (index + 1) + 12, // line height of 26px, all translated of 12px
                    }))}
                    tooltip={({ datum }) => (
                        <TrashTypeTooltip
                            trashType={datum.id as TrashType}
                            label={datum.data.label}
                            value={datum.formattedValue}
                        />
                    )}
                    onClick={(datum) => {
                        handleSliceClick(datum.id as TrashType);
                    }}
                    onMouseEnter={(datum, e) => {
                        e.currentTarget.style.cursor = "pointer";
                    }}
                    onMouseLeave={(datum, e) => {
                        e.currentTarget.style.cursor = "default";
                    }}
                // arcLabelsTextColor={{
                //     from: "color",
                //     modifiers: [
                //         [
                //             'darker',
                //             3
                //         ]
                //     ]
                // }}
                // arcLinkLabelsStraightLength={8}
                // arcLinkLabelsDiagonalLength={8}
                />
            </Box>
        </Box>
    );
}

export default WasteTypesPieChart;