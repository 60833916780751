import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import CollectionsController from "controllers/collections";
import { Namespace } from "locales/translations";
import Collection, { CollectionType } from "models/Collection";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

export default function ChangeCollectionTypeDialog({ open, onClose, }: DialogProps) {

    const { t } = useTranslation([Namespace.DIALOGS]);

    const collection = useAppSelector(state => state.collections.selected.data);

    const dialogTranslationKey = `change_collection_type.to_household`;

    const dispatch = useAppDispatch();

    const changeCollectionType = () => {
        if (collection) {
            dispatch(CollectionsController.markAsHousehold(collection));
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t(`${dialogTranslationKey}.title`, { ns: Namespace.DIALOGS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t(`${dialogTranslationKey}.content`, { ns: Namespace.DIALOGS })}
                </DialogContentText>
                <Typography mt={2} fontWeight="bold">
                    {t(`${dialogTranslationKey}.warning`, { ns: Namespace.DIALOGS })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <Button
                    onClick={() => changeCollectionType()}
                    color="warning"
                >
                    {t("confirm", { ns: Namespace.ACTIONS })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}