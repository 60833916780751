import { Box } from "@mui/material";
import DefaultBoundsMap from "./DefaultBoundsMap";
import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, areSameCoordinates } from "helpers/geo";
import { CenterAndZoom } from "constants/types";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import GoogleMapReact from 'google-map-react';
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import _ from "lodash";
import PartnersController from "controllers/partners";
import SaveDefaultMapBoundsButton from "./SaveDefaultMapBoundsButton";
import { MAP_HEIGHT } from "components/ManageCollections/CollectionDetail/BatchesMap/BatchesMapWrapper";

export default function DefaultMapBoundsWrapper() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const mapWrapperRef = useRef<HTMLDivElement>(null);
    const width = mapWrapperRef.current?.offsetWidth;

    const [{ center, zoom }, setMapState] = useState<CenterAndZoom>({
        center: GRENOBLE_COORDINATES,
        zoom: DEFAULT_ZOOM_LEVEL,
    });

    const [saveButtonVisible, setSaveButtonVisible] = useState(false);

    const defaultMapBounds = useAppSelector(state => state.partners.selected.data?.defaultMapViewport);

    useEffect(() => {
        if (width && defaultMapBounds) {
            setMapState(defaultMapBounds);
        }
    }, [width, defaultMapBounds]);

    const handleChange: (v: GoogleMapReact.ChangeEventValue) => void = useCallback(({ center: c, zoom: z }) => {
        if (!areSameCoordinates(center, c, 8) || zoom !== z) { // user has changed map viewport
            // NB: we check that it's not the first change when map is set (coordinates may have tiny differences)
            setSaveButtonVisible(true); // 
        }
        setMapState({ center: c, zoom: z });
    }, [setMapState]);

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();

    const onSaveClicked = () => {
        if (partnerID) {
            dispatch(PartnersController.update(partnerID, {
                defaultMapViewport: {
                    center,
                    zoom,
                }
            }))
                .then(success => {
                    if (success) setSaveButtonVisible(false); // hide button after save
                });
        }
    }

    return (
        <Box
            ref={mapWrapperRef}
            id="batches-map"
            width="100%"
            height={MAP_HEIGHT}
            mb={4}
            borderRadius={4}
            border={1}
            borderColor="rgba(0,0,0,0.12)"
            overflow="hidden"
            position="relative"
        >
            <DefaultBoundsMap
                center={center}
                zoom={zoom}
                onChange={handleChange}
            />

            <SaveDefaultMapBoundsButton 
                visible={saveButtonVisible}
                onClick={onSaveClicked}
                />
        </Box>
    );
}