import { Dialog, DialogTitle } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { Namespace } from 'locales/translations';
import _ from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleForm from './ScheduleForm';
import { useTranslation } from 'react-i18next';

type AddScheduleDialogProps = {
    open: boolean;
    onClose: () => void;
}

function AddScheduleDialog(props: AddScheduleDialogProps) {
    const { open, onClose } = props;

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS,]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogIcon
                Icon={CalendarMonthIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.schedules.add_schedule", { ns: Namespace.FORMS })}
            </DialogTitle>
            <ScheduleForm 
                onClose={onClose}
                />
        </Dialog>
    );
}

export default AddScheduleDialog;