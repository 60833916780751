import { Box, Typography } from "@mui/material";
import { TRASH_TYPES_ICONS, TrashType } from "constants/trash";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { ShortcutKey } from "store/reducers/preferences/validation_shortcuts";
import CustomizeShortcutButton from "./CustomizeShortcutButton/CustomizeShortcutButton";
import ClearShortcutButton from "./ClearShortcutButton";

type ItemProps = {
    shortcut: ShortcutKey;
}

export default function ValidationShortcutItem({ shortcut }: ItemProps) {

    const { t } = useTranslation([Namespace.PREFERENCES,]);

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            <Typography variant="body1" flexGrow={1}>
                {Object.values<string>(TrashType).includes(shortcut) ?
                    `${TRASH_TYPES_ICONS[shortcut as TrashType]} ${t(shortcut, { ns: Namespace.WASTES, context: "small", count: 1 })}`
                    :
                    t(shortcut, { ns: Namespace.PREFERENCES })
                }
            </Typography>

            <Box display="flex" alignItems="center" gap={1}>
                <ClearShortcutButton
                    shortcut={shortcut}
                    />

                <CustomizeShortcutButton
                    shortcut={shortcut}
                />
            </Box>
        </Box>
    )
}