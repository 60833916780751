import { LoadableContext } from "store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as XLSX from "xlsx";

type ExcelFileData = ReturnType<typeof XLSX.utils.sheet_to_json>;

export type ColumnType = "datetime" | "rfid";

type CollectionContext = LoadableContext & {
    data: ExcelFileData | null;
    /** Keys of the columns selected by the user containing datetime and rfid data. */
    selectedColumns: { [type in ColumnType]: string | null };
    /** Format used for the datetimes in the Excel sheet. @example "DD/MM/YYYY HH:mm:ss" */
    datetimeFormat: string | null;
};

const initialState: CollectionContext = {
    loading: false,
    error: null,
    data: null,
    selectedColumns: { 
        datetime: null, 
        rfid: null,
    },
    datetimeFormat: null,
};

export const collectionRFIDSlice = createSlice({
    name: 'collection_rfid',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        cleanUp: (state) => {
            state.loading = false;
            state.data = null;
            state.selectedColumns = { ...initialState.selectedColumns };
        },
        setData: (state, { payload: data }: PayloadAction<ExcelFileData | null>) => {
            state.loading = false;
            state.data = data;
        },
        setSelectedColumn: (state, { payload: { type, key, datetimeFormat }}: PayloadAction<{ type: ColumnType, key: string | null, datetimeFormat?: string }>) => {
            state.selectedColumns[type] = key;
            if (datetimeFormat) state.datetimeFormat = datetimeFormat;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CollectionRFIDActions = collectionRFIDSlice.actions;

const CollectionRFIDReducer = collectionRFIDSlice.reducer;

export default CollectionRFIDReducer;