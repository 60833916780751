import { Box, Card } from "@mui/material";
import TrashColorSquare from "components/_include/TrashColorSquare";
import { TrashType } from "constants/trash"

type TooltipProps = {
    trashType: TrashType;
    label: string;
    value: string;
}

export default function TrashTypeTooltip({ trashType, label, value }: TooltipProps) {

    return (
        <Card
            sx={{
                background: "white",
                padding: "4px 8px",
                border: "1px solid #ccc",
                borderRadius: 1,
            }}
        >
            <Box
                py="3px"
                display="flex"
                alignItems="center"
            >
                <Box component="span" display="inline-flex" mr={1} mb="1px">
                    <TrashColorSquare trashType={trashType} />
                </Box>
                {label}{'\u00A0:\u00A0'}<strong>{value}</strong>
            </Box>
        </Card>
    )
}