import { Box, Button, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

type LessCommonWastesHeaderProps = {
    expanded: boolean;
    onClick: () => void;
}

export default function LessCommonWastesHeader({ expanded, onClick }: LessCommonWastesHeaderProps) {

    const { t } = useTranslation([Namespace.ACTIONS]);

    return (
        <Box
            display="flex"
            px={1}
            alignItems="center"
            gap={2}
            alignSelf="stretch"
            color={theme => theme.palette.secondary.main}
        >
            <Typography
                variant='subtitle1'
                >
                {t("other_types_of_waste", { ns: Namespace.WASTES })}
            </Typography>

            <Button
                variant="text"
                color='secondary'
                endIcon={expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                onClick={onClick}
                >
                {t(expanded ? "hide" : "show_more", { ns: Namespace.ACTIONS })}
            </Button>
        </Box>
    );
}