import { TimePicker } from 'react-rainbow-components';

type TimeSelectProps = {
    loading?: boolean;
    label: string;
    value: string;
    onChange: (v: string) => void;
}

function TimeSelect({ label, loading, value, onChange }: TimeSelectProps) {

    return (
        <TimePicker
            hour24={true}
            placeholder={label}
            value={value}
            onChange={onChange}
            className="datepicker-range"
            style={{
                pointerEvents: "all",
            }}
            size="small"
            disabled={loading}
            icon={<></>} // no icon
        />
    );
}

export default TimeSelect;