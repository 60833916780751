import { ButtonBase } from '@mui/material';
import PartnerLogo from './PartnerLogo';
import PartnerLogoBackdrop from './PartnerLogoBackdrop';
import React, { useEffect, useState } from 'react';
import { StorageType, getStorageURL, uploadFile } from 'helpers/storage';
import { PartnersActions, selectPartnerById } from 'store/reducers/partners/list';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PartnersController from 'controllers/partners';
import { SelectedPartnerActions } from 'store/reducers/partners/selected';

type PartnerLogoButtonProps = {
    partnerID: string | null;
    onChange?: (logoURL: string | undefined) => void;
}

export default function PartnerLogoButton({ partnerID, onChange }: PartnerLogoButtonProps) {
    const [logoURL, setLogoURL] = useState<string>();

    const partnerLogoURL = useAppSelector(state => partnerID ? selectPartnerById(state, partnerID)?.logoURL : undefined);

    useEffect(() => {
        setLogoURL(partnerLogoURL);
    }, [partnerLogoURL]);
    
    const dispatch = useAppDispatch();

    const updateLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (!selectedFiles || selectedFiles.length < 1) return; // user didn't select any file

        const newLogoFile = selectedFiles[0];
        const filename = newLogoFile.name;

        if (partnerID) { // existing partner
            // start displaying the loader
            dispatch(SelectedPartnerActions.startLoading());
        }

        // upload the selected image to Google Cloud Storage
        const fullURL = await uploadFile(newLogoFile, `${getStorageURL(StorageType.PARTNERS)}/${filename}`);

        if (partnerID) { // existing partner
            // update the database entry
            await dispatch(PartnersController.update(partnerID, { logoURL: filename }));
        }
        else { // new partner
            setLogoURL(filename);
        }
    }

    useEffect(() => {
        // call parent callback
        if (onChange) onChange(logoURL);
    }, [logoURL]);

    return (
        <ButtonBase
            component="label" // use label so that click forwards to hidden file input
            sx={{
                width: "100%",
                height: theme => theme.spacing(20),
            }}
        >
            {logoURL && ( // existing partner with a logo or a logo has been chosen for new partner
                <PartnerLogo
                    logoURL={logoURL}
                />
            )}
            <PartnerLogoBackdrop noLogo={!logoURL} />

            <input type="file" style={{ display: "none" }} onChange={updateLogo} />
        </ButtonBase>
    );
}