import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import DialogIcon from "components/_include/DialogIcon";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import MailIcon from "@mui/icons-material/MailRounded";
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import { TextInput } from "helpers/forms";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import { UserRole } from "models/User";
import { isValidEmail } from "helpers/strings";
import CollaboratorsController from "controllers/collaborators";
import { NewCollaborator } from "models/Collaborator";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

type Inputs = {
    firstName: TextInput;
    lastName: TextInput;
    email: TextInput;
    role: TextInput;
}

/** Dialog to enter the details of a new collaborator to invite to access a partner's data. */
export default function AddCollaboratorDialog({ open, onClose }: DialogProps) {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.CONTACTS, Namespace.FORMS]);

    const loading = useAppSelector(state => state.collaborators.selected.loading);

    const [inputs, setInputs] = useState<Inputs>({
        firstName: { value: "", error: null },
        lastName: { value: "", error: null },
        email: { value: "", error: null },
        role: { value: "", error: null },
    });

    const { value: firstName, error: firstNameError } = inputs.firstName;
    const { value: lastName, error: lastNameError } = inputs.lastName;
    const { value: email, error: emailError } = inputs.email;
    const { value: role, } = inputs.role;

    const handleInputChange = (field: keyof Inputs, value: string) => {
        setInputs({
            ...inputs,
            [field]: {
                value,
                error: null,
            },
        });
    }

    const dispatch = useAppDispatch();

    const partnerID = usePartnerID();

    const handleSubmit = () => {
        if (!partnerID) return;

        if (!isValidEmail(email)) { // check if email is valid
            setInputs({
                ...inputs,
                email: {
                    ...inputs.email,
                    error: t("sign_in.invalid_email", { ns: Namespace.FORMS }),
                }
            })
            return;
        }

        dispatch(CollaboratorsController.invite(partnerID, {
            firstName,
            lastName,
            email,
            role: role as NewCollaborator["role"],
        }))
            .then(collaborator => {
                if (collaborator) { // invitation successful
                    onClose(); // close dialog
                }
            });
    }

    const rolesOptions: Option[] = useMemo(() => (
        [UserRole.PARTNER_ADMIN, UserRole.PARTNER_MEMBER, UserRole.AMBASSADOR].map(role => ({
            label: t(role, { ns: Namespace.CONTACTS }),
            value: role,
        }))
    ), []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogIcon
                Icon={GroupsRoundedIcon}
            />
            <DialogTitle align='center'>
                {t("add collaborator", { ns: Namespace.ACTIONS })}
            </DialogTitle>

            <DialogContent>
                <Grid container columnSpacing={3} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("first name", { ns: Namespace.CONTACTS })}
                            value={firstName}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                onChange: (event) => handleInputChange('firstName', event.target.value),
                            }}
                            disabled={loading}
                            error={firstNameError !== null}
                            helperText={firstNameError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("last name", { ns: Namespace.CONTACTS })}
                            value={lastName}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                onChange: (event) => handleInputChange('lastName', event.target.value),
                            }}
                            disabled={loading}
                            error={lastNameError !== null}
                            helperText={lastNameError}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <TextField
                            label={t("email", { ns: Namespace.CONTACTS })}
                            value={email}
                            margin="normal"
                            variant="outlined"
                            type="email"
                            InputProps={{
                                onChange: (event) => handleInputChange('email', event.target.value),
                            }}
                            disabled={loading}
                            error={emailError !== null}
                            helperText={emailError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} display="flex" alignItems="center">
                        <DropdownMenu
                            id="collaborator-role-select-menu"
                            title={role ? t(role, { ns: Namespace.CONTACTS }) : t("select.a_role", { ns: Namespace.FORMS })}
                            loading={loading}
                            values={rolesOptions}
                            defaultValue={role}
                            startIcon={<AssignmentIndRoundedIcon />}
                            onChange={(role) => handleInputChange('role', role)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    sx={{
                        color: theme => theme.palette.grey[700],
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>

                <LoadingButton
                    variant="text"
                    color="success"
                    onClick={handleSubmit}
                    endIcon={<MailIcon />}
                    disabled={loading || !(firstName && lastName && email && role)}
                >
                    {t("send invitation", { ns: Namespace.ACTIONS })}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}