

import { Box, Modal } from '@mui/material';
import CreateMissionModal from '../MissionCard/CreateMissionModal';

type NewPartnerModalProps = {
    open: boolean;
    onClose: () => void;
}

export default function NewMissionModal({ open, onClose }: NewPartnerModalProps) {

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
            }}
        >
            <Box
                position='absolute'
                top='50%'
                left='50%'
                width={theme => theme.spacing(40)}
                sx={{
                    transform: 'translate(-50%, -50%)'
                }}
            >
               <CreateMissionModal onMissionCreated={onClose} />
            </Box>
        </Modal>
    );
}