import { Box, ButtonBase, Modal } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BatchesActions } from "store/reducers/batches/list";
import UpdateBatchCard from "./UpdateBatchCard";
import { CloseRounded } from "@mui/icons-material";
import SaveResultsButton from "./WastesForm/WastesFormActions/SaveResultsButton";

type DialogProps = {
    canEditResults?: boolean;
}

function UpdateBatchDialog({ canEditResults }: DialogProps) {

    const batch = useAppSelector(state => state.batches.list.selectedBatch);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(BatchesActions.unselectBatch());
    }

    return (
        <Modal
            open={batch != null}
            onClose={handleClose}
            disableEscapeKeyDown
            hideBackdrop
        >
            <Box
                height="100%"
                width="100%"
                display="flex"
                alignItems="stretch"
            >
                <ButtonBase
                    onClick={handleClose}
                    sx={{ 
                        width: theme => theme.spacing(8),
                        backgroundColor: "rgba(0,0,0,.9)",
                        transition: "background-color .3s",
                        "&:hover": {
                            backgroundColor: "rgba(50,50,50,.8)",
                        }
                    }}
                    >
                    <CloseRounded sx={{ color: "#fff" }} />
                </ButtonBase>

                <UpdateBatchCard
                    canEditResults={canEditResults}
                />

                {canEditResults && (
                    <SaveResultsButton
                        goToNext
                        fullHeight
                        />
                )}
            </Box>
        </Modal>
    );
}

export default UpdateBatchDialog;