import { FormControl, IconButton, InputLabel, OutlinedInput } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { CSSProperties } from "react";

type NumberInputProps = {
    id: string;
    label?: string;
    value: number;
    min?: number;
    max?: number;
    onChange: (newValue: number) => void;
}

const ADORNMENTS_STYLES: CSSProperties = { 
    borderRadius: 2, 
    color: "#000",
};

export default function NumberInput({ id, label, value, min, max, onChange }: NumberInputProps) {
    
    const handleChange = (newValue: number) => {
        if ((min && newValue < min) || (max && newValue > max)) return; // prevent going beyond min/max

        onChange(newValue);
    }
    
    return (
        <FormControl sx={{ width: 100 }} >
            {label && (
                <InputLabel id={`input-${id}-label`}>
                    {label}
                </InputLabel>
            )}
            <OutlinedInput
                aria-describedby={`input-${id}-label`}
                label={label}
                value={value}
                disabled={true}
                size="small"
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    style: {
                        fontSize: '0.75rem',
                        textAlign: "center",
                    }
                }}
                sx={{
                    px: 0,
                }}
                startAdornment={
                    <IconButton
                        disabled={value === min}
                        onClick={() => handleChange(value - 1)}
                        sx={ADORNMENTS_STYLES}
                    >
                        <RemoveRoundedIcon />
                    </IconButton>
                }
                endAdornment={(
                    <IconButton
                        disabled={value === max}
                        onClick={() => handleChange(value + 1)}
                        sx={ADORNMENTS_STYLES}
                    >
                        <AddRoundedIcon />
                    </IconButton>
                )}
            />
        </FormControl>
    );
}