import Batch, { NextBatchesQuery } from "models/Batch";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { DEFAULT_ZOOM } from "controllers/batches";

const batchesAdapter = createEntityAdapter<Batch>({
    selectId: batch => batch.ID,
    sortComparer: (batch1, batch2) => batch1.timestamp - batch2.timestamp, // sort by timestamp asc
});

type BatchesContext = {
    count: number;
    loading: boolean;
    error: string | null;
    next: NextBatchesQuery | null;
    hoveredBatchID: string | undefined;
    selectedBatch: Batch | undefined;
    hidingMany: boolean;
    selectedStartID: string | null;
    selectedEndID: string | null;
};

const initialState: BatchesContext = {
    count: 0,
    loading: false,
    error: null,
    next: null,
    hoveredBatchID: undefined,
    selectedBatch: undefined,
    hidingMany: false,
    selectedStartID: null,
    selectedEndID: null,
};

/** Maximum zoom level. */
export const MAX_ZOOM = 3;

export const batchesSlice = createSlice({
    name: 'batches',
    initialState: batchesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            batchesAdapter.setAll(state, []);
            state.count = 0;
            state.loading = true;
            state.error = null;
            state.selectedBatch = undefined;
        },
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        addToList: (state, { payload: { batches, next } }: PayloadAction<{ batches: Batch[], next: NextBatchesQuery | null }>) => {
            state.loading = false;
            batchesAdapter.addMany(state, batches);
            state.count += batches.length;
            state.next = next;
        },
        updateItem: (state, { payload: { batchID, data } }: PayloadAction<{ batchID: string, data: Partial<Batch> }>) => {
            batchesAdapter.updateOne(state, { id: batchID, changes: data });
            if (state.selectedBatch?.ID === batchID) { // update selected batch in state
                state.selectedBatch = {
                    ...state.selectedBatch,
                    ...data,
                };
            }
        },
        updateItems: (state, { payload: updates }: PayloadAction<{ id: string, changes: Partial<Batch> }[]>) => {
            batchesAdapter.updateMany(state, updates);
            if (state.selectedBatch?.ID) { // update selected batch in state
                const selectedChanges = updates.find(({ id }) => id === state.selectedBatch?.ID);
                if (selectedChanges) {
                    state.selectedBatch = {
                        ...state.selectedBatch,
                        ...selectedChanges?.changes,
                    };
                }
            }
        },
        selectBatch: (state, { payload: batchID }: PayloadAction<string>) => {
            state.selectedBatch = state.entities[batchID];
            state.hoveredBatchID = undefined; // mark as not hovered anymore
        },
        selectNextBatch: (state) => {
            const index = state.ids.findIndex(id => id === state.selectedBatch?.ID);
            if (index < state.ids.length - 1) { // there's a batch after the current one
                const nextID = state.ids[index + 1];
                state.selectedBatch = state.entities[nextID];
            }
            else { // that was the last batch
                state.selectedBatch = undefined;
            }
        },
        selectPreviousBatch: (state) => {
            const index = state.ids.findIndex(id => id === state.selectedBatch?.ID);
            if (index > 0) { // there's a batch before the current one
                const beforeID = state.ids[index - 1];
                state.selectedBatch = state.entities[beforeID];
            }
            else { // that was the first batch
                state.selectedBatch = undefined;
            }
        },
        unselectBatch: (state) => {
            state.selectedBatch = undefined;
        },
        /** 
         * Select several batches between a "start" and an "end" IDs. 
         * If the selected batch is before previous "start", select all batches between.
         * Same if it's after previous "end".
         */
        selectMany: (state, { payload: batchID }: PayloadAction<string>) => {
            const batchIndex = state.ids.indexOf(batchID);
            const newStartIndex = state.selectedStartID ? Math.min(batchIndex, state.ids.indexOf(state.selectedStartID)) : batchIndex;
            if (newStartIndex === batchIndex) { // selected batch is before the previously first selected one
                state.selectedStartID = batchID;
            }
            const newEndIndex = state.selectedEndID ? Math.max(batchIndex, state.ids.indexOf(state.selectedEndID)) : batchIndex;
            if (newEndIndex === batchIndex) { // selected batch is before the previously first selected one
                state.selectedEndID = batchID;
            }
            let idsToSelect = state.ids.slice(newStartIndex, newEndIndex + 1); // IDs of all the batches between start and end
            batchesAdapter.updateMany(state, idsToSelect.map(id => ({ id, changes: { selected: true } })));
        },
        /** Unselect a single batch. Update the start/end in case batch was the first/last. */
        unselectOne: (state, { payload: batchID }: PayloadAction<string>) => {
            batchesAdapter.updateOne(state, { id: batchID, changes: { selected: false } });
            if (batchID === state.selectedStartID) { // batch was the first
                console.debug(batchID, "was first");
                let newStartID: string | null = null;
                for (let id of state.ids) { // find first item still selected
                    if (state.entities[id]?.selected) {
                        newStartID = id.toString();
                        break;
                    }
                }
                state.selectedStartID = newStartID;
                console.debug(newStartID, "is new first");
            }
            if (batchID === state.selectedEndID) { // batch was the last
                let newEndID: string | null = null;
                for (let i = state.ids.length - 1; i >= 0; i--) { // find last item still selected
                    const id = state.ids[i];
                    if (state.entities[id]?.selected) {
                        newEndID = id.toString();
                        break;
                    }
                }
                state.selectedEndID = newEndID;
            }
        },
        /** Stop selecting several batches to hide. Unselect all selected if any. */
        toggleHidingMany: (state, { payload: hiding }: PayloadAction<boolean>) => {
            state.hidingMany = hiding;
            state.selectedStartID = null;
            state.selectedEndID = null;
            batchesAdapter.updateMany(state, state.ids.map(id => ({ id, changes: { selected: false } })));
        },
        removeItem: (state, { payload: batchId }: PayloadAction<string>) => {
            batchesAdapter.removeOne(state, batchId);
            state.count -= 1;
        },
        resetList: (state) => {
            state.selectedBatch = undefined;
            batchesAdapter.removeAll(state);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        hoverBatch: (state, { payload: batchID }: PayloadAction<string | undefined>) => {
            state.hoveredBatchID = batchID;
        },
        leaveBatch: (state, { payload: batchID }: PayloadAction<string | undefined>) => {
            if (batchID === state.hoveredBatchID) state.hoveredBatchID = undefined;
        },
        /** Zoom or unzoom on a part of the selected batch's image. */
        adjustZoom: (state, { payload: { batchID, delta, clientX, clientY, } }: PayloadAction<{ batchID: string, delta: number, clientX: number, clientY: number }>) => {
            if (state.selectedBatch) {
                const imageZoom = state.selectedBatch.imageZoom;

                let newX = 0, newY = 0;

                let newScale = imageZoom.zoom + delta;
                newScale = Math.min(Math.max(newScale, DEFAULT_ZOOM.zoom), MAX_ZOOM); // Limit the scale within the specified range

                if (newScale > DEFAULT_ZOOM.zoom) { // if zoom level is <= 1, reset to its original size
                    // Calculate the ratio between the new scale and the current scale
                    const ratio = newScale / imageZoom.zoom;

                    // Calculate the new position based on the cursor position
                    //TODO: clamp these values too to prevent white on side when image is too translated when unzooming
                    newX = imageZoom.transform.x + (clientX - imageZoom.transform.x) * (1 - ratio);
                    newY = imageZoom.transform.y + (clientY - imageZoom.transform.y) * (1 - ratio);
                }

                const updatedData = { imageZoom: { zoom: newScale, transform: { x: newX, y: newY } } };

                batchesAdapter.updateOne(state, { id: batchID, changes: updatedData });

                state.selectedBatch = {
                    ...state.selectedBatch,
                    ...updatedData,
                };
            }
        },
    },
});

export const BatchesActions = batchesSlice.actions;

export const {
    selectAll: selectAllBatches,
    selectById: selectBatchById,
    selectIds: selectBatchesIds,
} = batchesAdapter.getSelectors((state: RootState) => state.batches.list)

const BatchesListReducer = batchesSlice.reducer;

export default BatchesListReducer;