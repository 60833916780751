import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import { TrashType } from "constants/trash";
import { useMemo } from "react";

type PreventEmptyProps = {
    /** Set to true to remove empty value option ("") once a valid value has been selected */
    preventEmpty: true;

    /** Callback to trigger when a different value is selected */
    onChange: (value: TrashType) => void;
}

type AllowEmptyProps = {
    /** Callback to trigger when a different value is selected */
    onChange: (value: TrashType | "") => void;
}

type TrashTypeSelectProps = {
    /** Unique id for the select input */
    id: string;

    /** Title to display by default when the menu is closed */
    title: string;

    /** Set to true to force the loading state on the dropdown */
    parentLoading: boolean;

    /** Set the default value for the dropdown */
    defaultValue: TrashType | "";

    /** List of the options that should appear in the menu */
    options: TrashType[];

    /** Decorator to display before the title when the menu is closed and no value is selected */
    startIcon?: JSX.Element;

    /** Set the count for use of plural or singular in localized labels  */
    count?: number;
} & (PreventEmptyProps | AllowEmptyProps);

/**
 * Dropdown menu to select a class of waste.
 */
export default function TrashTypeSelect({ id, title, parentLoading, options: optionsValues, defaultValue, startIcon, count, onChange, ...others }: TrashTypeSelectProps) {
    const loading = parentLoading;

    const { t } = useTranslation([Namespace.FORMS]);

    const translateTrashType = (type: TrashType) => t(type, { ns: Namespace.WASTES, context: "small", count });

    /**
     * Sort waste types alphabetically with "other" at the end
     */
    const sortedOptionsLabels = useMemo(() => {
        const sortedKeys = optionsValues.filter(waste => waste !== TrashType.OTHER);
        sortedKeys.sort((waste1, waste2) => translateTrashType(waste1).localeCompare(translateTrashType(waste2)));
        const sortedOptions = [...sortedKeys, TrashType.OTHER];
        return sortedOptions;
    }, [t, optionsValues]);

    let options: Option[] = [];

    // allow selecting empty option
    if (!("preventEmpty" in others)) options.push({ label: title, value: "" });

    options.push(
        ...sortedOptionsLabels.map(value => ({
            label: translateTrashType(value),
            value: value,
        }))
    );

    return (
        <DropdownMenu
            id={id}
            title={title}
            loading={loading}
            values={options}
            defaultValue={defaultValue}
            startIcon={startIcon}
            onChange={(value) => onChange(value as TrashType)}
        />
    );
}