import { Container, CssBaseline } from '@mui/material';
import { Outlet, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { onAuthStateChanged, getAuth, } from 'firebase/auth';

type PageProps = {
    
}

function LoggedOutLayout(props: PageProps) {
    const {  } = props;

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const redirectParam = searchParams.get("next");

    const isLoginPage = useMatch("/login");
    const isResetPasswordPage = useMatch("/reset-password");

    // redirect logged in users to home page or "next" URL param
    onAuthStateChanged(getAuth(), user => {
        if ((isLoginPage || isResetPasswordPage) && user) {
            navigate(redirectParam || "/", { replace: true });
        }
    });

    return (
        <Container
            sx={{
                mt: 4,
                padding: 4,
                pt: 6,
                mx: "auto",
                my: 0,
                textAlign: 'center',
                display: "flex",
                flexDirection: "colummn"
            }}>
            <CssBaseline />
            <Outlet />
        </Container>
    );
}

export default LoggedOutLayout;