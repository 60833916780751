import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Truck from "models/Truck";
import { LoadableContext, RootState } from "store/store";

const trucksAdapter = createEntityAdapter<Truck>({
    selectId: truck => truck.ID,
    // sortComparer: (a, b) => b.date.localeCompare(a.date)
});

type TrucksContext = LoadableContext & {

};

const initialState: TrucksContext = {
    loading: false,
    error: null,
};


export const trucksSlice = createSlice({
    name: 'trucks',
    initialState: trucksAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            trucksAdapter.removeAll(state); // clear all loaded trucks (eg change of selected partner)
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: trucks }: PayloadAction<Truck[]>) => {
            state.loading = false;
            trucksAdapter.setAll(state, trucks);
        },
        addItem: (state, { payload: truck }: PayloadAction<Truck>) => {
            trucksAdapter.addOne(state, truck);
        },
        updateItem: (state, { payload: { truckID, data } }: PayloadAction<{ truckID: string, data: Partial<Truck> }>) => {
            let truckData = state.entities[truckID]!;
            trucksAdapter.updateOne(state, { 
                id: truckID, 
                changes: {
                    ...data,
                    displayID: data.identificationNumber ?? truckData.identificationNumber ?? data.licensePlate ?? truckData.licensePlate,
                },
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const TrucksActions = trucksSlice.actions;

export const {
    selectAll: selectAllTrucks,
    selectById: selectTruckById,
    selectIds: selectTrucksIds
} = trucksAdapter.getSelectors((state: RootState) => state.trucks.list)

const TrucksReducer = trucksSlice.reducer;

export default TrucksReducer;