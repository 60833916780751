import { Namespace } from "locales/translations";
import SectionLoader from "../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography, ImageList, } from "@mui/material";
import ImageToAnnotateItem from "components/ImagesToAnnotate/ImageToAnnotateItem";
import LoadMoreBatches from "../_include/LoadMoreBatches";
import { useEffect, useState } from "react";
import { usePrevious } from "helpers/refs";
import ImagesFilterAndAction from "./ImagesToAnnotateFilters/ImagesFilterAndAction";
import UpdateBatchDialog from "components/ManageBatches/UpdateBatchDialog/UpdateBatchDialog";
import { useAppSelector } from "store/hooks";
import { selectBatchesIds } from "store/reducers/batches/list";
import PageTitle from "components/_include/PageTitle";
import FormatShapesIcon from '@mui/icons-material/FormatShapes';

type ImagesToAnnotateProps = {

}

export const BATCH_IMAGE_HEIGHT = 246;

function ImagesToAnnotatePage(props: ImagesToAnnotateProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.TITLES]);

    const batchesLoading = useAppSelector(state => state.batches.list.loading);
    const batchesIDs = useAppSelector(state => selectBatchesIds(state));
    const nextQuery = useAppSelector(state => state.batches.list.next);

    const [hasLoaded, setHasLoaded] = useState(false);

    const wasLoading = usePrevious(batchesLoading);

    useEffect(() => {
        if (!hasLoaded && wasLoading && !batchesLoading) {
            setHasLoaded(true);
        }
    }, [batchesLoading]);

    return (
        <Container
            sx={{
                // bgcolor: "#EFEFEF",
            }}
        >
            <PageTitle
                title={t("to_annotate_title", { ns: Namespace.TITLES })}
                Icon={FormatShapesIcon}
                hidePartnerSelect
                />
            <Box>
                <Box
                    position="sticky"
                    top={0}
                    py={2}
                    zIndex={1000}
                    borderBottom="1px solid #fff"
                    sx={{
                        bgcolor: (theme) => theme.palette.background.default,
                    }}
                >
                    <ImagesFilterAndAction />
                </Box>

                {batchesLoading && (
                    <SectionLoader />
                )}

                {hasLoaded && (
                    <Box>
                        <ImageList cols={3} rowHeight={BATCH_IMAGE_HEIGHT}>
                            {batchesIDs.map(batchID => (
                                <ImageToAnnotateItem
                                    key={batchID}
                                    batchID={batchID}
                                />
                            ))}
                        </ImageList>

                        {nextQuery !== null && (
                            <LoadMoreBatches />
                        )}
                    </Box>
                )}
            </Box>

            <UpdateBatchDialog />
        </Container>
    );
};

export default ImagesToAnnotatePage;
