import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

import moment from 'moment-timezone';
import "moment/locale/fr";

import { configureTranslations, DEFAULT_TIMEZONE } from "locales/translations";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

moment.locale("fr");
moment.tz.setDefault(DEFAULT_TIMEZONE);

configureTranslations();

initializeApp(require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`));

if (process.env.REACT_APP_IS_LOCALHOST) { // connect to local Firebase emulators
    connectAuthEmulator(getAuth(), "http://localhost:9099", { disableWarnings: true });
    connectFirestoreEmulator(getFirestore(), "localhost", 8080);
    connectStorageEmulator(getStorage(), "localhost", 9199);
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.unregister();
