import Collection, { CollectionDbData } from "models/Collection";
import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NextQuery } from "constants/db";

type CollectionContext = LoadableContext & {
    loadingMore: boolean;
    nextQuery: NextQuery<CollectionDbData> | null;
};

const initialState: CollectionContext = {
    loading: false,
    loadingMore: false,
    error: null,
    nextQuery: null,
};

const collectionsAdapter = createEntityAdapter<Collection>({
    selectId: collection => collection.ID,
});

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState: collectionsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        startLoadingMore: (state) => {
            state.error = null;
            state.loadingMore = true;
        },  
        setList: (state, { payload: { collections, nextQuery } }: PayloadAction<{ collections: Collection[], nextQuery: NextQuery<CollectionDbData> | null }>) => {
            state.loading = false;
            collectionsAdapter.setAll(state, collections);
            state.nextQuery = nextQuery;
        },
        addMany: (state, { payload: { collections, nextQuery } }: PayloadAction<{ collections: Collection[], nextQuery: NextQuery<CollectionDbData> | null }>) => {
            state.loadingMore = false;
            collectionsAdapter.addMany(state, collections);
            state.nextQuery = nextQuery;
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<Collection>}>) => {
            collectionsAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        removeItem: (state, { payload: collectionID }: PayloadAction<string>) => {
            collectionsAdapter.removeOne(state, collectionID);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const CollectionsActions = collectionsSlice.actions;

export const {
    selectAll: selectAllCollections,
    selectById: selectCollectionById,
    selectIds: selectCollectionsIds
} = collectionsAdapter.getSelectors((state: RootState) => state.collections.list)

const CollectionsReducer = collectionsSlice.reducer;

export default CollectionsReducer;