import { ListItemButton, ListItemText, ListItemIcon, Box, Tooltip } from "@mui/material";
import { Namespace } from "locales/translations";
import Batch from "models/Batch";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TrashType } from "constants/trash";
import TrashColorSquare from "components/_include/TrashColorSquare";
import { getErrors, getErrorsCount } from "helpers/trash";
import VisitedIcon from "components/_include/Icons/VisitedIcon";
import { getDangerColor } from "helpers/draw";

type ItemButtonProps = {
    batch: Batch;
    onClick: (batchID: string) => void;
    onMouseEnter: (batchID: string) => void;
    onMouseLeave: (batchID: string) => void;
};

export default function SelectBatchItemButton(props: ItemButtonProps) {
    const { batch, onClick, onMouseEnter, onMouseLeave, } = props;

    const { t } = useTranslation([Namespace.COMMONS, Namespace.DATES, Namespace.GLOSSARY, Namespace.STATS]);

    // const batch = useAppSelector(state => selectBatchById(state, batchID)!);

    let visited = localStorage.getItem(`visited-batch-${batch.ID}`);

    return (
        <ListItemButton
            sx={{
                borderBottom: `1px solid #EEEEEE`,
                // opacity: visited ? .6 : 1,
                // bgcolor: visited ? "#DDDDDD" : "#FFFFFF",
            }}
            onClick={() => onClick(batch.ID)}
            onMouseEnter={() => onMouseEnter(batch.ID)}
            onMouseLeave={() => onMouseLeave(batch.ID)}
        >
            <ListItemText
                primary={t("datetime_full", { ns: Namespace.DATES, datetime: batch.timestamp }).toString()}
                secondary={
                    <Box 
                        component="span"
                        display="flex"
                        alignItems="center"
                        >
                        <Box component="span" mr={2}>
                            {t("errors_count", { ns: Namespace.GLOSSARY, count: getErrorsCount(batch.results) })}
                        </Box>
                        {Object.entries(getErrors(batch.results)).map(([trashType, count]) => {
                            const squares = [];
                            for (let i = 0; i < count; i++) {
                                squares.push(
                                    <TrashColorSquare
                                        key={`${trashType}-${i}`}
                                        trashType={trashType as TrashType}
                                        />
                                );
                            }
                            return squares;
                        })}
                    </Box>
                }
                secondaryTypographyProps={{
                    color: getDangerColor(getErrorsCount(batch.results)),
                }}
            />
            <ListItemIcon>
                {visited ? (
                    <Tooltip 
                        title={t("visited", { ns: Namespace.COMMONS })}                        
                        >
                        <VisitedIcon color="success" />
                    </Tooltip>
                ) : (
                    <ChevronRightIcon />
                )}
            </ListItemIcon>
        </ListItemButton>
    );
}