import { createSvgIcon } from "@mui/material";

const AutoVerifiedIcon = createSvgIcon(
    <g>
        <path d="M21.7531 14.7V17.4C21.7531 17.895 21.349 18.3 20.8552 18.3H19.9573V19.2C19.9573 20.199 19.1582 21 18.1616 21H5.59147C5.11521 21 4.65846 20.8104 4.32169 20.4728C3.98493 20.1352 3.79573 19.6774 3.79573 19.2V18.3H2.89787C2.40404 18.3 2 17.895 2 17.4V14.7C2 14.205 2.40404 13.8 2.89787 13.8H3.79573C3.79573 10.317 6.60606 7.5 10.0808 7.5H10.9787V6.357C10.44 6.051 10.0808 5.466 10.0808 4.8C10.0808 3.81 10.8889 3 11.8765 3C12.8642 3 13.6723 3.81 13.6723 4.8C13.6723 5.466 13.3131 6.051 12.7744 6.357V7.5V7.5C12.7744 9.48804 14.386 11.0997 16.3741 11.0997H16.5632C17.3127 11.0997 18.0453 10.8774 18.6684 10.461L19.0595 10.1997C19.7979 11.254 19.9596 12.5118 19.9573 13.8H20.8552C21.349 13.8 21.7531 14.205 21.7531 14.7ZM10.0808 15.15C10.0808 14.5533 9.84432 13.981 9.42336 13.559C9.0024 13.1371 8.43146 12.9 7.83614 12.9C7.24081 12.9 6.66987 13.1371 6.24892 13.559C5.82796 13.981 5.59147 14.5533 5.59147 15.15C5.59147 15.7467 5.82796 16.319 6.24892 16.741C6.66987 17.1629 7.24081 17.4 7.83614 17.4C8.43146 17.4 9.0024 17.1629 9.42336 16.741C9.84432 16.319 10.0808 15.7467 10.0808 15.15ZM18.1616 15.15C18.1616 14.5533 17.9251 13.981 17.5042 13.559C17.0832 13.1371 16.5123 12.9 15.9169 12.9C15.3216 12.9 14.7507 13.1371 14.3297 13.559C13.9088 13.981 13.6723 14.5533 13.6723 15.15C13.6723 15.7467 13.9088 16.319 14.3297 16.741C14.7507 17.1629 15.3216 17.4 15.9169 17.4C16.5123 17.4 17.0832 17.1629 17.5042 16.741C17.9251 16.319 18.1616 15.7467 18.1616 15.15Z" />
        <path d="M20.4961 3.8775L16.5903 7.7925L15.3782 6.5775C15.2136 6.4125 15.0041 6.33 14.7497 6.33C14.4953 6.33 14.2858 6.4125 14.1212 6.5775C13.9566 6.7425 13.8743 6.9525 13.8743 7.2075C13.8743 7.4625 13.9566 7.6725 14.1212 7.8375L15.9618 9.6825C16.1414 9.8625 16.3509 9.9525 16.5903 9.9525C16.8298 9.9525 17.0393 9.8625 17.2189 9.6825L21.7531 5.1375C21.9177 4.9725 22 4.7625 22 4.5075C22 4.2525 21.9177 4.0425 21.7531 3.8775C21.5885 3.7125 21.379 3.63 21.1246 3.63C20.8702 3.63 20.6607 3.7125 20.4961 3.8775Z" />
    </g>,
    "AutoVerified",
);

export default AutoVerifiedIcon;