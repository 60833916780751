import { Namespace } from 'locales/translations';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { Contact } from 'constants/contacts';
import theme from 'constants/theme';
import { Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ContactsCardProps {
    contact: Contact,
}

function ContactsCard(props: ContactsCardProps) {
    const { contact } = props;

    const { t } = useTranslation([Namespace.CONTACTS]);

    return (
        <Paper sx={{
            padding: 1,
            [theme.breakpoints.up("md")]: {
                padding: 2,
            }
        }}>
            <Typography
                component="div"
                variant="body1"
            >
                <table cellSpacing={theme.spacing(1)}>
                    <tbody>
                        <tr>
                            <td>{t("name")}:</td>
                            <td>{contact.name}</td>
                        </tr>
                        <tr>
                            <td>{t("role")}:</td>
                            <td>{t(contact.role, { ns: Namespace.CONTACTS })}</td>
                        </tr>
                        <tr>
                            <td>{t("email")}:</td>
                            <td>
                                <Link
                                    href={`mailto:${contact.email}`}>
                                    {contact.email}
                                    <EmailIcon sx={{
                                        margin: "-2px 8px -6px",
                                    }} />
                                </Link>
                            </td>
                        </tr>
                        {contact.phone &&
                            <tr>
                                <td>{t("phone")}:</td>
                                <td>{contact.phone}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </Typography>
        </Paper>
    );
}

export default ContactsCard;