import { Box, Typography } from "@mui/material";
import ActionButton from "components/_include/Buttons/ActionButton";
import { Namespace } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddRounded";
import AddCollaboratorDialog from "./AddCollaboratorDialog/AddCollaboratorDialog";
import { useCanWrite, usePartnerID } from "store/hooks";

export default function CollaboratorsSectionHeader() {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.CONTACTS,]);

    const partnerID = usePartnerID();
    const canInvite = useCanWrite(partnerID);

    const [addCollaboratorDialogOpen, setAddCollaboratorDialogOpen] = useState(false);

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <Typography variant="h4" flexGrow={1}>
                {t("collaborator", { ns: Namespace.CONTACTS })}
            </Typography>

            {canInvite && (
                <>
                    <ActionButton
                        color="primary"
                        onClick={() => setAddCollaboratorDialogOpen(true)}
                        startIcon={<AddIcon />}
                    >
                        {t("add collaborator", { ns: Namespace.ACTIONS })}
                    </ActionButton>

                    <AddCollaboratorDialog
                        open={addCollaboratorDialogOpen}
                        onClose={() => setAddCollaboratorDialogOpen(false)}
                    />
                </>
            )}
        </Box>
    )
}