import MostFrequentErrorsBarChart, { ERROR_CATEGORY, BAR_COLORS, TEXT_COLORS, ErrorPoint } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsBarChart";
import { ErrorsCount, DISPLAYED_SORTING_ERRORS, TrashType } from "constants/trash";
import { getErrors, mergeTrashCounts } from "helpers/trash";
import _ from "lodash";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { Namespace } from 'locales/translations';
import { WasteSlice } from "./WasteRepartitionPieChart";
import { WASTES_COLORS } from "constants/trash";
import { useTranslation } from "react-i18next";

type ChartProps = {
    height: number;
}

export default function MostFrequentErrorsChart({ height }: ChartProps) {
    const wastesCounts = useAppSelector(state => state.stats.data?.wastesCounts);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);


    const loading = useAppSelector(state => state.trucks.list.loading
        || state.areas.list.loading || state.stats.loading || state.collections.list.loading);

    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!wastesCounts) return errorsTypes; // results not loaded yet

        let mergedResults: ErrorsCount = getErrors(wastesCounts);

        const maxErrorCount = _.max(Object.entries(mergedResults).map(([_, count]) => count))!;

        for (const errorType of DISPLAYED_SORTING_ERRORS) {
            const count = mergedResults[errorType] ?? 0;

            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.4) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
            else {
                category = ERROR_CATEGORY.GOOD;
                // continue;
            }

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [wastesCounts]);

    const pieData: WasteSlice[] = useMemo(() => {
        let wastesList: WasteSlice[] = [];
        if (!wastesCounts) return wastesList; // results not loaded yet

        let wastes = mergeTrashCounts(wastesCounts);

        let mergedResults: ErrorsCount = getErrors(wastesCounts);

        let totalErrors = _.sum(Object.values(mergedResults));

        for (const errorType of DISPLAYED_SORTING_ERRORS) {
            const count = wastes[errorType] ?? 0;

            const portion = count / totalErrors;

            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            const value = count / totalErrors * 100;
            const rounderValue = Math.round(value);
            if (rounderValue > 0) {
                wastesList.push({
                    id: errorType,
                    label: t(errorType, { ns: Namespace.WASTES }),
                    value: rounderValue,
                    color: WASTES_COLORS[errorType as TrashType],
                });
            }
        }

        return wastesList;
    }, [t, wastesCounts]);

    return (
        <MostFrequentErrorsBarChart data={data} pieData={pieData} loading={loading} icons={true} height={(theme) => theme.spacing(height)} />
    )
}