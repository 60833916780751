import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CollectionsController from "controllers/collections";
import { Namespace } from "locales/translations";
import Collection from "models/Collection";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";

type ButtonProps = {
    loading: boolean;
    collection: Collection;
}

export default function MarkCollectionAsDoneButton({ loading, collection }: ButtonProps) {

    const processed = collection.processed;

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.DIALOGS]);

    const dispatch = useAppDispatch();

    const [dialogOpen, toggleDialog] = useState(false);

    const markCollectionAsDone = () => {
        dispatch(CollectionsController.update(collection, { processed: !collection.processed }));
        toggleDialog(false);
    }

    return (
        <>
            <LoadingButton
                color="white"
                loading={loading}
                onClick={() => toggleDialog(true)}
            >
                <span>{t(processed ? "undo" : "done", { ns: Namespace.ACTIONS })}</span>
            </LoadingButton>

            <Dialog
                open={dialogOpen}
                onClose={() => toggleDialog(false)}
                >
                <DialogTitle 
                    sx={{
                        py: 1,
                        px: 4,
                    }}
                    >
                    {t(`mark_collection_as.${processed ? "undo" : "done"}.title`, { ns: Namespace.DIALOGS })}
                </DialogTitle>
                <DialogContent 
                    sx={{ 
                        px: 4,
                    }}
                    >
                    <DialogContentText>
                        {t(`mark_collection_as.${processed ? "undo" : "done"}.body`, { ns: Namespace.DIALOGS })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => toggleDialog(false)}
                    >
                        {t("cancel", { ns: Namespace.ACTIONS })}
                    </Button>
                    <LoadingButton
                        color="success"
                        onClick={markCollectionAsDone}
                        loading={loading}
                        disabled={loading}
                    >
                        <span>{t(processed ? "undo" : "done", { ns: Namespace.ACTIONS })}</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}