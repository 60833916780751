import { useSearchParams } from "react-router-dom";
import { DISPLAYED_SORTING_ERRORS, TrashType } from "constants/trash";
import TrashTypeSelect from "./TrashTypeSelect";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import DeleteIcon from "@mui/icons-material/DeleteForeverRounded";

type SingleTrashTypeSelectProps = {
    parentLoading: boolean;
}

/**
 * Dropdown menu to select a single class of waste.
 * It updates the search parameters in the URL upon change.
 */
export default function SingleTrashTypeSelect({ parentLoading }: SingleTrashTypeSelectProps) {
    const { t } = useTranslation([Namespace.FORMS]);

    const [searchParams, setSearchParams] = useSearchParams();

    const showSingleTrashType = searchParams.get("trashType");

    const handleSingleTrashTypeSelected = (showSingleTrashType: TrashType | "") => {
        searchParams.set("trashType", showSingleTrashType);
        setSearchParams(searchParams);
    };

    return (
        <TrashTypeSelect
            id="single-trash-type-select-menu"
            title={t(`manage_batches.trash_type.title`, { ns: Namespace.FORMS })}
            parentLoading={parentLoading}
            options={DISPLAYED_SORTING_ERRORS}
            defaultValue={showSingleTrashType as TrashType || ""}
            startIcon={<DeleteIcon />}
            onChange={handleSingleTrashTypeSelected} 
            />
    );
}