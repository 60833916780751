import { List } from '@mui/material';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTrucksIds } from 'store/reducers/trucks/list';
import OnboardingSetupCard from '../OnboardingSetupCard';
import EditTruckDialog from './EditTruckDialog';
import TruckItem from './TruckItem';
import { SelectedTruckActions } from 'store/reducers/trucks/selected';
import Truck from 'models/Truck';

type TrucksSetupCardProps = {
}

function TrucksSetupCard(props: TrucksSetupCardProps) {
    const { } = props;

    const trucksIDs = useAppSelector(state => selectTrucksIds(state));

    const dispatch = useAppDispatch();

    const selectTruck = useCallback((truck: Truck) => {
        dispatch(SelectedTruckActions.selectTruck(truck));
    }, [trucksIDs]); 

    return (
        <OnboardingSetupCard
            type="trucks"
            completed={trucksIDs.length > 0}
        >
            <List>
                {trucksIDs.map((truckID) => (
                    <TruckItem
                        key={truckID}
                        truckID={truckID}
                        onEditClicked={selectTruck}
                    />
                ))}
            </List>

            <EditTruckDialog />
        </OnboardingSetupCard>
    );
}

export default TrucksSetupCard;