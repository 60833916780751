import { Box, BoxProps, List } from '@mui/material';
import ChartTitle from "components/Statistics/ChartTitle";
import SectionLoader from 'components/_include/SectionLoader';
import { NamedErrorsByAddress } from 'constants/types';
import { formatAddress } from 'helpers/geo';
import { useAppSelector } from "store/hooks";
import AddressErrorsListItem from "./AddressErrorsListItem";

type AddressesWithMostErrorsProps = Pick<BoxProps, "height" | "px"> & {
    title: string;
    data: NamedErrorsByAddress[];
    onAddressClick?: (addressKey: string) => void;
    onAddressHover?: (addressKey: string, enter: boolean) => void;
}

export type ErrorPoint = {
    trashType: string;
    count: number;
    color: string;
    textColor: string;
};

function AddressesWithMostErrors({ title, data, height, px, onAddressClick, onAddressHover }: AddressesWithMostErrorsProps) {
    const loading = useAppSelector(state => state.batches.list.loading || state.batches.sortingMap.loading || state.savedAddresses.list.loading);
    return (
        <Box
            justifyContent="center"
        >
            <ChartTitle sx={{ px: px, }}>
                {title}
            </ChartTitle>

            <List
                sx={{
                    width: "100%",
                    height: height,
                    px: px,
                    overflow: "scroll"
                }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    data.map((place) => (
                        <AddressErrorsListItem
                            key={place.addressKey}
                            addressKey={place.addressKey}
                            address={formatAddress(place.address)}
                            errors={place.errors}
                            errorsCount={place.errorsCount}
                            onClick={onAddressClick}
                            onHover={onAddressHover}
                            />
                    ))
                )}
            </List>
        </Box >
    );
}

export default AddressesWithMostErrors;