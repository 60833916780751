import { DbCollection } from "constants/db";
import { QueryFilter } from "constants/types";
import { addDoc, deleteDoc, DocumentData, DocumentSnapshot, QueryDocumentSnapshot, Timestamp, updateDoc } from "firebase/firestore";
import { listDocs, getDocumentReference, createDocument, getCollectionRef } from "helpers/db";
import Route, { RouteDbData } from "models/Route";
import { showError, showSuccess, showTranslatedMessage } from "store/reducers/snacks";
import { RoutesActions } from "store/reducers/routes/list";
import { AppDispatch } from "store/store";
import { deleteRouteSchedules } from "./collections_schedules";
import { omit } from "lodash";

function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Route;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Route | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as RouteDbData;
    if (!data || !dbDoc.ref.parent.parent) return null;
    const routeData: Route = {
        ...data,
        ID: dbDoc.id,
        partnerID: dbDoc.ref.parent.parent.id,
        createdAt: data.createdAt.toMillis(),
    }
    return routeData;
}

const list = (partnerID: string, filters: QueryFilter<RouteDbData>[]) => async (dispatch: AppDispatch) => {
    dispatch(RoutesActions.startLoadingList());

    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.ROUTES];

    try {
        const routesDocs = await listDocs(collectionPath, filters, { fieldPath: "createdAt" });

        const routes = routesDocs.map(doc => fromDbDoc(doc));

        dispatch(RoutesActions.setList(routes));
        return routes;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load routes", error);
        dispatch(RoutesActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
}

const create = (partnerID: string, data: RouteDbData) => async (dispatch: AppDispatch) => {
    dispatch(RoutesActions.startLoadingList());

    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.ROUTES];

    try {
        const dbDoc = await addDoc(getCollectionRef(collectionPath), data);
        const route: Route = {
            ...data,
            ID: dbDoc.id,
            partnerID: dbDoc.parent.parent!.id,
            createdAt: Timestamp.now().toMillis(),
        };
        dispatch(RoutesActions.addItem(route));
        return route;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to create new route", error);
        dispatch(RoutesActions.setError(error.message));
        dispatch(showError(error.message));
        return null;
    }
}

const update = (partnerID: string, routeID: string, data: Partial<RouteDbData>) => async (dispatch: AppDispatch) => {
    dispatch(RoutesActions.startLoadingList());

    try {
        const dbDoc = await updateDoc(getDocumentReference(routeID, DbCollection.ROUTES, `${DbCollection.PARTNERS}/${partnerID}`), data);
        dispatch(RoutesActions.updateItem({ 
            ID: routeID, 
            data: {
                ...omit(data, "createdAt"),
                ...(data.createdAt && { createdAt: data.createdAt?.toMillis() }),
            },
        }));
        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to update route", error);
        dispatch(RoutesActions.setError(error.message));
        dispatch(showError(error.message));
        return false;
    }
}

const deleteRoute = (partnerID: string, routeID: string) => async (dispatch: AppDispatch) => {
    dispatch(RoutesActions.startLoadingList());

    try {
        await deleteDoc(getDocumentReference(routeID, DbCollection.ROUTES, `${DbCollection.PARTNERS}/${partnerID}`));
        dispatch(RoutesActions.removeItem(routeID));

        // also delete associated schedules
        await dispatch(deleteRouteSchedules(partnerID, routeID));
        
        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to delete route", error);
        dispatch(RoutesActions.setError(error.message));
        dispatch(showError(error.message));
        return false;
    }
}

const RoutesController = {
    list,
    create,
    update,
    delete: deleteRoute,
};

export default RoutesController;
