import { useState, FormEvent } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import UserController from '../../controllers/user';
import ActionButton from '../_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { UserActions } from 'store/reducers/user';
import { TextInput } from 'helpers/forms';
import { isValidEmail } from 'helpers/strings';
import SendIcon from '@mui/icons-material/SendRounded';
import { PartnerType } from 'models/Partner';

type SignUpPageProps = {

}

type FormInputs = {
    name: TextInput,
    partnerType: TextInput,
    email: TextInput,
    message: TextInput,
};

function SignUpPage(props: SignUpPageProps) {

    const userLoading = useAppSelector(state => state.user.loading);
    const userError = useAppSelector(state => state.user.error);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS, Namespace.SNACKS, Namespace.TITLES,]);

    const [inputs, setInputs] = useState<FormInputs>({
        name: { value: "", error: null },
        partnerType: { value: "", error: null },
        email: { value: "", error: null },
        message: { value: "", error: null },
    });

    const { name, partnerType, email, message } = inputs;

    const handleSubmitPressed = (event?: FormEvent) => {
        event?.preventDefault();

        if (!isValidEmail(email.value)) { // invalid email address
            setInputs({
                ...inputs,
                email: {
                    ...inputs.email,
                    error: t("sign_in.invalid_email", { ns: Namespace.FORMS }),
                },
            });
            return;
        }

        // submit form
        const emailContent = `New message from the truck dashboard sign up:\n \
            - Name: ${name.value}\n \
            - Type: ${partnerType.value}\n \
            - Email: ${email.value}\n \
            - Message: ${message.value} \
            `;
        dispatch(UserController.sendEmailToFicha(emailContent));
    }

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: keyof FormInputs, value: string) => {
        dispatch(UserActions.resetError());

        setInputs({
            ...inputs,
            [name]: {
                value,
                error: null,
            }
        });
    }

    const formIsValid = name.value.length > 0 && partnerType.value.length > 0 && email.value.length > 0;

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h1" sx={{
                mb: 5
            }}>
                {t("dashboard", { ns: Namespace.TITLES })}
            </Typography>
            <form
                autoComplete="on"
                method="post"
                action="#"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    flexGrow: 1,
                    maxWidth: 800,
                }}
            >
                <Grid container columnSpacing={2} maxWidth="sm">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("name", { ns: Namespace.COMMONS })}
                            value={name.value}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                onChange: (event) => { handleInputChange('name', event.target.value) }
                            }}
                            error={name.error !== null}
                            helperText={name.error}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin='normal'>
                            <InputLabel id="partner-type-label">
                                {t("sign_up.partner_type.label", { ns: Namespace.FORMS })}
                            </InputLabel>
                            <Select
                                labelId="partner-type-label"
                                value={partnerType.value}
                                label={t("sign_up.partner_type.label", { ns: Namespace.FORMS })}
                                onChange={(event) => { handleInputChange('partnerType', event.target.value) }}
                            >
                                {Object.values(PartnerType).map(ptype => {
                                    const label = t(`sign_up.partner_type.${ptype}`, { ns: Namespace.FORMS });
                                    return (
                                        <MenuItem key={ptype} value={label}>{label}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            label={t("email", { ns: Namespace.COMMONS })}
                            value={email.value}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                onChange: (event) => { handleInputChange('email', event.target.value) }
                            }}
                            error={email.error !== null}
                            helperText={email.error}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={5}
                            label={t("message", { ns: Namespace.COMMONS })}
                            value={message.value}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                onChange: (event) => { handleInputChange('message', event.target.value) }
                            }}
                            error={message.error !== null}
                            helperText={message.error}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        textAlign="center"
                        mt={4}
                    >
                        <ActionButton
                            color="primary"
                            disabled={!formIsValid}
                            loading={userLoading}
                            onClick={(event) => handleSubmitPressed(event)}
                            type="submit"
                            startIcon={
                                <SendIcon
                                    sx={{
                                        transform: "rotate(-45deg)",
                                        mt: -.75
                                    }}
                                />
                            }
                        >
                            {t("send", { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </Grid>
                </Grid>

                <Box>
                    <Typography>
                        {t("sign_up.already_an_account", { ns: Namespace.FORMS })}
                        <Link
                            to='/login'
                        >
                            <Button>
                                {t("sign_up.log_in", { ns: Namespace.FORMS })}
                            </Button>
                        </Link>
                    </Typography>
                </Box>
            </form>
        </Box>
    )
}

export default SignUpPage;