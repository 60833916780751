import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector, useIsAdmin } from 'store/hooks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { SelectedTruckActions } from 'store/reducers/trucks/selected';
import TruckSortingExceptionsList from './SortingExceptions/TruckSortingExceptionsList';
import SaveTruckSettingsButton from './SaveTruckSettingsButton';
import TrucksDetailsInputs from './TrucksDetailsInputs';

type EditTruckDialogProps = {

}

function EditTruckDialog({ }: EditTruckDialogProps) {

    const isAdmin = useIsAdmin();

    const open = useAppSelector(state => Boolean(state.trucks.selected.data));
    
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const closeDialog = () => {
        dispatch(SelectedTruckActions.selectTruck(null));
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="md"
            fullWidth
        >
            <DialogIcon
                Icon={LocalShippingIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.trucks.update_truck", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    >
                    <TrucksDetailsInputs />

                    {isAdmin && (
                        <>
                            <Divider />
                            <TruckSortingExceptionsList />
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={closeDialog}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <SaveTruckSettingsButton
                    onSaved={closeDialog}
                    />
            </DialogActions>
        </Dialog>
    );
}

export default EditTruckDialog;