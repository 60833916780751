import { ComputedSerie, DatumValue, Point, ResponsiveLine } from "@nivo/line";
import SectionLoader from 'components/_include/SectionLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import _ from 'lodash';
import moment from 'moment';
import ChartTitle from './ChartTitle';
import { useAppSelector } from "store/hooks";
import { API_TIMESTAMP_FORMAT } from "constants/dates";

type ErrorsCountEvolutionLineChartProps = {

}

type ErrorPoint = {
    x: DatumValue,
    y: number
};

type ErrorLine = {
    id: string;
    color: string;
    data: ErrorPoint[];
};

const TICK_WIDTH = 92;

function ErrorsCountEvolutionLineChart(props: ErrorsCountEvolutionLineChartProps) {
    const errorsCounts = useAppSelector(state => state.stats.data?.errorsCounts);

    const loading = useAppSelector(state => state.trucks.list.loading
        || state.areas.list.loading || state.stats.loading || state.collections.list.loading);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.STATS, Namespace.WASTES]);

    // let averageNumberOfBatches = collections.length ? 0 : 1; // average number of batches per collection
    // collections.forEach(collection => {
    //     averageNumberOfBatches += collection.batchesCount;
    // });
    // averageNumberOfBatches /= collections.length || 1;

    const data: ErrorLine[] = useMemo(() => {
        let errorsEvolution: ErrorPoint[] = [];
        // let averageEvolution: ErrorPoint[] = [];

        if (errorsCounts) {
            errorsCounts.forEach(errorsCountsDated => {
                errorsEvolution.push({
                    x: moment(errorsCountsDated.date, API_TIMESTAMP_FORMAT).toDate(),
                    y: errorsCountsDated.count,
                });

                // averageEvolution.push({
                //     x: moment(collection.startAt).toDate(),
                //     y: errorsCount / collection.batchesCount * averageNumberOfBatches,
                // });
            });
        }

        return [{
            id: "errors",
            color: "#FF3399",
            data: errorsEvolution,
        },
            // {
            //     id: "average",
            //     color: "#336699",
            //     data: averageEvolution
            // }
        ];
    }, [errorsCounts]);

    // adapt bottom axis ticks to chart width to prevent overlap
    const wrapperRef = useRef<HTMLDivElement>();
    const wrapperWidth = wrapperRef.current?.offsetWidth;
    const [bottomTickValues, setBottomTickValues] = useState<DatumValue[]>([]);
    useEffect(() => {
        if (!wrapperWidth) setBottomTickValues([]);
        else {
            var xValues = _.uniq(
                data
                    .map((d) => d.data.map((x) => x.x))
                    .reduce((acc, data) => acc.concat(data), []),
            );

            var gridWidth = Math.ceil(wrapperWidth / xValues.length);
            var tickDistance = Math.floor(TICK_WIDTH / gridWidth);

            setBottomTickValues(tickDistance === 0 ? xValues : xValues.filter((_, i) => i % tickDistance === 0));
        }
    }, [TICK_WIDTH, wrapperWidth, data]);

    return (
        <Box
            justifyContent="center"
            my={2}
        >
            <ChartTitle>
                {t("errors_count_evolution.title", { ns: Namespace.CHARTS })}
            </ChartTitle>
            <Box
                ref={wrapperRef}
                sx={{
                    width: "100%",
                    height: (theme) => theme.spacing(38),
                    position: "relative",
                }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    <ResponsiveLine
                        data={data}
                        colors={(datum) => datum.color}
                        margin={{ top: 24, right: 32, bottom: 40, left: 48 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 'auto',
                            stacked: false,
                            reverse: false,
                        }}
                        // enableGridX={false}
                        // gridXValues={[1648825142000]}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickValues: bottomTickValues,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t('errors_count_evolution.bottomAxis', { ns: Namespace.CHARTS }),
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: (value) => t("date_day", { ns: Namespace.DATES, date: moment(value) }),
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t('errors_count_evolution.leftAxis', { ns: Namespace.CHARTS }),
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={(serie: ComputedSerie) => { return serie.id === "average" ? "rgba(0,0,0,0)" : "#FFFFFF" }}
                        pointBorderWidth={2}
                        pointBorderColor={(point: Point) => point.serieId === "average" ? "rgba(0,0,0,0)" : point.serieColor}
                        pointLabelYOffset={0}
                        useMesh={true}
                        enableSlices="x"
                        enableArea={true}
                        defs={[
                            // linearGradientDef('gradientA', [
                            //     { offset: 0, color: 'inherit' },
                            //     { offset: 100, color: 'inherit', opacity: 0 },
                            // ]),
                            {
                                id: "transparent",
                                type: 'patternLines',
                                background: 'rgba(0,0,0,0)',
                                color: 'rgba(0,0,0,0)',
                            }
                        ]}
                        fill={[
                            // { match: { id: "average" }, id: 'gradientA' },
                            { match: { id: "errors" }, id: 'transparent' },
                        ]}
                        sliceTooltip={({ slice }) => {
                            return (
                                <Card
                                    sx={{
                                        background: "white",
                                        padding: "9px 12px",
                                        border: "1px solid #ccc",
                                        borderRadius: 2,
                                    }}
                                >
                                    <Box>
                                        {t("date_full_day", { ns: Namespace.DATES, date: moment(slice.points[0].data.x, "x") })}
                                    </Box>
                                    {slice.points.map((point) => {
                                        // const realValue = point.serieId === "average" ? ( // average
                                        //     Math.round(Number(point.data.yFormatted) / averageNumberOfBatches * 10) / 10
                                        // ) : ( // number of trashes
                                        //     point.data.yFormatted
                                        // );
                                        const realValue = point.data.yFormatted;
                                        return (
                                            <Box
                                                key={point.id}
                                                sx={{
                                                    color: point.serieColor,
                                                    padding: "3px 0"
                                                }}
                                            >
                                                <strong>{t(`errors_count_evolution.tooltip.${point.serieId}`, { ns: Namespace.CHARTS })}</strong>: {realValue}
                                            </Box>
                                        );
                                    })}
                                </Card>
                            );
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default ErrorsCountEvolutionLineChart;