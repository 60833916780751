import { DialogContent, Typography, Grid, Divider } from "@mui/material";
import { Namespace } from "locales/translations";
import DownloadExcelButton from "./DownloadExcelButton";
import DownloadPDFButton from "./DownloadPDFButton";
import { useTranslation } from "react-i18next";

export type DownloadReportDialogContentProps = {
    parentPage: "stats" | "collection";
}

export default function DownloadReportDialogContent({ parentPage }: DownloadReportDialogContentProps) {

    const { t } = useTranslation([Namespace.DIALOGS]);

    return (
        <DialogContent>
            <Typography
                variant="subtitle1"
                color={theme => theme.palette.grey[500]}
            >
                {t("download_report.instruction", { ns: Namespace.DIALOGS })}
            </Typography>
            <Grid container mt={4}>
                <Grid item xs={5}>
                    <DownloadPDFButton parentPage={parentPage} />
                </Grid>
                <Grid item xs={2}>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            mx: "50%",
                            height: "100%",
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <DownloadExcelButton parentPage={parentPage} />
                </Grid>
            </Grid>

        </DialogContent>
    );
}