import { Dialog, DialogTitle } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppSelector } from 'store/hooks';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleForm from './ScheduleForm';

type EditCollectionsScheduleDialogProps = {
    onClose: () => void;
}

function EditCollectionsScheduleDialog(props: EditCollectionsScheduleDialogProps) {
    const { onClose } = props;

    const open = useAppSelector(state => state.collectionsSchedules.selected.data !== null);

    const { t } = useTranslation([Namespace.FORMS]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogIcon
                Icon={CalendarMonthIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.schedules.update_schedule", { ns: Namespace.FORMS })}
            </DialogTitle>
            <ScheduleForm
                onClose={onClose}
                />
        </Dialog>
    );
}

export default EditCollectionsScheduleDialog;