import { Box } from "@mui/material";
import { PARTNER_STATUSES_COLORS, PartnerStatus } from "models/Partner"

type PartnerStatusColorIndicatorProps = {
    status: PartnerStatus;
}

export default function PartnerStatusColorIndicator({ status }: PartnerStatusColorIndicatorProps) {
    return (
        <Box
            width={10}
            height={10}
            borderRadius="50%"
            bgcolor={PARTNER_STATUSES_COLORS[status]}
            mr={1}
        />
    )
}