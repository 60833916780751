import { DbCollection } from "constants/db";
import { DocumentData, DocumentSnapshot, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { getDocumentReference, listDocs } from "helpers/db";
import SavedAddress, { SavedAddressDbData } from "models/SavedAddress";
import { SavedAddressesActions } from "store/reducers/saved_addresses/list";
import { SelectedSavedAddressActions } from "store/reducers/saved_addresses/selected";
import { AppDispatch } from "store/store";
import { handleAPIError } from "./actions";
import { chunk } from "lodash";


/**
 * Build a SavedAddress object from its Firestore document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): SavedAddress;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): SavedAddress | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as SavedAddressDbData;
    if (!data) return null;
    const addressData: SavedAddress = {
        ...data,
        title: data.title.replace(`, ${data.address.countryName}`, ""), // remove country from database title
        partnerID: dbDoc.ref.parent.parent!.id,
    };
    return addressData;
}

/**
 * List saved addresses matching a given list of IDs
 */
const list = (partnerID: string, addressesIDs: string[]) => async (dispatch: AppDispatch) => {
    dispatch(SavedAddressesActions.startLoadingList());

    let addresses: SavedAddress[] = [];

    let addressesIDsChunks = chunk(addressesIDs, 10); // max 10 values for "in" filter in Firestore

    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.SAVED_ADDRESSES];

    try {
        for (let addressesIDsChunk of addressesIDsChunks) {
            const addressesDocs = await listDocs(collectionPath, [{
                fieldPath: "ID",
                opStr: "in",
                value: addressesIDsChunk,
            }]);

            addresses.push(...addressesDocs.map(doc => fromDbDoc(doc)));
        }

        addresses.sort((p1, p2) => addressesIDs.indexOf(p1.ID) - addressesIDs.indexOf(p2.ID)); // sort addresses in same order as addressesIDs

        dispatch(SavedAddressesActions.setList(addresses));

        return addresses;
    }
    catch (e) {
        dispatch(handleAPIError(e, "load saved addresses", SavedAddressesActions.setError));
        return [];
    }
}

/**
 * Retrieve a single saved address matching a given ID
 */
const retrieve = (partnerID: string, addressID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedSavedAddressActions.startLoading());

    const parentCollectionPath = [DbCollection.PARTNERS, partnerID].join("/");

    try {
        const savedAddressDoc = await getDoc(getDocumentReference(addressID, DbCollection.SAVED_ADDRESSES, parentCollectionPath));
        const savedAddress = fromDbDoc(savedAddressDoc);

        dispatch(SelectedSavedAddressActions.setData(savedAddress));

        return savedAddress;
    }
    catch (e) {
        dispatch(handleAPIError(e, "load saved address", SelectedSavedAddressActions.setError));
        return null;
    }
}

const SavedAddressesController = {
    list,
    retrieve,
};

export default SavedAddressesController;